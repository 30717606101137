import { apiRequest } from "(apis)/api-interface/api-handler";
import { Divider, message, notification, Tag } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "shared-components/form-fields/custom-button";
import GlobalDragableTable from "shared-components/table/global-dragable-table";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
interface ScheduleTableProps {
  getScheduleData: () => Promise<void>;
  scheduledCourseTopicData: Array<{
    topic: string;
    date: string;
    markedValue: string;
    subject: string;
  }>;
  courseId: string;
}

const ScheduleTable: React.FC<ScheduleTableProps> = ({
  getScheduleData,
  scheduledCourseTopicData,
  courseId,
}) => {
  const navigate = useNavigate();
  const formatedScheduledCourseTopicData = scheduledCourseTopicData?.map(
    (data: any, i) => {
      return {
        ...data,
        SNO: i + 1,
        key: data?._id,
      };
    }
  );

  const [dataSource, setDataSource] = useState(
    formatedScheduledCourseTopicData
  );
  const initialOrder = useRef(
    formatedScheduledCourseTopicData?.map((item) => item.key)
  );
useEffect(() => {
    setDataSource(formatedScheduledCourseTopicData);
  
}, [scheduledCourseTopicData,formatedScheduledCourseTopicData]);
  const columns = [
    {
      title: "SR#",
      dataIndex: "SNO",
      key: "SNO",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return dayjs(date)?.format("DD-MM-YYYY");
      },
    },
    {
      title: "Topic",
      dataIndex: "topic",
      key: "topic",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Marked as",
      dataIndex: "markAs",
      key: "markAs",
      render: (markAs) => {
        let color;
        switch (markAs) {
          case "UPCOMING":
            color = "blue";
            break;
          case "DEMO":
            color = "green";
            break;
          default:
            color = "red";
        }
        return <Tag color={color}>{markAs}</Tag>;
      },
    },
    {
      title: "Is Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive, record) => (
        <GlobalPublishButton
          isActive={isActive}
          onChange={() => handlePublish(record.key)}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record:any) => (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 cursor-pointer text-black hover:text-rose-200"
            onClick={() => handleManage(record?._id)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>
        </>
      ),
    },
  ];

  const handlePublish = async (id: string) => {
    try {
      const response = await apiRequest("updateScheduleActive", null, [id]);
      if (response?.status === 200) {
          console.log("response", response);
        getScheduleData();
        message.success(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  const handleManage = (id) => {
    navigate(`/schedule/${id}`);
  };

  const updateOrder = async () => {
    try {
      const payload = dataSource?.map((topic) => topic.key);
      const response = await apiRequest("updateCourseScheduledOrder", payload, [
        courseId,
      ]);
      if (response?.data?.success) {
        getScheduleData();
        notification.success({
          message: response?.data?.message,
        });
        initialOrder.current = payload; // Update initial order after successful update
      } else {
        notification.error({
          message: response?.data?.message,
        });
      }
    } catch (error) {
      console.log("error", error);
      notification.error({
        message: error?.response?.data?.message,
      });
    }
  };

  const isOrderChanged = () => {
    const currentOrder = dataSource?.map((item) => item.key);
    return (
      JSON.stringify(initialOrder.current) !== JSON.stringify(currentOrder)
    );
  };

  // Sort dataSource by date
  const sortedDataSource = [...dataSource]?.sort((a, b) => {
    return new Date(b?.date).getTime() - new Date(a?.date).getTime();
  });

  return (
    <div className="mx-4 mt-8">
      <Divider className="text-gray-700 font-bold">Scheduled Topic</Divider>
      {/* <div className="flex justify-end my-4">
        <CustomButton
          text="Update Order"
          onClick={updateOrder}
          disabled={!isOrderChanged()}
        />
      </div> */}
      <GlobalDragableTable setUpDatedDataSource={setDataSource} data={sortedDataSource} columns={columns} />
  
    </div>
  );
};

export default ScheduleTable;
