import React, { useState } from "react";
import { Textarea } from "../ui/textarea";
import { Select } from "../ui/select";
import { Course } from "../../type";
import { Input } from "shared-components/ui/CustomForm";
import { FileUpload } from "shared-components/ui/file-upload";
import { Select as AntSelect } from "antd";
import { RichTextEditor } from "shared-components/ui/rich-text-editor";
import { Switcher } from "shared-components/ui/CustomForm";
import { Loader2, SaveIcon } from "lucide-react";

interface BasicInfoProps {
  courseData: Course;
  handleInputChange: (name: string, value: string | string[] | boolean) => void;
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleFileChange?: (name: string, file: File) => void;
  teacherData: { value: string; label: string }[];
  handleInstructorChange: (selectedIds: string[]) => void;
  handleSave?: () => void;
  apiLoader: {
    loading: boolean;
    api: string;
  }
}

export const BasicInfo: React.FC<BasicInfoProps> = ({
  courseData,
  handleInputChange,
  handleSelectChange,
  handleFileChange,
  teacherData,
  handleInstructorChange,
  handleSave,
  apiLoader
}) => {
  const [searchText, setSearchText] = useState("");

  const handleThumbnailSelect = (file: File | string) => {

    if (!file) {
      handleInputChange("thumbnail_image", "");
      return
    }
    if (typeof file === "string") {
      handleInputChange("thumbnail_image", file);
    } else {
      if (handleFileChange) {
        handleFileChange("thumbnail_image", file);
      }

      // Convert file to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        handleInputChange("thumbnail_image", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDemoVideoSelect = (file: File | null) => {
    if (file) {
      // Create a URL for the video
      const videoUrl = URL.createObjectURL(file);
      handleInputChange("demo_video", videoUrl);
    } else {
      handleInputChange("demo_video", "");
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-end mb-4">
        {handleSave && (
          <button
            onClick={handleSave}
            disabled={apiLoader?.loading}
            className={`flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-md transition-colors 
             ${apiLoader?.loading
                ? 'text-gray-500 bg-gray-100 cursor-not-allowed'
                : 'text-green-600 bg-green-50 hover:bg-green-100'}`}
          >
            {apiLoader?.loading ? (
              <>
                <span>Saving...</span>
                <Loader2 className="animate-spin h-4 w-4" />
              </>
            ) : (
              <>
                <span>Save</span>
                <SaveIcon className="w-4 h-4" />
              </>
            )}
          </button>
        )}
      </div>
      <div>
        <label
          htmlFor="thumbnail"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Course Thumbnail
        </label>
        <FileUpload
          onFileSelect={(file) => {
            if (typeof file === 'string') {
              handleInputChange("thumbnail_image", file);
            } else {
              handleThumbnailSelect(file);
            }
          }}
          accept="image/*"
          maxSize={5242880} // 5MB
          value={typeof courseData?.thumbnail_image === 'string'
            ? courseData.thumbnail_image
            : courseData?.thumbnail_image
              ? URL.createObjectURL(courseData.thumbnail_image)
              : undefined}
          previewType="image"
          className="mb-4"
        />
      </div>

      <div>
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Course Title
        </label>
        <Input
          type="text"
          name="title"
          value={courseData?.title}
          onChange={(name, value) => {
            handleInputChange(name, value);
          }}
          placeholder="Enter course title"
        />
      </div>
      <div>
        <label
          htmlFor="subject"
          className="block text-sm font-medium text-gray-700"
        >
          Subject
        </label>
        <Input
          type="text"
          name="subject"
          value={courseData?.subject}
          onChange={(name, value) => {
            handleInputChange(name, value);
          }}
          placeholder="Enter course subject"
          isError={false}
          errorMessage={""}
        />
      </div>
      <div>
        <label
          htmlFor="description"
          className="block text-sm font-medium text-gray-700"
        >
          Description
        </label>
        <RichTextEditor
          value={courseData?.description}
          onChange={(value) => handleInputChange("description", value)}
          placeholder="Enter course description"
        />
      </div>
      <div>
        <label
          htmlFor="learning_outcomes"
          className="block text-sm font-medium text-gray-700"
        >
          Learning Outcomes
        </label>
        <RichTextEditor
          value={courseData?.learning_outcomes || ""}
          onChange={(value) => handleInputChange("learning_outcomes", value)}
          placeholder="Enter learning outcomes"
        />
      </div>
      <div>
        <label
          htmlFor="level"
          className="block text-sm font-medium text-gray-700"
        >
          Level
        </label>
        <Select
          id="level"
          name="level"
          value={courseData?.level}
          onChange={handleSelectChange}
        >
          <option value="">Select course level</option>
          <option value="beginner">Beginner</option>
          <option value="intermediate">Intermediate</option>
          <option value="advanced">Advanced</option>
        </Select>
      </div>

      <div>
        <label
          htmlFor="duration_months"
          className="block text-sm font-medium text-gray-700"
        >
          Duration (months)
        </label>
        <Input
          type="number"
          onChange={(name, value) => {
            handleInputChange(name, value);
          }}
          name="duration_months"
          value={courseData?.duration_months}
          placeholder="Enter course duration in months"
        />
      </div>
      <div>
        <label
          htmlFor="instructors"
          className="block text-sm font-medium text-gray-700"
        >
          Select Instructors
        </label>
        <AntSelect
          mode="multiple"
          showSearch
          style={{ width: "100%" }}
          placeholder="Select instructors"
          value={courseData?.instructors}
          onChange={handleInstructorChange}
          options={teacherData}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label
            htmlFor="is_coming_soon"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Coming Soon
          </label>
          <Switcher
            togglevalue={courseData?.is_coming_soon}
            isActive={courseData?.is_coming_soon}
            for="is_coming_soon"
            onChange={() =>
              handleInputChange("is_coming_soon", !courseData?.is_coming_soon)
            }
          />
        </div>
        <div>
          <label
            htmlFor="is_published"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Published
          </label>
          <Switcher
            togglevalue={courseData?.is_published}
            isActive={courseData?.is_published}
            for="is_published"
            onChange={() =>
              handleInputChange("is_published", !courseData?.is_published)
            }
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="demo_video"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Demo Video
        </label>
        <FileUpload
          onFileSelect={handleDemoVideoSelect}
          accept="video/*"
          maxSize={50 * 1024 * 1024} // 50MB
          value={typeof courseData?.demo_video === 'string'
            ? courseData.demo_video
            : courseData?.demo_video
              ? URL.createObjectURL(courseData.demo_video)
              : undefined}
          previewType="video"
          className="mb-4"
        />
      </div>

      <div>
        <label
          htmlFor="tags"
          className="block text-sm font-medium text-gray-700"
        >
          Course Tags
        </label>
        <AntSelect
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Add course tags"
          value={courseData?.tags || []}
          onChange={(value) => handleInputChange("tags", value)}
        />
      </div>
    </div>
  );
};
