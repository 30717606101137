import React from 'react'

const CheckingAuthorization = () => {
  return (
    <div className="flex flex-col justify-start mt-10 items-center h-screen bg-gray-50 p-4">
        <div className="mb-6 text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary mx-auto mb-4"></div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">
            Authorizing Access
          </h2>
          <p className="text-gray-600 max-w-md text-center">
            Please wait while we verify your permissions and access rights. This
            ensures the security of your account and the system.
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-primary animate-pulse"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
            />
          </svg>
          <span className="text-gray-700">Checking credentials...</span>
        </div>
      </div>
  )
}

export default CheckingAuthorization