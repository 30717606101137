import { Timetable, TimetableAction } from "../../type";

export function timetableReducer(
  state: Timetable,
  action: TimetableAction
): Timetable {
  switch (action.type) {
    case "ADD_WEEK":
      const newWeekNumber = state.weeks.length;
      const newWeek = {
        id: `week-${newWeekNumber}`,
        weekNumber: newWeekNumber,
        title: `Week ${newWeekNumber}`,
        days: Array.from({ length: 6 }, (_, i) => ({
          id: `week-${newWeekNumber}-day-${i + 1}`,
          day: i + 1,
          slots: [],
        })),
      };
      return { ...state, weeks: [...state.weeks, newWeek] };

    case "ADD_TEST":
      return {
        ...state,
        weeks: state.weeks.map((week) =>
          week.id === action.weekId
            ? {
                ...week,
                days: week.days.map((day, index) =>
                  index === week.days.length - 1
                    ? {
                        ...day,
                        slots: [
                          ...day.slots,
                          {
                            id: Date.now().toString(),
                            moduleId: "",
                            topicId: "",
                            title: "Weekly Test",
                            type: "test",
                          },
                        ],
                      }
                    : day
                ),
              }
            : week
        ),
      };

    case "ADD_TOPIC":
      return {
        ...state,
        weeks: state.weeks.map((week) =>
          week.id === action.weekId
            ? {
                ...week,
                days: week.days.map((day) =>
                  day.id === action.dayId
                    ? { ...day, slots: [...day.slots, action.topic] }
                    : day
                ),
              }
            : week
        ),
      };

    case "REMOVE_TOPIC":
      return {
        ...state,
        weeks: state.weeks.map((week) =>
          week.id === action.weekId
            ? {
                ...week,
                days: week.days.map((day) =>
                  day.id === action.dayId
                    ? {
                        ...day,
                        slots: day.slots.filter(
                          (slot) => slot.id !== action.slotId
                        ),
                      }
                    : day
                ),
              }
            : week
        ),
      };

    case "REORDER_TOPICS":
      return {
        ...state,
        weeks: state.weeks.map((week) =>
          week.id === action.weekId
            ? {
                ...week,
                days: week.days.map((day) =>
                  day.id === action.dayId
                    ? {
                        ...day,
                        slots: Array.from(day.slots).map((slot, index) => {
                          if (index === action.sourceIndex) {
                            return day.slots[action.destinationIndex];
                          }
                          if (index === action.destinationIndex) {
                            return day.slots[action.sourceIndex];
                          }
                          return slot;
                        }),
                      }
                    : day
                ),
              }
            : week
        ),
      };

    case "MOVE_TOPIC":
      return {
        ...state,
        weeks: state.weeks.map((week) => {
          if (week.id === action.weekId) {
            return {
              ...week,
              days: week.days.map((day) => {
                // Remove topic from source day
                if (day.id === action.sourceDayId) {
                  return {
                    ...day,
                    slots: day.slots.filter(
                      (slot) => slot.id !== action.topicSlotId
                    ),
                  };
                }
                // Add topic to destination day
                if (day.id === action.destDayId) {
                  const movedTopic = state.weeks
                    .find((w) => w.id === action.weekId)
                    ?.days.find((d) => d.id === action.sourceDayId)
                    ?.slots.find((slot) => slot.id === action.topicSlotId);

                  return movedTopic
                    ? {
                        ...day,
                        slots: [...day.slots, movedTopic],
                      }
                    : day;
                }
                return day;
              }),
            };
          }
          return week;
        }),
      };

    default:
      return state;
  }
}
