import { useDrag, useDrop } from "react-dnd";
import { TimeSlot } from "../../type";
import { X } from "lucide-react";

interface DroppableSlotProps {
  weekId: string;
  dayId: string;
  slotIndex: number;
  onDrop: (item: any, weekId: string, dayId: string) => void;
  onRemove: (weekId: string, dayId: string, slotId: string) => void;
  onReorder: (
    weekId: string,
    dayId: string,
    sourceIndex: number,
    destinationIndex: number
  ) => void;
  onMoveTopic?: (
    weekId: string,
    sourceDayId: string,
    destDayId: string,
    topicSlotId: string
  ) => void;
  slot?: TimeSlot;
  className?: string;
}

export function DroppableSlot({
  weekId,
  dayId,
  slotIndex,
  onDrop,
  onRemove,
  onReorder,
  onMoveTopic,
  slot,
  className = "",
}: DroppableSlotProps) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["topic", "scheduled-topic"],
    drop: (item: any) => {
      if (item.type === "scheduled-topic") {
        // If dropping a scheduled topic from a different day
        if (item.dayId !== dayId) {
          onMoveTopic?.(weekId, item.dayId, dayId, item.id);
        } else {
          // Reordering within the same day
          onReorder(weekId, dayId, item.index, slotIndex);
        }
      } else {
        // Dropping a new topic
        onDrop(item, weekId, dayId);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className={`min-h-[60px] p-2 rounded border-2 relative
          ${
            isOver
              ? "border-blue-500 bg-blue-50"
              : "border-dashed border-gray-300"
          }
          ${slot ? "bg-white" : "bg-gray-50"}
          ${className}
        `}
    >
      {slot ? (
        <DraggableScheduledTopic
          slot={slot}
          weekId={weekId}
          dayId={dayId}
          index={slotIndex}
          onRemove={onRemove}
        />
      ) : (
        <div className="text-gray-400 text-center">Drop topic here</div>
      )}
    </div>
  );
}

interface DraggableScheduledTopicProps {
  slot: TimeSlot;
  weekId: string;
  dayId: string;
  index: number;
  onRemove: (weekId: string, dayId: string, slotId: string) => void;
}

function DraggableScheduledTopic({
  slot,
  weekId,
  dayId,
  index,
  onRemove,
}: DraggableScheduledTopicProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "scheduled-topic",
    item: {
      id: slot.id,
      index,
      type: "scheduled-topic",
      dayId,
      weekId,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} className={`text-sm ${isDragging ? "opacity-50" : ""}`}>
      <div className="font-medium">{slot.title}</div>
      <button
        onClick={() => onRemove(weekId, dayId, slot.id)}
        className="absolute top-1 right-1 text-red-500 hover:text-red-700"
      >
        <X size={16} />
      </button>
    </div>
  );
}
