import { Timetable } from "../../type";

interface PreviewTimetableProps {
  timetable: Timetable;
}

const PreviewTimetable = ({ timetable }: PreviewTimetableProps) => {
  return (
    <div className="space-y-8">
      {timetable?.weeks?.map((week) => (
        <div key={week?.id} className="space-y-4">
          <h3 className="text-xl font-semibold text-primary">
            Week {week?.weekNumber + 1}
          </h3>
          <div className="grid grid-cols-3 gap-4">
            {week?.days?.map((day) => (
              <div
                key={day?.id}
                className="p-4 rounded-lg bg-amber-50 border border-amber-100"
              >
                <h4 className="font-medium text-lg mb-4">Day {day?.day}</h4>
                <div className="space-y-3">
                  {day?.slots?.map((slot) => (
                    <div key={slot?.id} className="space-y-1">
                      <div className="text-gray-800">{slot?.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PreviewTimetable;
