"use client";

import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Timetable, WeekSchedule, TimeSlot, Module } from "../../type";
import { DraggableTopic } from "./draggable-topic";
import { DroppableSlot } from "./droppable-slot";
import { Button } from "../ui/button";
import { Eye, Edit, Plus } from "lucide-react";
import { timetableReducer } from "./timetable-reducer";
import PreviewTimetable from "./preview-time-table";

interface CourseTimetableProps {
  modules: Module[];
  initialTimetable?: Timetable;
  onTimetableChange: (timetable: Timetable) => void;
}

export function CourseTimetable({
  modules,
  initialTimetable,
  onTimetableChange,
}: CourseTimetableProps) {
  const [showPreview, setShowPreview] = useState(false);
  const [timetable, dispatch] = useReducer(
    timetableReducer,
    initialTimetable || {
      id: Date.now().toString(),
      courseId: "",
      weeks: [
        {
          id: "week-0",
          weekNumber: 0,
          title: "Week 0",
          days: Array.from({ length: 6 }, (_, i) => ({
            id: `week-0-day-${i + 1}`,
            day: i + 1,
            slots: [],
          })),
        },
      ],
    }
  );

  const scheduled_sub_modules = useMemo(() => {
    const sub_modules = new Set<string>();
    timetable.weeks.forEach((week) => {
      week.days.forEach((day) => {
        day.slots.forEach((slot) => {
          if (slot.type === "topic") {
            sub_modules.add(slot.topicId);
          }
        });
      });
    });
    return sub_modules;
  }, [timetable]);

  const handleDrop = useCallback((item: any, weekId: string, dayId: string) => {
    const newSlot: TimeSlot = {
      id: Date.now().toString(),
      moduleId: item.moduleId,
      topicId: item.id,
      title: item.title,
      type: "topic",
    };
    dispatch({ type: "ADD_TOPIC", weekId, dayId, topic: newSlot });
  }, []);

  const handleRemove = useCallback(
    (weekId: string, dayId: string, slotId: string) => {
      dispatch({ type: "REMOVE_TOPIC", weekId, dayId, slotId });
    },
    []
  );

  const handleReorder = useCallback(
    (
      weekId: string,
      dayId: string,
      sourceIndex: number,
      destinationIndex: number
    ) => {
      dispatch({
        type: "REORDER_TOPICS",
        weekId,
        dayId,
        sourceIndex,
        destinationIndex,
      });
    },
    []
  );

  const handleMoveTopic = useCallback(
    (
      weekId: string,
      sourceDayId: string,
      destDayId: string,
      topicSlotId: string
    ) => {
      dispatch({
        type: "MOVE_TOPIC",
        weekId,
        sourceDayId,
        destDayId,
        topicSlotId,
      });
    },
    []
  );

  const addWeek = useCallback(() => {
    dispatch({ type: "ADD_WEEK" });
  }, []);

  const addTest = useCallback((weekId: string) => {
    dispatch({ type: "ADD_TEST", weekId });
  }, []);

  useEffect(() => {
    onTimetableChange(timetable);
  }, [timetable, onTimetableChange]);

  if (showPreview) {
    return (
      <div className="space-y-6">
        <div className="flex justify-end">
          <button
            onClick={() => setShowPreview(false)}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 bg-indigo-50 rounded-md hover:bg-indigo-100"
          >
            <span>Edit Timetable</span>
            <Edit className="w-4 h-4" />
          </button>
        </div>
        <PreviewTimetable timetable={timetable} />
      </div>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="space-y-6">
        <div className="flex justify-end">
          <button
            onClick={() => setShowPreview(true)}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 bg-indigo-50 rounded-md hover:bg-indigo-100"
          >
            <span>Show Preview</span>
            <Eye className="w-4 h-4" />
          </button>
        </div>

        <div className="grid grid-cols-4 gap-6">
          {/* sub_modules Panel */}
          <div className="space-y-4">
            <h3 className="font-semibold text-lg">Available sub_modules</h3>
            <div className="space-y-4">
              {modules.map((module) => (
                <div key={module.id} className="space-y-2">
                  <h4 className="font-medium">{module.title}</h4>
                  {module.sub_modules.map((topic) => (
                    <DraggableTopic
                      key={topic.id}
                      topic={topic}
                      moduleId={module.id}
                      isScheduled={scheduled_sub_modules.has(topic.id)}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>

          {/* Timetable */}
          <div className="col-span-3 space-y-8">
            <div className="flex justify-between items-center">
              <h3 className="font-semibold text-lg">Course Schedule</h3>
              <Button onClick={addWeek}>
                <Plus className="w-4 h-4 mr-2" />
                Add Week
              </Button>
            </div>

            <div className="space-y-8">
              {timetable.weeks.map((week) => (
                <div key={week.id} className="space-y-4">
                  <div className="flex justify-between items-center">
                    <h4 className="font-medium text-lg">{week.title}</h4>
                    <Button onClick={() => addTest(week.id)}>
                      Add Weekly Test
                    </Button>
                  </div>
                  <div className="grid grid-cols-6 gap-4">
                    {week.days.map((day) => (
                      <div key={day.id} className="space-y-2">
                        <h5 className="font-medium">Day {day.day}</h5>
                        {day.slots.map((slot, index) => (
                          <DroppableSlot
                            key={slot.id}
                            weekId={week.id}
                            dayId={day.id}
                            slotIndex={index}
                            onDrop={handleDrop}
                            onRemove={handleRemove}
                            onReorder={handleReorder}
                            onMoveTopic={handleMoveTopic}
                            slot={slot}
                            className={slot.type === "test" ? "bg-pink-50" : ""}
                          />
                        ))}
                        <DroppableSlot
                          weekId={week.id}
                          dayId={day.id}
                          slotIndex={day.slots.length}
                          onDrop={handleDrop}
                          onRemove={handleRemove}
                          onReorder={handleReorder}
                          onMoveTopic={handleMoveTopic}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
}
