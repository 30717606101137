import { CommissionAPI } from "(apis)/(shared-apis)/latest-commission-api";
import React, { useContext, useEffect, useState } from "react";
import FullScreenPopup from "./components/full-screen-popup";
import { allowAccess } from "./utils";
import { UserContext } from "state-management/user-context";
import AuthorizationFailed from "./authorization-states/authorization-failed";

const CheckAuthorization = ({ children, role = "DIRECTOR", route = "" }) => {
  const [popup, setPopup] = useState({
    isOpen: false,
    message: "",
    heading: "",
    canDestroy: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  const { user: loggedInUser } = useContext(UserContext);

  async function fetchLoggedinCommissionUser() {
    try {
      setIsLoading(true);
      const response = await CommissionAPI("getLoggedinCommissionUser");

      if (response?.data?.success) {
        const loggedInUserData = response?.data?.data;

        if (allowAccess(loggedInUser)) {
          setIsLoading(false);
          return;
        } else if (loggedInUserData?.role !== role) {
          setPopup({
            isOpen: true,
            message: "You are not authorized to access this page.",
            canDestroy: false,
            heading: "Unauthorized Access",
          });
          return;
        }
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong.";
      setPopup({
        isOpen: true,
        message: message,
        canDestroy: false,
        heading: "Error",
      });
      console.error("Something went wrong:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (loggedInUser?.isloggedin) {
      if (allowAccess(loggedInUser)) {
        setIsLoading(false);
        return;
      }

      fetchLoggedinCommissionUser();
    }
  }, [loggedInUser]);

  // Show loading state
  if (isLoading) {
    return (
      <div className="flex flex-col justify-start mt-10 items-center h-screen bg-gray-50 p-4">
        <div className="mb-6 text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary mx-auto mb-4"></div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">
            Authorizing Access
          </h2>
          <p className="text-gray-600 max-w-md text-center">
            Please wait while we verify your permissions and access rights. This
            ensures the security of your account and the system.
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-primary animate-pulse"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
            />
          </svg>
          <span className="text-gray-700">Checking credentials...</span>
        </div>
      </div>
    );
  }

  // Show popup if authorization fails
  if (popup?.isOpen) {
    return <AuthorizationFailed message={popup?.message} />;
  }

  // Render children if authorized
  return <>{children}</>;
};

export default CheckAuthorization;
