import React, { useState } from "react";
import { Course, ContentItem } from "../../type";
import { Select } from "../ui/select";
import { FileUpload } from "shared-components/ui/file-upload";
import { Button } from "../ui/button";
import {
  Upload,
  Loader2,
  PlusCircle,
  Trash2,
  DeleteIcon,
  EditIcon,
} from "lucide-react";
import { FileManagerAPI } from "(apis)/(shared-apis)/file-manager-apis";
import toast from "react-hot-toast";
import { Input } from "shared-components/ui/CustomForm";

interface Props {
  courseData: Course;
  setCourseData: React.Dispatch<React.SetStateAction<Course>>;
}

const UploadContent: React.FC<Props> = ({ courseData, setCourseData }) => {
  const [selectedModuleIndex, setSelectedModuleIndex] = useState<number>(-1);
  const [selectedSubModuleIndex, setSelectedSubModuleIndex] =
    useState<number>(-1);
  const [selectedContentIndex, setSelectedContentIndex] = useState<number>(-1);
  const [mediaType, setMediaType] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [contentItem, setContentItem] = useState<ContentItem>({
    type: "",
    title: "",
    duration: 0,
    is_coming_soon: false,
  });

  const selectedModule = courseData?.modules?.[selectedModuleIndex];
  const selectedSubModule =
    selectedModule?.sub_modules?.[selectedSubModuleIndex];

  const handleFileSelect = (file: File | null) => {
    if (file) {
      // Validate file type
      const fileType = file.type;
      const expectedType = 
        mediaType === 'pdf' ? 'application/pdf' :
        mediaType === 'video' ? fileType.startsWith('video/') :
        fileType.startsWith('image/');

      if (!expectedType) {
        toast.error(`Please upload a valid ${mediaType.toUpperCase()} file`);
        return;
      }

      setSelectedFile(file);
      setContentItem((prev) => ({
        ...prev,
        type: mediaType,
        ...(prev.title === '' ? { title: file.name.split('.')[0] } : {}),
      }));
    }
  };

  const handleContentItemChange = (
    field: keyof ContentItem,
    value: string | number | boolean
  ) => {
    setContentItem((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAddContentItem = () => {
    if (!selectedModule || !selectedSubModule) {
      toast.error("Please select a module and sub-module first");
      return;
    }

    // Validate content item
    if (!contentItem.title || !contentItem.type) {
      toast.error("Please fill in title and type");
      return;
    }

    // Create a deep copy of course data
    const updatedCourseData = JSON.parse(JSON.stringify(courseData));

    // Find module and sub-module indices
    const moduleIndex = updatedCourseData.modules.findIndex(
      (m) => m.id === selectedModule.id
    );
    const subModuleIndex = updatedCourseData.modules[
      moduleIndex
    ].sub_modules.findIndex((sm) => sm.id === selectedSubModule.id);

    // Ensure contents array exists
    if (
      !updatedCourseData.modules[moduleIndex].sub_modules[subModuleIndex]
        .contents
    ) {
      updatedCourseData.modules[moduleIndex].sub_modules[
        subModuleIndex
      ].contents = [];
    }

    // Add or update content item
    if (selectedContentIndex >= 0) {
      // Update existing content item
      updatedCourseData.modules[moduleIndex].sub_modules[
        subModuleIndex
      ].contents[selectedContentIndex] = {
        ...contentItem,
        ...(selectedFile ? { url: "" } : {}), // Reset URL if file is selected
      };
    } else {
      // Add new content item
      updatedCourseData.modules[moduleIndex].sub_modules[
        subModuleIndex
      ].contents.push(contentItem);
    }

    // Update course data
    setCourseData(updatedCourseData);

    // Reset form
    setContentItem({
      type: "",
      title: "",
      duration: 0,
      is_coming_soon: false,
    });
    setSelectedContentIndex(-1);
    setMediaType("");
    setSelectedFile(null);

    toast.success("Content item added successfully");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedFile || !selectedModule || !selectedSubModule) {
      toast.error("Please select a file, module, and sub-module");
      return;
    }

    setIsUploading(true);

    try {
      // Create FormData
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("title", contentItem.title);

      // Call upload API
      const response = await FileManagerAPI("uploadFile", [], formData);

      if (
        response &&
        response?.data?.isSuccess &&
        response?.data?.message == "File uploaded successfully"
      ) {
        // Get the uploaded file URL
        const url = response?.data?.file?.url;

        // Create a deep copy of course data
        const updatedCourseData = JSON.parse(JSON.stringify(courseData));

        // Find module and sub-module indices
        const moduleIndex = updatedCourseData.modules.findIndex(
          (m) => m.id === selectedModule.id
        );
        const subModuleIndex = updatedCourseData.modules[
          moduleIndex
        ].sub_modules.findIndex((sm) => sm.id === selectedSubModule.id);

        // Update the content item's URL
        if (selectedContentIndex >= 0) {
          updatedCourseData.modules[moduleIndex].sub_modules[
            subModuleIndex
          ].contents[selectedContentIndex].url = url;
        } else {
          // If no content item selected, create a new one
          const newContentItem: ContentItem = {
            type: mediaType,
            title: contentItem.title,
            url: url,
            duration:
              contentItem.type === "video" ? contentItem.duration : undefined,
            is_coming_soon: contentItem.is_coming_soon || false,
          };

          // Ensure contents array exists
          if (
            !updatedCourseData.modules[moduleIndex].sub_modules[subModuleIndex]
              .contents
          ) {
            updatedCourseData.modules[moduleIndex].sub_modules[
              subModuleIndex
            ].contents = [];
          }

          // Add new content item
          updatedCourseData.modules[moduleIndex].sub_modules[
            subModuleIndex
          ].contents.push(newContentItem);
        }

        // Update course data
        setCourseData(updatedCourseData);

        toast.success("File uploaded successfully");
      }

      // Reset form
      setSelectedFile(null);
      setMediaType("");
      setContentItem({
        type: "",
        title: "",
        duration: 0,
        is_coming_soon: false,
      });
      setSelectedContentIndex(-1);
    } catch (error) {
      console.error("File upload error:", error);
      toast.error("Failed to upload file");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteContentItem = (indexToDelete: number) => {
    if (!selectedModule || !selectedSubModule) {
      toast.error("Please select a module and sub-module first");
      return;
    }

    // Create a deep copy of course data
    const updatedCourseData = JSON.parse(JSON.stringify(courseData));

    // Find module and sub-module indices
    const moduleIndex = selectedModuleIndex;
    const subModuleIndex = selectedSubModuleIndex;

    // Remove the content item
    updatedCourseData.modules[moduleIndex].sub_modules[
      subModuleIndex
    ].contents.splice(indexToDelete, 1);

    // Update course data
    setCourseData(updatedCourseData);

    // Reset form if the deleted item was being edited
    if (selectedContentIndex === indexToDelete) {
      setContentItem({
        type: "",
        title: "",
        duration: 0,
        is_coming_soon: false,
      });
      setSelectedContentIndex(-1);
    }

    toast.success("Content item deleted successfully");
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-6 mx-auto bg-white rounded-lg p-6"
    >
      {/* Module Selection */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Select Module
        </label>
        <Select
          value={selectedModuleIndex.toString()}
          onChange={(e) => {
            setSelectedModuleIndex(Number(e.target.value));
            setSelectedSubModuleIndex(-1);
            setSelectedContentIndex(-1);
          }}
          className="w-full"
        >
          <option value="-1">Select a module</option>
          {courseData?.modules?.map((module, index) => (
            <option key={module.id} value={index}>
              {index + 1}. {module.title}
            </option>
          ))}
        </Select>
      </div>

      {/* Sub Module Selection */}
      {selectedModuleIndex >= 0 && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Sub Module
          </label>
          <Select
            value={selectedSubModuleIndex.toString()}
            onChange={(e) => {
              setSelectedSubModuleIndex(Number(e.target.value));
              setSelectedContentIndex(-1);
            }}
            className="w-full"
          >
            <option value="-1">Select a sub module</option>
            {selectedModule?.sub_modules?.map((subModule, index) => (
              <option key={subModule.id} value={index}>
                {selectedModuleIndex + 1}.{index + 1} {subModule.title}
              </option>
            ))}
          </Select>
        </div>
      )}

      {/* Content Items List */}
      {selectedSubModuleIndex >= 0 && (
        <div>
          <div className="flex justify-between items-center mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Content Items
            </label>
            <button
              type="button"
              onClick={() => {
                setContentItem({
                  type: "",
                  title: "",
                  duration: 0,
                  is_coming_soon: false,
                });
                setSelectedContentIndex(-1);
              }}
              className="text-primary-500 flex items-center gap-1 text-sm"
            >
              <PlusCircle className="w-4 h-4" /> Add New
            </button>
          </div>
          {selectedSubModule?.contents?.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {selectedSubModule?.contents?.map((content, index) => (
                <div
                  key={index}
                  className="bg-white border border-gray-200 rounded-lg shadow-sm p-4 hover:shadow-md transition-all duration-300 ease-in-out"
                >
                  <div className="flex justify-between items-center mb-2">
                    <div>
                      <p className="text-sm font-semibold text-gray-800 mb-1">
                        {content?.title}
                      </p>
                      <span className="inline-block bg-primary-50 text-primary-600 text-xs  rounded-full font-bold">
                        {content?.type?.toUpperCase()}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button
                        type="button"
                        onClick={() => {
                          setContentItem(content);
                          setSelectedContentIndex(index);
                        }}
                        className="text-primary-500   rounded-full"
                        title="Edit"
                      >
                        <EditIcon className=" w-6 h-6" />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleDeleteContentItem(index)}
                        className="text-emerald-400"
                        title="Delete"
                      >
                        <Trash2 className="w-6 h-6" />
                      </button>
                    </div>
                  </div>
                  {content.url && (
                    <div className="mt-2 text-xs text-gray-500 truncate">
                      <a
                        href={content.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-primary-600 underline"
                      >
                        View File
                      </a>
                    </div>
                  )}
                  {/* {content.url && (
                    <div className="mt-4">
                      <p className="text-sm font-medium text-gray-700 mb-2">
                        File Preview
                      </p>
                      {content.type === "pdf" ? (
                        <iframe
                          src={content.url}
                          className="w-full h-64 border rounded"
                          title="PDF Preview"
                        />
                      ) : content.type === "image" ? (
                        <img
                          src={content.url}
                          alt="Content Preview"
                          className="max-w-full max-h-64 object-contain rounded"
                        />
                      ) : content.type === "video" ? (
                        <video
                          src={content.url}
                          controls
                          className="w-full h-64 rounded"
                        />
                      ) : null}
                      <a
                        href={content.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary-600 hover:underline text-sm mt-2 inline-block"
                      >
                        Open Original File
                      </a>
                    </div>
                  )} */}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500 py-4">
              No content items added yet
            </div>
          )}
        </div>
      )}

      {/* Content Item Form */}
      {selectedSubModuleIndex >= 0 && (
        <div className="space-y-4 border-t pt-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Content Title
            </label>
            <Input
              value={contentItem.title}
              onChange={(name, value) =>
                handleContentItemChange("title", value)
              }
              placeholder="Enter content title"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Media Type
            </label>
            <Select
              value={contentItem.type}
              onChange={(e) => {
                handleContentItemChange("type", e.target.value);
                setMediaType(e.target.value);
              }}
              className="w-full"
            >
              <option value="">Select media type</option>
              <option value="pdf">PDF Document</option>
              <option value="video">Video Content</option>
              <option value="image">Image Content</option>
            </Select>
          </div>

          {contentItem.type === "video" && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Duration (minutes)
              </label>
              <Input
                type="number"
                value={contentItem.duration?.toString() || "0"}
                onChange={(name, value) =>
                  handleContentItemChange("duration", Number(value))
                }
                placeholder="Enter video duration"
              />
            </div>
          )}

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="is_coming_soon"
              checked={contentItem.is_coming_soon || false}
              onChange={(e) =>
                handleContentItemChange("is_coming_soon", e.target.checked)
              }
              className="mr-2"
            />
            <label htmlFor="is_coming_soon" className="text-sm">
              Coming Soon
            </label>
          </div>

          {/* File Upload */}
          {contentItem.type && (
            <div className="border-2 border-dashed border-gray-300 rounded-lg p-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Upload {contentItem.type.toUpperCase()} File
              </label>
              <FileUpload
                onFileSelect={handleFileSelect}
                accept={
                  contentItem.type === "pdf"
                    ? ".pdf"
                    : contentItem.type === "video"
                    ? "video/*"
                    : "image/*"
                }
                maxSize={
                  contentItem.type === "video"
                    ? 50 * 1024 * 1024
                    : 5 * 1024 * 1024
                }
                previewType={contentItem.type as 'image' | 'video' | 'pdf'}
              />
              {(selectedFile || contentItem.url) && (
                <div className="mt-4">
                  <p className="text-sm font-medium text-gray-700 mb-2">
                    File Preview
                  </p>
                  {selectedFile ? (
                    <div className="flex items-center gap-2">
                      <p className="text-sm text-gray-600">
                        {selectedFile.name} (
                        {(selectedFile.size / 1024 / 1024).toFixed(2)} MB)
                      </p>
                    </div>
                  ) : contentItem.url ? (
                    <div>
                      {contentItem.type === "pdf" ? (
                        <iframe
                          src={contentItem.url}
                          className="w-full h-64 border rounded"
                          title="PDF Preview"
                        />
                      ) : contentItem.type === "image" ? (
                        <img
                          src={contentItem.url}
                          alt="Content Preview"
                          className="max-w-full max-h-64 object-contain rounded"
                        />
                      ) : contentItem.type === "video" ? (
                        <video
                          src={contentItem.url}
                          controls
                          className="w-full h-64 rounded"
                        />
                      ) : null}
                      <a
                        href={contentItem.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary-600 hover:underline text-sm mt-2 inline-block"
                      >
                        Open Original File
                      </a>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}

          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleAddContentItem}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-md bg-primary-600 text-white hover:bg-primary-700"
            >
              <PlusCircle className="w-4 h-4" />
              {selectedContentIndex >= 0 ? "Update" : "Add"} Content Item
            </button>
          </div>
        </div>
      )}

      {/* File Upload Button */}
      {selectedFile && (
        <div className="flex justify-end mt-4">
          <button
            type="submit"
            disabled={!selectedFile || isUploading}
            className={`flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-md transition-colors 
              ${
                !selectedFile || isUploading
                  ? "text-gray-500 bg-gray-100 cursor-not-allowed"
                  : "text-green-600 bg-green-50 hover:bg-green-100"
              }`}
          >
            {isUploading ? (
              <>
                <span>Uploading...</span>
                <Loader2 className="animate-spin h-4 w-4" />
              </>
            ) : (
              <>
                <span>
                  Upload{" "}
                  {contentItem.type ? contentItem.type.toUpperCase() : "File"}
                </span>
                <Upload className="w-4 h-4" />
              </>
            )}
          </button>
        </div>
      )}

      {selectedFile && (
        <p className="text-sm text-gray-500 text-center">
          Selected file: {selectedFile.name} (
          {(selectedFile.size / 1024 / 1024).toFixed(2)} MB)
        </p>
      )}
    </form>
  );
};

export default UploadContent;
