import axios from "axios";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import toast from "react-hot-toast";
import { backendUrl } from "constants/config";
import { useNavigate } from "react-router-dom";
import {
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import CheckoutMyOwnBundle from "../checkout-my-own-bundle";


export default function OwnBundle({ Theme }) {
  const navigate = useNavigate();

  const [bundles, setBundles] = useState([]);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [isOpenMobCartModal, setIsOpenMobCartModal] = useState(false);

  const GetOwnBundleComponents = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/custom-bundles/get`);
      if (response.data.success) {
        const data = response.data.data
          .filter((bundle) => bundle.isPublished === true)
          .sort((a, b) => a.position - b.position);
        setBundles(data);
        setSelectedBundle(data[0]);
      } else {
        setBundles([]);
      }
    } catch (error) {
      setBundles([]);
    }
  };

  useEffect(() => {
    GetOwnBundleComponents();
  }, []);

  const [toBeBought, setToBeBought] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);

  //on initial load
  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("custom-bundle-cart"));
    if (cart) {
      setToBeBought(cart.toBeBought);
      setTotalPrice(cart.totalPrice);
      setTotalDiscount(cart.totalDiscount);
    }
  }, [isOpenMobCartModal]);

  const handleBuyNow = () => {
    if (totalDiscount === 0) {
      toast.error("Your cart is empty. Please add items to the cart.");
      return;
    }

    const cart = {
      toBeBought,
      totalPrice,
      totalDiscount,
    };

    localStorage.setItem("custom-bundle-cart", JSON.stringify(cart));
    // navigate('/checkout/mob');
    setIsOpenMobCartModal(true);
  };

  const handleRemoveFromCart = (featureId) => {
    const tobeRemovedObject = toBeBought.find(item => item.featureId === featureId);
    
    // Guard clause if item not found
    if (!tobeRemovedObject) {
        toast.error("Item not found in cart");
        return;
    }

    const newToBeBought = toBeBought.filter(item => item.featureId !== featureId);
    const newTotalPrice = totalPrice - tobeRemovedObject.promotionalPrice;
    const newTotalDiscount = totalDiscount - tobeRemovedObject.originalPrice;

    // Update state
    setToBeBought(newToBeBought);
    setTotalPrice(newTotalPrice);
    setTotalDiscount(newTotalDiscount);

    // Update localStorage
    const cart = {
        toBeBought: newToBeBought,
        totalPrice: newTotalPrice,
        totalDiscount: newTotalDiscount,
    };
    localStorage.setItem("custom-bundle-cart", JSON.stringify(cart));
};

  const RemoveAllFromCart = () => {
    //set local storage
    localStorage.removeItem("custom-bundle-cart");
    setToBeBought([]);
    setTotalPrice(0);
    setTotalDiscount(0);
  };

  return (
    <>
      <div className="md:w-3/4 mx-auto mt-20 mb-20">
        <div className="text-center text-5xl font-bold text-black">
          Build Your{" "}
          <span className={`font-bold`} style={{ color: Theme.ColorScheme }}>
            Own Bundle
          </span>
        </div>
        <div className="flex flex-row justify-center gap-4 mt-2">
          <div className="md:w-3/4 text-center text-xl text-gray-800 font-500">
            Select the features you need and build your own bundle
          </div>
        </div>

        <div className="md:w-3/4 mx-auto mt-8 gap-4 md:gap-4 flex flex-row flex-wrap justify-center">
          {bundles?.map((board, index) => (
            <div
              key={index}
              className={cn(
                "border-2 z-10 text-sm md:text-xl p-2 rounded-full md:min-w-[8rem] md:font-bold text-center transition cursor-pointer",
                board?.boardName?.toUpperCase().trim() ===
                  selectedBundle?.boardName?.toUpperCase().trim()
                  ? `text-black border-white shadow-xl `
                  : `shadow-xl text-gray-400 hover:bg-gray-100 hover:shadow-none hover:border-gray-200 transition duration-300`
              )}
              style={
                board?.boardName?.toUpperCase().trim() ===
                selectedBundle?.boardName?.toUpperCase().trim()
                  ? { backgroundColor: Theme.ColorScheme, borderColor: "white" }
                  : { backgroundColor: "#F7F6F7", borderColor: "white" }
              }
              onClick={() => setSelectedBundle(board)}
            >
              {board?.boardName}
            </div>
          ))}
        </div>

        <BundlesView
          selectedBundle={selectedBundle}
          Theme={Theme}
          toBeBought={toBeBought}
          setToBeBought={setToBeBought}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
          totalDiscount={totalDiscount}
          setTotalDiscount={setTotalDiscount}
        />

        {toBeBought && toBeBought?.length > 0 && (
          <div className="relative bg-shadowboxes rounded shadow-xl p-4 mt-4">
            {toBeBought && toBeBought?.length > 0 && (
              <div className="absolute top-2 right-2">
                <DeleteOutlined
                  className="text-gray-700 w-4 h-4 cursor-pointer hover:text-gray-200 transition duration-300 text-sm hover:scale-110"
                  onClick={RemoveAllFromCart}
                />
              </div>
            )}
            <div className="flex flex-row justify-start items-center flex-wrap gap-2">
              {toBeBought.map((item, index) => (
                <div
                  key={index}
                  className="shadow-xl rounded p-1 px-2 flex flex-row justify-between flex-wrap items-center mt-2"
                >
                  <div className="flex gap-2 items-center text-sm md:text-md font-semibold">
                    <CheckOutlined />
                    <div className="text-md">{item.featureName}</div>
                  </div>
                  <div className="text-lg font-bold">
                    <CloseOutlined
                      className="text-gray-700 text-md cursor-pointer hover:text-gray-200 transition duration-300 text-sm hover:scale-110"
                      onClick={() => handleRemoveFromCart(item.featureId)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-row justify-end gap-2 items-end">
              <div className="text-xl text-gray-800 font-bold">
                Total {totalPrice} PKR
              </div>
              <div className="text-md text-gray-500 font-bold line-through">
                {totalDiscount} PKR
              </div>

              <button
                className="bg-primary text-white px-2 py-1 font-bold rounded shadow-2xl hover:bg-primary/90 hover:shadow-none border border-primary hover:border-gray-200 transition duration-300"
                onClick={handleBuyNow}
                disabled={totalPrice === 0}
              >
                Buy Now
              </button>
            </div>
          </div>
        )}
      </div>
      <CheckoutMyOwnBundle
        isOpenMobCartModal={isOpenMobCartModal}
        setIsOpenMobCartModal={setIsOpenMobCartModal}
      />
    </>
  );
}

const BundlesView = ({
  selectedBundle,
  Theme,
  toBeBought,
  setToBeBought,
  totalPrice,
  setTotalPrice,
  totalDiscount,
  setTotalDiscount,
}) => {
  const [selectedToggle, setSelectedToggle] = useState(null);
  const [Toggles, setToggles] = useState([]);
  const [categories, setCategories] = useState([]);

  const RandomColors = [
    "#14B614",
    "#5D5DFF",
    "#BD60FF",
    "#EE82EE",
    "#FFC65D",
    "#FFFF00",
    "#FF0000",
  ];

  useEffect(() => {
    if (selectedBundle && selectedBundle.togglesAndPricings) {
      const tog = selectedBundle.togglesAndPricings
        .filter((toggle) => toggle?.active === true)
        .sort((a, b) => a.position - b.position);
      setToggles(tog);
      setSelectedToggle(tog[0]);
    } else {
      setToggles([]);
      setSelectedToggle(null);
    }

    if (selectedBundle && selectedBundle.features) {
      // @ts-nocheck
      // const uniqueCategories = [
      //     ...new Set(
      //         selectedBundle.features
      //             .filter(feature => feature?.isPublished)
      //             .map(feature => feature.category)
      //     )
      // ];
      const uniqueCategories = Array.from(
        new Set(
          selectedBundle.features
            .filter((feature) => feature?.isPublished)
            .map((feature) => feature.category)
        )
      );
      setCategories(uniqueCategories);
    } else {
      setCategories([]);
    }
  }, [selectedBundle]);

  const handleAddToCart = (featureId, featureName, duration, access) => {
    if (duration.type === "number") {
        duration.numberOfMonths = 10000;
        toast.error("Report this to representative")
        return;
    }
    if (duration.endDate < new Date()) {
        toast.error("Report this to representative")
        return;
    }

    const isAlreadyAdded = toBeBought.some(item => item.featureId === featureId);
    const originalPrice = FeaturesAndRespectiveTogglePrice(featureId, selectedToggle, selectedBundle.togglesAndPricings)[0];
    const promotionalPrice = FeaturesAndRespectiveTogglePrice(featureId, selectedToggle, selectedBundle.togglesAndPricings)[1];

    let newToBeBought;
    let newTotalPrice;
    let newTotalDiscount;

    if (isAlreadyAdded) {
        // Remove item
        newToBeBought = toBeBought.filter(item => item.featureId !== featureId);
        newTotalPrice = totalPrice - promotionalPrice;
        newTotalDiscount = totalDiscount - originalPrice;
    } else {
        // Add item
        newToBeBought = [...toBeBought, { 
            featureId, 
            featureName, 
            duration, 
            originalPrice, 
            promotionalPrice, 
            discount: originalPrice - promotionalPrice, 
            access 
        }];
        newTotalPrice = totalPrice + promotionalPrice;
        newTotalDiscount = totalDiscount + originalPrice;
    }

    // Update state
    setToBeBought(newToBeBought);
    setTotalPrice(newTotalPrice);
    setTotalDiscount(newTotalDiscount);

    // Update localStorage
    const storageObject = {
        toBeBought: newToBeBought,
        totalPrice: newTotalPrice,
        totalDiscount: newTotalDiscount,
    }
    localStorage.setItem("custom-bundle-cart", JSON.stringify(storageObject));
};;

  return (
    <>
      <div className="md:w-3/4 mx-auto mt-8">
        <div className="gap-4 md:gap-4 flex flex-row flex-wrap justify-center">
          <div className="flex flex-row justify-between border-2 border-white rounded-full bg-shadowboxes shadow-md">
            {Toggles &&
              Toggles.map((toggle, index) => (
                <div
                  key={index}
                  className="cursor-pointer flex flex-col justify-between items-center text-sm md:text-md p-2 pl-4 pr-4 rounded-full md:min-w-[12rem] md:font-bold text-center transition border-white text-gray-800 hover:bg-gray-100 hover:shadow-none hover:border-gray-200 transition duration-300"
                  style={
                    selectedToggle?._id === toggle._id
                      ? { backgroundColor: Theme.ColorScheme, color: "white" }
                      : { backgroundColor: "#F7F6F7", color: "black" }
                  }
                  onClick={() => setSelectedToggle(toggle)}
                >
                  {toggle.toggleValue}
                  <div
                    className={`text-xs text-white font-bold rounded-full mt-1 w-fit pr-2 pl-2`}
                    style={{ backgroundColor: RandomColors[index] }}
                  >
                    {toggle.discountAmount}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
        {categories?.map((category, index) => (
          <div key={index} className="bg-shadowboxes rounded-3xl shadow-xl p-4">
            <div className="text-left text-xl text-gray-800 font-bold">
              {category}
            </div>
            <div className="mt-4">
              {selectedBundle?.features
                ?.filter(
                  (feature) =>
                    feature.category === category && feature.isPublished
                )
                .map((feature, index) => (
                  <div
                    key={index}
                    className="flex flex-row justify-between items-center mt-2"
                  >
                    <div
                      className={`flex gap-2 items-center text-sm md:text-md font-semibold ${
                        toBeBought.some(
                          (item) => item.featureId === feature._id
                        )
                          ? "text-green-500"
                          : "text-yellow-400"
                      }`}
                    >
                      <CheckOutlined />
                      <div className="text-sm">{feature.featureName}</div>
                    </div>
                    <div className="flex justify-end items-center gap-2">
                      <div className="font-bold">
                        {FeaturesAndRespectiveTogglePrice(
                          feature._id,
                          selectedToggle,
                          selectedBundle.togglesAndPricings
                        )[2] !== 0 && (
                          <span className="line-through text-gray-500 mr-2 text-xs">
                            {
                              FeaturesAndRespectiveTogglePrice(
                                feature._id,
                                selectedToggle,
                                selectedBundle.togglesAndPricings
                              )[0]
                            }{" "}
                            PKR
                          </span>
                        )}
                        <span className="text-gray-700 text-md">
                          {
                            FeaturesAndRespectiveTogglePrice(
                              feature._id,
                              selectedToggle,
                              selectedBundle.togglesAndPricings
                            )[1]
                          }{" "}
                          PKR
                        </span>
                      </div>
                      {toBeBought.some(
                        (item) => item.featureId === feature._id
                      ) ? (
                        <MinusOutlined
                          className="text-gray-700 text-sm cursor-pointer hover:text-gray-200 transition duration-300"
                          onClick={() =>
                            handleAddToCart(
                              feature._id,
                              feature.featureName,
                              FeaturesAndRespectiveTogglePrice(
                                feature._id,
                                selectedToggle,
                                selectedBundle.togglesAndPricings
                              )[3],
                              feature.access
                            )
                          }
                        />
                      ) : (
                        <PlusOutlined
                          className="text-primary text-sm cursor-pointer hover:text-gray-200 transition duration-300"
                          onClick={() =>
                            handleAddToCart(
                              feature._id,
                              feature.featureName,
                              FeaturesAndRespectiveTogglePrice(
                                feature._id,
                                selectedToggle,
                                selectedBundle.togglesAndPricings
                              )[3],
                              feature.access
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const FeaturesAndRespectiveTogglePrice = (
  featureId,
  selectedToggle,
  togglesAndPricings
) => {
  if (!selectedToggle || !togglesAndPricings) return [0, 0, 0];

  const togglePricing = togglesAndPricings.find(
    (toggle) => toggle._id === selectedToggle._id
  );
  if (!togglePricing) return [0, 0, 0];

  let duration = {
    type: "number",
    numberOfMonths: 10000,
    endDate: new Date(),
  };

  if (togglePricing.endDate !== undefined) {
    duration.type = "date";
    duration.endDate = togglePricing.endDate;
  } else {
    duration.type = "number";
    duration.numberOfMonths = togglePricing.numberOfMonths;
  }

  const featurePricing = togglePricing.featurePricings.find(
    (pricing) => pricing.featureId === featureId
  );
  if (!featurePricing) return [0, 0, 0];

  const { price, discount } = featurePricing;
  const priceAfterDiscount = price - price * discount;

  return [price, priceAfterDiscount, discount, duration];
};
