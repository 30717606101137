import React from "react";
import { Course } from "../../type";

interface Props {
  courseData: Course;
  setCourseData: React.Dispatch<React.SetStateAction<any>>;
  testimonialsData: any[];
}

const Testimonials: React.FC<Props> = ({
  courseData,
  setCourseData,
  testimonialsData,
}) => {
  const handleTestimonialClick = (id: string) => {
    const selectedTestimonials = courseData.testimonials || [];
    if (selectedTestimonials.includes(id)) {
      // Remove the testimonial ID if it's already selected
      setCourseData((prev: Course) => ({
        ...prev,
        testimonials: selectedTestimonials.filter(
          (testimonialId) => testimonialId !== id
        ),
      }));
    } else {
      // Add the testimonial ID if it's not already selected
      setCourseData((prev: Course) => ({
        ...prev,
        testimonials: [...selectedTestimonials, id],
      }));
    }
  };

  console.log("courseData.testimonials: ", courseData.testimonials);
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
      {testimonialsData?.map((testimonial) => (
        <div
          key={testimonial?._id}
          onClick={() => handleTestimonialClick(testimonial?._id)}
          className={`p-2 border rounded-lg cursor-pointer transition-colors duration-300 flex items-center space-x-3 ${
            courseData.testimonials?.includes(testimonial._id)
              ? "bg-blue-200 border-blue-500"
              : "bg-gray-100 hover:bg-gray-200"
          }`}
        >
          {testimonial?.text?.pictureUrl && (
            <img
              src={testimonial?.text?.pictureUrl}
              alt={testimonial?.name}
              className="w-10 h-10 rounded-full object-cover"
            />
          )}
          <div className="flex-grow">
            <div className="font-semibold text-sm">{testimonial?.name}</div>
            <div className="text-xs text-gray-600">
              {testimonial?.university} | Score: {testimonial?.score}
              {testimonial?.isTopper && (
                <span className="ml-2 px-1 py-0.5 bg-green-200 text-green-800 rounded-full text-[10px]">
                  Topper
                </span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Testimonials;
