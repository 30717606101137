import React from "react";
import { useNavigate } from "react-router-dom";

const AuthorizationFailed = ({ message }) => {
  const navigate = useNavigate();

  const handleGoToDashboard = () => {
    navigate("/");
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
        <div className="bg-white rounded-xl shadow-2xl max-w-md w-full p-8 text-center relative">
          <div className="absolute top-0 right-0 p-4 opacity-50">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 mx-auto text-red-500 mb-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">
              Access Restricted
            </h2>
            <p className="text-gray-600 mb-4">
              {message || "You do not have permission to access this page."}
            </p>
            <p className="text-sm text-gray-500 italic mb-6">
              Please contact your system administrator for further assistance.
            </p>

            {/* Go to Dashboard Button */}
            <div className="flex justify-center">
              <button
                onClick={handleGoToDashboard}
                className="bg-primary text-white px-4 py-2 rounded-md hover:bg-opacity-90 transition-colors duration-300"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
          <div className="animate-pulse">
            <div className="h-2 bg-gray-300 rounded mb-2"></div>
            <div className="h-2 bg-gray-300 rounded w-3/4 mx-auto"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorizationFailed;
