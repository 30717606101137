import React, { useEffect, useState, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { UserContext } from "../../state-management/user-context";
import toast, { Toaster } from "react-hot-toast";
import { backendUrl } from "constants/config";
import Papa from "papaparse";

const HomePage = () => {
  const { user } = useContext(UserContext);
  const [marketingUsers, setMarketingUsers] = useState([]);
  const [FileUpload, setFileUpload] = useState<File>();
  const [base64Image, setBase64Image] = useState("");
  const [Button1Text, setButton1Text] = useState("");
  const [Button1URL, setButton1URL] = useState("");
  const [Button2Text, setButton2Text] = useState("");
  const [Button2URL, setButton2URL] = useState("");
  const [TextNotification, setTextNotification] = useState("");
  const [Discription, setDiscription] = useState("");
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const SubmitNotification = async () => {
    if (TextNotification === "") {
      toast.error("Please enter the notification text");
      return;
    }
    if (Discription === "") {
      toast.error("Please enter the notification discription");
      return;
    }

    if (
      (Button1Text !== "" && Button1URL === "") ||
      (Button1Text === "" && Button1URL !== "")
    ) {
      toast.error(
        "Please enter both the button text and the button URL for Button 1"
      );
      return;
    }

    if (
      (Button2Text !== "" && Button2URL === "") ||
      (Button2Text === "" && Button2URL !== "")
    ) {
      toast.error(
        "Please enter both the button text and the button URL for Button 2"
      );
      return;
    }
    if (!base64Image || base64Image === "") {
      toast.error("Please upload an image");
      return;
    }
    if (marketingUsers.length === 0) {
      toast.error("Please add at least one user");
      return;
    }
 
    try {
      setIsLoading(true);
      const response = await fetch(
        `${backendUrl}/api/marketing/SendMarketingEmail`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            users: marketingUsers,
            Text: TextNotification,
            Description: Discription,
            ActionButton1Text: Button1Text,
            ActionButton1Link: Button1URL,
            ActionButton2Text: Button2Text,
            ActionButton2Link: Button2URL,
            IconSrc: base64Image,
          }),
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to send notification");
      }
      toast.success("Notification Sent Successfully");
      resetForm();
    } catch (error) {
      console.error(error);
      toast.error("Failed to send notification");
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setFileUpload(null);
    setBase64Image("");
    setTextNotification("");
    setButton1Text("");
    setButton1URL("");
    setButton2Text("");
    setButton2URL("");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleCSVUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setCsvFile(file);
      Papa.parse(file, {
        complete: (results) => {
          // Assuming emails are in the first column (index 0)
          const emails = results.data
            .slice(1) // Skip header row if exists
            .map((row: any) => row[0])
            .filter((email: string) => email && email.trim() !== ""); // Remove empty emails

          setMarketingUsers((prevUsers) => {
            // Combine existing users with new emails, removing duplicates
            const uniqueEmails = Array.from(new Set([...prevUsers, ...emails]));
            //trim the emails
            const trimmedEmails = uniqueEmails.map((email) => email.trim());
            return trimmedEmails;
          });
        },
        header: false, // Set to true if your CSV has headers
      });
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">
            Add User Emails
          </h2>
          <p className="text-gray-600 mb-4">
            Add the emails of the users you want to send the notification to
            (comma-separated or via CSV)
          </p>
          <textarea
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500"
            value={marketingUsers.join(",")}
            onChange={(e) => setMarketingUsers(e.target.value.split(","))}
            rows={4}
          ></textarea>

          <div className="mt-4">
            <label className="block mb-2 text-sm text-gray-600">
              Or Upload CSV File
            </label>
            <input
              type="file"
              accept=".csv"
              onChange={handleCSVUpload}
              className="file-input"
            />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-semibold text-gray-700">
              Send Notification
            </h2>
            <button
              className="text-rose-700 hover:text-rose-900"
              onClick={() =>
                document
                  .getElementById("Notification")
                  .style.setProperty("display", "none", "important")
              }
            >
              <i className="fas fa-times text-2xl"></i>
            </button>
          </div>
          <div className="mb-4">
            <h1 className="block mb-2 text-sm text-gray-600">
              Notification Icon
            </h1>
            <input
              id="imagefile"
              type="file"
              name="image"
              accept=".jpg,.jpeg,.png,.svg"
              onChange={handleImageChange}
              className="file-input"
            />
          </div>
          <div className="mb-4">
            <h1 className="block mb-2 text-sm text-gray-600">
              Notification Text
            </h1>
            <input
              type="text"
              id="notifText"
              name="city"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500"
              onChange={(e) => {
                setTextNotification(e.target.value);
              }}
            />
          </div>
          <div className="mb-4">
            <h1 className="block mb-2 text-sm text-gray-600">
              Notification Description
            </h1>
            <input
              type="text"
              id="notifDesc"
              name="city"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500"
              onChange={(e) => {
                setDiscription(e.target.value);
              }}
            />
          </div>

          <Toaster />
          <div className="mb-4">
            <div className="flex items-center gap-2">
              <h1 className="block mb-2 text-sm text-gray-600">Text:</h1>
              <input
                type="text"
                id="button1text"
                name="province"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light"
                onChange={(e) => {
                  setButton1Text(e.target.value);
                }}
              />
              <h1 className="block mb-2 text-sm text-gray-600">URL</h1>
              <input
                type="text"
                id="button1url"
                name="province"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light"
                onChange={(e) => {
                  setButton1URL(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center gap-2">
              <h1 className="block mb-2 text-sm text-gray-600">Text:</h1>
              <input
                type="text"
                id="button2text"
                name="province"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light"
                onChange={(e) => {
                  setButton2Text(e.target.value);
                }}
              />
              <h1 className="block mb-2 text-sm text-gray-600">URL</h1>
              <input
                type="text"
                id="button2url"
                name="province"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light"
                onChange={(e) => {
                  setButton2URL(e.target.value);
                }}
              />
            </div>
          </div>
          <button
            type="submit"
            className={`w-full text-white py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 mt-6 ${
              isLoading
                ? 'bg-rose-300 cursor-not-allowed'
                : 'bg-gradient-to-r from-rose-400 to-rose-600 hover:opacity-90'
            }`}
            onClick={SubmitNotification}
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
