import { useDrag } from "react-dnd";
import { Topic } from "../../type";
interface DraggableTopicProps {
  topic: Topic;
  moduleId: string;
  isScheduled: boolean;
}

export function DraggableTopic({
  topic,
  moduleId,
  isScheduled,
}: DraggableTopicProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "topic",
    item: {
      id: topic.id,
      moduleId,
      title: topic.title,
      type: "topic",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={`p-2 mb-2 rounded border cursor-move
        ${isDragging ? "opacity-50 bg-gray-100" : "bg-white"}
        ${isScheduled ? "border-green-500 bg-green-50" : ""}
      `}
    >
      {topic.title}
      {isScheduled && (
        <span className="ml-2 text-xs text-green-600">Scheduled</span>
      )}
    </div>
  );
}
