import { BasicGetApi } from "../../../(apis)/(shared-apis)/basic-get-apis";
import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import { useEffect, useState } from "react";
import ViewCommissionUser from "../components/ViewCommissionUser";
import {
  CommissionBanner,
  getUsersOfSpecificRole,
  ROlES,
  TableTop,
} from "../utils";
import toast, { Toaster } from "react-hot-toast";

const ManageDirectors = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [nestedStructure, setNestedStructure] = useState([]);
  const [allCommissionUsers, setAllCommissionUsers] = useState([]);
  const [allAdminUsers, setAllAdminUsers] = useState([]);

  const [isFetchingNestedStructure, setIsFetchingNestedStructure] =
    useState(false);
  const [fetchNestedStructure, setFetchNestedStructure] = useState(0);

  async function fetchNestedData() {
    setIsFetchingNestedStructure(true);

    try {
      const [hierarchyResponse] = await Promise.all([
        CommissionAPI("getHierarchy", [ROlES.DIRECTOR]),
      ]);

      if (hierarchyResponse.status === 200) {
        const hierarchyData = hierarchyResponse?.data?.data || [];

        setNestedStructure(hierarchyData);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "Failed to update status.";
      toast.error(message);

      // console.error("Error fetching data:", error);
    } finally {
      setIsFetchingNestedStructure(false);
    }
  }

  useEffect(() => {
    if (fetchNestedStructure !== 0) {
      fetchNestedData();
    }
  }, [fetchNestedStructure]);

  useEffect(() => {
    fetchData();
  }, [refreshData]);

  async function fetchData() {
    setIsLoading(true);

    try {
      const [
        adminUsersResponse,
        commissionUsersResponse,
        //  hierarchyResponse
      ] = await Promise.allSettled([
        BasicGetApi("getUserInfo"),
        CommissionAPI("getAllCommissionUsers"),
        // CommissionAPI("getHierarchy", [ROlES.DIRECTOR]),
      ]);

      // Handle adminUsersResponse
      if (
        adminUsersResponse.status === "fulfilled" &&
        adminUsersResponse.value.status === 200
      ) {
        const adminUsersData = adminUsersResponse.value?.data?.data || [];
        // console.log("Admin Users Response:", adminUsersResponse.value);
        setAllAdminUsers(adminUsersData);
      } else if (adminUsersResponse.status === "rejected") {
        // console.error("Admin Users API failed:", adminUsersResponse.reason);
      }

      // Handle commissionUsersResponse
      if (
        commissionUsersResponse.status === "fulfilled" &&
        commissionUsersResponse.value.status === 200
      ) {
        const commissionUsersData =
          commissionUsersResponse.value?.data?.data || [];
        const filteredUsers = getUsersOfSpecificRole(
          commissionUsersData,
          ROlES.DIRECTOR
        );
        // console.log(
        //   "Commission Users Response:",
        //   commissionUsersResponse.value
        // );
        setAllCommissionUsers(commissionUsersData);
        setAllUsers(filteredUsers);
      } else if (commissionUsersResponse.status === "rejected") {
        console.error(
          "Commission Users API failed:",
          commissionUsersResponse.reason
        );
        toast.error("Something went wrong");
      }

      // Handle hierarchyResponse
      // if (
      //   hierarchyResponse.status === "fulfilled" &&
      //   hierarchyResponse.value.status === 200
      // ) {
      //   const hierarchyData = hierarchyResponse.value?.data?.data || [];
      //   // console.log("Hierarchy Data:", hierarchyData);
      //   setNestedStructure(hierarchyData);
      // } else if (hierarchyResponse.status === "rejected") {
      //   console.error("Hierarchy API failed:", hierarchyResponse.reason);
      //   toast.error("Something went wrong");
      // }
    } catch (error) {
      const message =
        error?.response?.data?.message || "Failed to update status.";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    // if (e.target.value === "") {

    //   setAllUsers(allDisciplines);
    //   return;
    // }
  };

  return (
    <>
      <div className="w-full pb-20 px-5">
        <CommissionBanner
          title="Manage Directors"
          setRefreshData={setRefreshData}
          setFetchNestedStructure={setFetchNestedStructure}
          fetchNestedStructureAuto={false}
          isFetchingNestedStructure={isFetchingNestedStructure}
        />

        <RenderStats data={allCommissionUsers} />
        <Toaster />
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className=" bg-white shadow-md sm:rounded-lg">
                <TableTop
                  searchQuery={searchQuery}
                  handleSearchQueryChange={handleSearchQueryChange}
                  setAllUsers={setAllUsers}
                  adminUsers={allAdminUsers}
                  allCommissionUsers={allCommissionUsers}
                  allAdminUsers={allAdminUsers}
                  label={ROlES.DIRECTOR}
                  reportToUsers={undefined}
                />

                <ViewCommissionUser
                  allUsers={allUsers}
                  setAllUsers={setAllUsers}
                  allAdminUsers={allAdminUsers}
                  label={ROlES.DIRECTOR}
                  nestedStructure={nestedStructure}
                  adminUsers={undefined}
                  reportToUsers={undefined}
                  allCommissionUsers={undefined}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

const RenderStats = ({ data }) => {
  // console.log("data: ", data);

  const skipEmails = [
    "admin@gmail.com",
    "commission_system@gmail.com",
    "ddd@gmail.com",
    "dddd@gmail.com",
    "abrarahmed.dev.social@gmail.com",
    "abarahmed.dev@gmail.com",
    "dawsooonpark@gmail.com",
    "akuwebinar2023@gmail.com",
  ];

  const isToday = (date) => {
    const today = new Date();
    const targetDate = new Date(date);
    return (
      today.getFullYear() === targetDate.getFullYear() &&
      today.getMonth() === targetDate.getMonth() &&
      today.getDate() === targetDate.getDate()
    );
  };

  const calculateTotalSales = (users) => {
    let orderIds = [];
    users?.forEach((user) => {
      if (skipEmails.includes(user.email)) return;
      user.orders?.forEach((order) => {
        if (!orderIds.includes(order?.orderId)) orderIds.push(order?.orderId);
      });
    });
    return orderIds.length;
  };

  const calculateTodaySales = (users) => {
    let todayOrderIds = [];
    users?.forEach((user) => {
      if (skipEmails.includes(user.email)) return;
      user.orders?.forEach((order) => {
        if (
          isToday(order.createdAt) &&
          !todayOrderIds.includes(order?.orderId)
        ) {
          todayOrderIds.push(order?.orderId);
        }
      });
    });
    return todayOrderIds.length;
  };

  const calculateTotalSignups = (users) => {
    let totalSignups = 0;

    if (users && users.length > 0) {
      users.forEach((user) => {
        if (skipEmails.includes(user.email)) return;

        user.targets?.forEach((target) => {
          totalSignups += target.userIds?.length || 0;
        });
      });
    }

    return totalSignups;
  };
  const calculateTodaySignups = (users) => {
    let todaySignups = 0;
    users?.forEach((user) => {
      if (skipEmails.includes(user.email)) return;
      user.targets?.forEach((target) => {
        target.userIds?.forEach((signup) => {
          if (isToday(signup?.createdAt)) todaySignups++;
        });
      });
    });
    return todaySignups;
  };

  const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const targetDate = new Date(date);
    return (
      yesterday.getFullYear() === targetDate.getFullYear() &&
      yesterday.getMonth() === targetDate.getMonth() &&
      yesterday.getDate() === targetDate.getDate()
    );
  };

  const calculateYesterdaySales = (users) => {
    let yesterdayOrderIds = [];
    users?.forEach((user) => {
      if (skipEmails.includes(user.email)) return;
      user.orders?.forEach((order) => {
        if (
          isYesterday(order.createdAt) &&
          !yesterdayOrderIds.includes(order.orderId)
        ) {
          yesterdayOrderIds.push(order.orderId);
        }
      });
    });
    return yesterdayOrderIds.length;
  };

  const calculateTotalManagers = (users) => {
    return users?.filter((user) => user?.role === "MANAGER" && user?.isActive)
      .length;
  };

  const calculateTotalAssociates = (users) => {
    return users?.filter((user) => user?.role === "ASSOCIATE" && user?.isActive)
      .length;
  };

  return (
    <div className="flex flex-wrap items-center justify-center my-5">
      <RenderCard
        item={{
          label: "User Count",
          value: data?.filter(
            (user) => !skipEmails.includes(user.email) && user?.isActive
          ).length,
        }}
        key={1}
      />
      <RenderCard
        item={{ label: "Total Sales", value: calculateTotalSales(data) }}
        key={2}
      />
      <RenderCard
        item={{
          label: "Sales (Yesterday)",
          value: calculateYesterdaySales(data),
        }}
        key={3}
      />
      <RenderCard
        item={{
          label: "Sales (Today)",
          value: calculateTodaySales(data),
        }}
        key={4}
      />
      <RenderCard
        item={{ label: "Total Signups", value: calculateTotalSignups(data) }}
        key={5}
      />
      <RenderCard
        item={{ label: "Signups (Today)", value: calculateTodaySignups(data) }}
        key={6}
      />
      <RenderCard
        item={{ label: "Active Managers", value: calculateTotalManagers(data) }}
        key={7}
      />
      <RenderCard
        item={{
          label: "Active Associates",
          value: calculateTotalAssociates(data),
        }}
        key={8}
      />
    </div>
  );
};

const RenderCard = ({
  item,
  key,
}: {
  item: { label: string; value: number };
  key: number;
}) => {
  return (
    <div
      className="w-full md:max-w-xs p-2 lg:w-1/3 md:w-1/2 cursor-pointer"
      key={key}
    >
      <div className="flex flex-col p-5 overflow-hidden bg-white hover:bg-gradient-to-br  rounded-xl shadow-lg duration-300 hover:shadow-2xl group transition-all ease-in-out">
        <h1 className="text-3xl sm:text-4xl xl:text-5xl font-bold text-gray-700  ">
          {item?.value}
        </h1>
        <div className="flex flex-row justify-between ">
          <p className="font-semibold text-lg text-gray-800">{item?.label}</p>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-pink-600 "
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ManageDirectors;
