import React, { useState, useEffect } from "react";
import { Course } from "../../type";
import { Trash2, Plus, Construction } from "lucide-react";
import { Input } from "shared-components/ui/CustomForm";
import { Textarea } from "../ui/textarea";

interface Props {
  courseData: Course;
  setCourseData: React.Dispatch<React.SetStateAction<Course>>;
}

const Faqs: React.FC<Props> = ({ courseData, setCourseData }) => {
  const [newFaq, setNewFaq] = useState({ question: "", answer: "" });

  const handleAddFaq = () => {
    if (newFaq?.question?.trim() && newFaq?.answer?.trim()) {
      setCourseData((prev) => ({
        ...prev,
        faqs: [
          ...(prev.faqs || []),
          {
            question: newFaq.question,
            answer: newFaq.answer,
          },
        ],
      }));
      // Reset input fields
      setNewFaq({ question: "", answer: "" });
    }
  };

  const handleRemoveFaq = (indexToRemove: number) => {
    setCourseData((prev) => ({
      ...prev,
      faqs: prev.faqs?.filter((_, index) => index !== indexToRemove) || [],
    }));
  };

  return (
    <div className="relative space-y-6 h-[400px]">
      {/* Coming Soon Overlay */}

      <div className="absolute inset-0 bg-white/70  backdrop-blur-sm z-50 flex flex-col items-center justify-center">
        <Construction className="w-24 h-24 text-gray-400 mb-4" />
        <h3 className="text-2xl font-semibold text-gray-700 mb-2">
          Coming Soon
        </h3>
        <p className="text-gray-500 text-center max-w-md">
          We are working on developing the FAQs section. Check back later for
          more information about this course.
        </p>
      </div>

      {/* <h2 className="text-2xl font-bold text-gray-800">Frequently Asked Questions</h2>

            Existing FAQs List
            {courseData.faqs && courseData.faqs.length > 0 && (
                <div className="space-y-4">
                    {courseData.faqs.map((faq, index) => (
                        <div
                            key={index}
                            className="flex items-end space-x-4 border-b pb-4"
                        >
                            <div className="flex-grow space-y-2 w-full">
                                <label
                                    htmlFor={`faq-question-${index}`}
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Question
                                </label>
                                <Input
                                    type="text"
                                    name={`faq-question-${index}`}
                                    value={faq.question}
                                    onChange={(name, value) => {
                                        const updatedFaqs = [...(courseData.faqs || [])];
                                        updatedFaqs[index] = {
                                            ...updatedFaqs[index],
                                            question: value
                                        };
                                        setCourseData(prev => ({ ...prev, faqs: updatedFaqs }));
                                    }}
                                    placeholder="Enter question"
                                />

                                <label
                                    htmlFor={`faq-answer-${index}`}
                                    className="block text-sm font-medium text-gray-700 mt-2"
                                >
                                    Answer
                                </label>
                                <Textarea
                                    name={`faq-answer-${index}`}
                                    value={faq.answer}
                                    onChange={(e) => {
                                        const updatedFaqs = [...(courseData.faqs || [])];
                                        updatedFaqs[index] = {
                                            ...updatedFaqs[index],
                                            answer: e.target.value
                                        };
                                        setCourseData(prev => ({ ...prev, faqs: updatedFaqs }));
                                    }}
                                    placeholder="Enter answer"
                                />
                            </div>
                            <button
                                onClick={() => handleRemoveFaq(index)}
                                className="text-red-500 hover:text-red-700 mb-2"
                            >
                                <Trash2 className="h-5 w-5" />
                            </button>
                        </div>
                    ))}
                </div>
            )} */}

      {/* New FAQ Input */}
      {/* <div className="space-y-4 border-t pt-6">
                <h3 className="text-lg font-semibold text-gray-800">Add New FAQ</h3>
                <div>
                    <label
                        htmlFor="new-faq-question"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Question
                    </label>
                    <Input
                        type="text"
                        name="new-faq-question"
                        value={newFaq.question}
                        onChange={(name, value) => setNewFaq(prev => ({ ...prev, question: value }))}
                        placeholder="Enter new question"
                    />
                </div>
                <div>
                    <label
                        htmlFor="new-faq-answer"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Answer
                    </label>
                    <Textarea
                        name="new-faq-answer"
                        value={newFaq.answer}
                        onChange={(e) => setNewFaq(prev => ({ ...prev, answer: e.target.value }))}
                        placeholder="Enter new answer"
                    />
                </div>
                <button
                    onClick={handleAddFaq}
                    className="flex items-center bg-green-50 text-green-600 px-4 py-2 rounded-md hover:bg-green-100 transition-colors"
                >
                    <Plus className="h-5 w-5 mr-2" /> Add FAQ
                </button>
            </div> */}
    </div>
  );
};

export default Faqs;
