import React from 'react'
import CustomButton from './form-fields/custom-button';
import { Select } from 'antd';
import { logsOfListPionts } from 'constants/constantvalues';
import ReactQuill from 'react-quill';
import GlobalPublishButton from './ui/CustomButtons';
import { Label } from './ui/CustomForm';

const TagComponent = ({ formik }) => {

  const { values, errors, touched, setFieldValue } = formik;
  const handleAddTagDetails = () => {
    if (values?.tagDetails) {
      setFieldValue("tagDetails", [
        ...values?.tagDetails,
        { description: "", logo: "", isActive: true, position: '' },
      ]);
    } else {
      setFieldValue("tagDetails", [{ description: "", logo: "", isActive: true, position: '' }]);
    }
  };
  const handleTagDetailsChange = (index, key, value) => {
    const updateTagDetails = [...values?.tagDetails];
    updateTagDetails[index][key] = value;
    setFieldValue("tagDetails", updateTagDetails);
  };
  const handleTagDetailsRemove = (index) => {
    const updatedTagDetails = [...values?.tagDetails];
    updatedTagDetails.splice(index, 1);
    setFieldValue("tagDetails", updatedTagDetails);
  };
  return (

    <div>
      {Array.isArray(values?.tagDetails) &&
        values?.tagDetails?.map((detail, index) => (
          <div
            key={index}
            className={`overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold p-2 mb-4`}
          >
            <div className="mx-auto p-2 pt-0">
              <div className="flex justify-between my-1 mb-2">
                <Label>Tag {index + 1}</Label>
                {index > 0 && (
                  <CustomButton
                    onClick={() => handleTagDetailsRemove(index)}
                    text="Remove"
                  />
                )}
              </div>
              <div>
                <Label>
                  Select Logo:
                </Label>
                <Select
                  showSearch
                  defaultValue={values?.tag || ""}
                  className="w-full p-6 px-2 border border-gray-200 rounded"
                  style={{
                    width: "100%",
                  }}
                  id={`logo${index}`}
                  placeholder={`Select `}
                  value={detail.logo || []}
                  onChange={(content) =>
                    handleTagDetailsChange(index, "logo", content)
                  }
                  bordered={false}
                  options={logsOfListPionts}
                />
              </div>
              {/* Additional fields for subtopics can be added here */}
              {errors?.tagDetails?.[index]?.logo &&
                touched?.tagDetails?.[index]?.logo && (
                  <span className="text-red-500">
                    {errors?.tagDetails?.[index]?.logo}
                  </span>
                )}
            </div>
            <div className="mx-auto p-2 pt-0">

              <div>
                <Label>Description</Label>
                <ReactQuill
                  id={`description${index}`}
                  placeholder={`Enter `}
                  value={detail.description}
                  onChange={(content) =>
                    handleTagDetailsChange(index, "description", content)
                  }
                  className="border border-gray-300 rounded"
                />
              </div>
              {/* Additional fields for subtopics can be added here */}
              {errors?.tagDetails?.[index]?.description &&
                touched?.tagDetails?.[index]?.description && (
                  <span className="text-red-500">
                    {errors?.tagDetails?.[index]?.description}
                  </span>
                )}
            </div>
            <div className="mx-auto p-2 pt-0">

              <div className="flex flex-col gap-2">
                <Label>Position</Label>
                <input
                  type="number"
                  id={`position${index}`}
                  name={`position${index}`}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder={`Enter `}
                  value={detail.position}
                  onChange={(e) =>
                    handleTagDetailsChange(index, "position", e.target.value)
                  }
                />
              </div>
              {errors?.tagDetails?.[index]?.position &&
                (
                  <span className="text-red-500">
                    {errors?.tagDetails?.[index]?.position}
                  </span>
                )}
            </div>
            <div className="flex justify-end items-end me-2">
              <GlobalPublishButton
                isActive={detail?.isActive}
                onChange={() => {
                  handleTagDetailsChange(
                    index,
                    "isActive",
                    !detail?.isActive
                  );

                }}
              />

            </div>
          </div>
        ))}
      <CustomButton
        onClick={handleAddTagDetails}
        text={`Add  ${values?.tagDetails?.length > 0 ? "More" : "Tag"}`}
      />
      {
        !Array.isArray(errors?.tagDetails) && (
          <div className="text-red-500 text-sm mt-1">
            {errors?.tagDetails && (<>
              {errors?.tagDetails}
            </>)}
          </div>
        )
      }
    </div>

  )
}

export default TagComponent
