import { useContext, useEffect, useState } from "react";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../shared-components/ui/CustomDropDown";
import { InfoIcon } from "../../../shared-components/ui/Icons";

import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";

import toast, { Toaster } from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import { Input, Label } from "../../../shared-components/ui/CustomForm";
import { CouponModalProps } from "../interfaces/latest-commission-interface";
import {
  allowAccess,
  formatNumberWithCommas,
  getTeamLength,
  getUserIdField,
  renderChips,
  ROlES,
} from "../utils";
import ReimbursementTable from "./components/reimbursement-table";
import NestedOrders from "./components/view-nested-orders";
import ViewSalesTable from "./components/view-sales-table";
import CustomBadges from "shared-components/ui/CustomBadges";
import TargetModal from "./components/TargetModal";
import TargetManagement from "./components/Target-Management";
import ViewUserTargetTable from "./components/view-user-target-table";
import ViewStats from "./components/view-stats";
import FullScreenPopup from "../components/full-screen-popup";
import { UserContext } from "state-management/user-context";
import ViewPendingSales from "./components/view-pending-sales";

const ViewUserDetail = () => {
  const { user: loggedInUser } = useContext(UserContext);

  const location = useLocation();
  const [selectStatus, setSelectStatus] = useState("Active");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [user, setUser] = useState(null);
  const [userCompensationDetail, setUserCompensationDetail] = useState(null);
  const [reimbursements, setReimbursements] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useParams();
  const id = params?.id;
  const [nestedStructure, setNestedStructure] = useState([]);
  const [displayScreen, setDisplayScreen] = useState<string>("Overview");
  const [pendingOrders, setPendingOrders] = useState([]);
  // const [displayScreen, setDisplayScreen] = useState<string>("My Sales");
  const [coupons, setCoupons] = useState([]);
  const urlParams = new URLSearchParams(location.search);
  const requestedRole = urlParams.get("role");

  useEffect(() => {
    if (loggedInUser?.isloggedin) {
      fetchData();
    }
  }, [refreshData, id, requestedRole, loggedInUser]);

  const [popup, setPopup] = useState({
    isOpen: false,
    message: "",
    heading: "",
    canDestroy: false,
  });

  async function fetchData() {
    setIsLoading(true);

    try {
      const response = await CommissionAPI("getCommissionUserById", [], {
        commissionId: id,
        // role: requestedRole,
        allowAccess: allowAccess(loggedInUser),
        // allowAccess: allowAccess(loggedInUser),
      });

      if (response) {
        const fetchedUser = response?.data?.data?.user;
        const fetchedCompensation = response?.data?.data?.compensationUser;
        const fetchedReimbursements = response?.data?.data?.reimbursements;
        const fetchedNestedStructure = response?.data?.data?.nestedStructure;
        const fetchedCoupons = response?.data?.data?.coupons;
        const pendingOrders = response?.data?.data?.pendingOrders;
        // console.log("pendingOrders: ", pendingOrders);
        setReimbursements(fetchedReimbursements);
        setUser(fetchedUser);
        setUserCompensationDetail(fetchedCompensation);
        setNestedStructure(fetchedNestedStructure);
        setCoupons(fetchedCoupons);
        setPendingOrders(pendingOrders);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "something went wrong.";
      toast.error(message);
      setPopup({
        isOpen: true,
        message: message,
        canDestroy: false,
        heading: "",
      });
    } finally {
      setIsLoading(false);
    }
  }

  const initialModalState: {
    isOpen: boolean;
    data: any;
  } = {
    isOpen: false,
    data: null,
  };

  const [couponDetailsModal, setCouponDetailsModal] = useState<{
    isOpen: boolean;
    data: any;
  }>(initialModalState);

  const getViewOptions = (user: any) => {
    if (user?.role === ROlES.DIRECTOR) {
      return [
        "Overview",
        "All Sales",
        "My Sales",
        // "Reimbursement",
        "Hierarchy Order View",
      ];
    } else if (user?.role === ROlES.MANAGER) {
      return [
        "Overview",
        "All Sales",
        "My Sales",
        "Pending Sales",
        // "Reimbursement",
        "Hierarchy Order View",
        "Target Management",

        // "Leaderboard",
      ];
    } else {
      return [
        "Overview",
        "My Sales",
        "Pending Sales",
        "Reimbursement",
        "My Target",
      ];
    }
  };

  const disableReimubrsement = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const dayOfMonth = currentDate.getDate();

    // Check if current date is NOT between Nov 28 and Dec 1
    // const isOutsideDateRange =
    //   (currentMonth !== 10 || dayOfMonth < 28) && // Not November 28, 29, 30
    //   (currentMonth !== 11 || dayOfMonth > 1); // Not December 1

    // const isOutsideDateRange =
    //   dayOfMonth < 28 && // Not November 28, 29, 30
    //   dayOfMonth > 31; // Not December 1

    const isOutsideDateRange = true;

    // Check if pending balance is zero
    const isZeroPendingBalance = userCompensationDetail?.pendingBalance === 0;

    // Disable reimbursement if outside date range OR zero pending balance
    return isOutsideDateRange || isZeroPendingBalance;
  };

  const isPendingReimbursement = () => {
    let flag = false;
    reimbursements?.forEach((item: any) => {
      if (item?.status === "Pending") {
        flag = true;
      }
    });
    return flag;
  };

  const calculatePendingOrderAmount = () => {
    let totalPendingOrdersAmount = 0;
    pendingOrders?.forEach((order) => {
      const orderAmount =
        (order?.BREAKDOWN?.GrandTotal || 0) *
        ((user?.commissionPercentage || 0) / 100);
      totalPendingOrdersAmount = totalPendingOrdersAmount + orderAmount;
    });

    return totalPendingOrdersAmount;
  };

  if (popup?.isOpen) {
    return <FullScreenPopup popup={popup} setPopup={setPopup} />;
  }

  return (
    <>
      <Toaster />
      {/* =========================== */}
      {/*  ReimbursementForm Modal*/}
      <ReimbursementForm
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        user={user}
        compensationDetails={userCompensationDetail}
      />
      {/* =========================== */}
      {/* =========================== */}
      <div className="w-full pb-20 md:px-5">
        <div className="flex gap-5 flex-wrap items-center justify-between pb-1 mt-5">
          <div className="">
            <div className="mb-6">
              <CustomBadges
                label={`CODE: ${coupons[0]?.couponCode || "Not Assigned"}`}
                color="success"
                showIcon
                textSize="lg"
              />
            </div>
            {user && (
              <h2 className="text-2xl font-bold text-gray-700 flex items-center space-x-3">
                <span className=""> {user?.name}</span>{" "}
                {renderChips(user?.role, "lg")}
                {user?.isActive === false && (
                  <CustomBadges
                    label="DEACTIVATED"
                    type="error"
                    showIcon
                    textSize="xl"
                  />
                )}
              </h2>
            )}
          </div>
          <ButtonOutlined
            handleClick={() => {
              setRefreshData((prev) => prev + 1);
            }}
          >
            Refresh
          </ButtonOutlined>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className=" bg-white shadow-md sm:rounded-lg">
                {/* <TableTop
                  searchQuery={searchQuery}
                  handleSearchQueryChange={handleSearchQueryChange}
                  setAllUsers={setAllUsers}
                  adminUsers={adminUsers}
                /> */}
                <div className="flex flex-wrap items-center p-4 space-x-3">
                  <CustomDropDown
                    label="View"
                    options={getViewOptions(user)}
                    onSelect={(selectedStatus) =>
                      // handleSelectStatusChange(selectedStatus.toString())
                      setDisplayScreen(selectedStatus)
                    }
                    disabled={!user}
                    width="min-w-[12rem]"
                    value={displayScreen}
                  />
                </div>
                <div className="flex flex-wrap items-stretch gap-6 my-10 mx-10">
                  <div className="p-5 shadow-lg rounded-md text-center w-full md:w-fit">
                    <h1 className="font-semibold">Total Amount Earned</h1>
                    <p className="font-bold text-3xl text-slate-800">
                      {userCompensationDetail?.totalAmountEarned?.toFixed(2)}
                    </p>
                  </div>
                  <div className="p-5 shadow-lg rounded-md text-center w-full md:w-fit">
                    <h1 className="font-semibold">Pending Balance</h1>
                    <p className="font-bold text-3xl text-slate-800">
                      {userCompensationDetail?.pendingBalance?.toFixed(2)}
                    </p>
                  </div>
                  <div className="p-5 shadow-lg rounded-md text-center w-full md:w-fit">
                    <h1 className="font-semibold">Paid Amount</h1>
                    <p className="font-bold text-3xl text-slate-800">
                      {userCompensationDetail?.paidAmount?.toFixed(2)}
                    </p>
                  </div>
                  {displayScreen === "Pending Sales" && (
                    <div className="p-5 shadow-lg rounded-md text-center w-full md:w-fit">
                      <h1 className="font-semibold">Pending Sales Amount</h1>
                      <p className="font-bold text-3xl text-slate-800">
                        {calculatePendingOrderAmount()}
                      </p>
                    </div>
                  )}
                  {/* {displayScreen === "Reimbursement" && ( */}
                  <>
                    <button
                      className={`shadow-lg rounded-md disabled:opacity-80 disabled:cursor-not-allowed  text-white  hover:bg-gradient-to-br  focus:outline-none  font-medium  text-sm p-5 text-center  cursor-pointer ${
                        isPendingReimbursement()
                          ? "bg-gradient-to-r from-amber-400 via-amber-500 to-amber-600"
                          : disableReimubrsement()
                          ? "bg-gradient-to-r from-rose-400 via-rose-500 to-rose-600"
                          : "bg-gradient-to-r from-green-400 via-green-500 to-green-600"
                      }`}
                      onClick={() => {
                        if (loggedInUser?.username !== user?.email) {
                          toast.error(
                            "Oops! You can only request reimbursement for your own account.",
                            {
                              duration: 4000,
                              style: {
                                background: "#FEE2E2",
                                color: "#7F1D1D",
                              },
                            }
                          );
                          return;
                        }
                        if (!user?.isActive) {
                          toast.error(
                            "Sorry you can't request for reimbursement",
                            {
                              duration: 4000,
                              style: {
                                background: "#FEE2E2",
                                color: "#7F1D1D",
                              },
                            }
                          );
                          return;
                        }
                        if (disableReimubrsement()) {
                          toast.error(
                            "Sorry you can't request for reimbursement",
                            {
                              duration: 4000,
                              style: {
                                background: "#FEE2E2",
                                color: "#7F1D1D",
                              },
                            }
                          );
                          return;
                        }
                        if (isPendingReimbursement()) {
                          toast.error(
                            "Sorry you can't request for reimbursement"
                          );
                          return;
                        }
                        setIsModalOpen(true);
                      }}
                      // disabled={disableReimubrsement()}
                    >
                      <h1 className="font-semibold text-lg">
                        Request <br /> Reimbursement
                      </h1>
                    </button>
                  </>
                  {/* )} */}
                </div>
                {displayScreen === "Overview" && (
                  <ViewStats user={user} pendingOrders={pendingOrders} />
                )}
                {displayScreen === "All Sales" && (
                  <ViewSalesTable
                    orders={user?.orders}
                    setCouponDetailsModal={setCouponDetailsModal}
                    managerEmail={user?.email}
                  />
                )}
                {displayScreen === "My Sales" && (
                  <ViewSalesTable
                    orders={user?.orders}
                    setCouponDetailsModal={setCouponDetailsModal}
                    showMySales
                    managerEmail={user?.email}
                  />
                )}
                {displayScreen === "Reimbursement" && (
                  <>
                    <ReimbursementTable reimbursements={reimbursements} />
                  </>
                )}
                {displayScreen === "Target Management" &&
                  user?.role !== ROlES.ASSOCIATE && (
                    <>
                      <TargetManagement
                        supervisor={user}
                        screen="Target Management"
                      />
                    </>
                  )}
                {displayScreen === "My Target" && (
                  <>
                    <TargetManagement supervisor={user} screen="My Target" />
                  </>
                )}
                {displayScreen === "Hierarchy Order View" &&
                  user?.role !== ROlES.ASSOCIATE && (
                    <NestedOrders
                      users={nestedStructure}
                      level={0}
                      showOrders
                      setCouponDetailsModal={setCouponDetailsModal}
                      managerEmail={user?.email}
                      activeUsersOnly={true}
                    />
                  )}
                {displayScreen === "Pending Sales" && (
                  <>
                    <ViewPendingSales
                      orders={pendingOrders}
                      setCouponDetailsModal={setCouponDetailsModal}
                      showMySales
                      user={user}
                    />
                  </>
                )}
                {displayScreen === "Leaderboard" && (
                  <>
                    <ViewPendingSales
                      orders={pendingOrders}
                      setCouponDetailsModal={setCouponDetailsModal}
                      showMySales
                      user={user}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export const ReimbursementForm = (props) => {
  const minimumAmountUserCanRequest = 5000;
  const [requestedAmount, setRequestedAmount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [requestAmountType, setRequestAmountType] = useState(
    "Total Pending Balance"
  );
  const [isLoading, setIsLoading] = useState(false);

  // Bank details states
  const [bankDetails, setBankDetails] = useState({
    accountTitle: "",
    bankName: "",
    accountNumber: "",
  });

  const resetForm = () => {
    setRequestedAmount(0);
    setPendingAmount(0);
    setRequestAmountType("Total Pending Balance");
    setBankDetails({
      accountTitle: "",
      bankName: "",
      accountNumber: "",
    });
    props.setIsModalOpen(false);
  };

  useEffect(() => {
    if (props?.compensationDetails) {
      const pendingAmount = props?.compensationDetails?.pendingBalance || 0;
      setPendingAmount(pendingAmount);
      setRequestedAmount(pendingAmount);
    }
  }, [props?.compensationDetails]);

  const handleInputChange = (name, value) => {
    if (name === "requestedAmount") {
      setRequestedAmount(value);
    } else {
      setBankDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const [error, setError] = useState(null);
  const closeModal = () => {
    resetForm();
  };

  const submit = async (e) => {
    e.preventDefault();

    // Validation for requested amount
    if (
      !requestedAmount ||
      requestedAmount <= 0 ||
      requestedAmount > pendingAmount ||
      requestedAmount > props?.compensationDetails?.pendingBalance ||
      props?.compensationDetails?.pendingBalance === 0 ||
      !props?.compensationDetails?.pendingBalance
    ) {
      setError("Invalid Amount");
      return;
    }

    // Validation for bank details
    if (
      !bankDetails.accountTitle ||
      !bankDetails.bankName ||
      !bankDetails.accountNumber
    ) {
      setError("All bank details are required.");
      return;
    }

    setIsLoading(true);

    try {
      const data = {
        userId: props?.user?.userId,
        requestedAmount: requestedAmount,
        email: props?.user?.email,
        name: props?.user?.name,
        role: props?.user?.role,
        bankDetails,
      };
      const response = await CommissionAPI("createReimbursement", [], data);

      if (response?.data?.isSuccess) {
        toast.success("Submit Successfully.");
        resetForm();
      }
    } catch (error) {
      console.error("Error createReimbursement:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      id="deleteModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props.isModalOpen ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40 backdrop-blur-sm`}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        {/* Modal content */}
        <div className="relative p-4 text-center bg-white rounded-lg shadow ddark:bg-gray-800 sm:p-5">
          <button
            type="button"
            className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ddark:hover:bg-gray-600 ddark:hover:text-white"
            onClick={() => closeModal()}
          >
            {/* Close Icon */}
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <p className="mb-4 text-gray-500 text-xl ddark:text-gray-300">
            Reimbursement Form
          </p>

          <form
            onSubmit={(e) => submit(e)}
            className="text-left my-5 space-y-5"
          >
            {/* Pending Balance */}
            <p className="mb-4 text-gray-700 text-center font-semibold text-lg ddark:text-gray-300">
              Pending Balance : {formatNumberWithCommas(pendingAmount)}
            </p>

            {/* Bank Details */}
            <div className="space-y-2">
              <Label>Account Title</Label>
              <Input
                type="text"
                name="accountTitle"
                value={bankDetails?.accountTitle}
                onChange={(name, value) =>
                  handleInputChange("accountTitle", value)
                }
                isError={false}
              />
            </div>
            <div className="space-y-2">
              <Label>Bank Name</Label>
              <Input
                type="text"
                name="bankName"
                value={bankDetails?.bankName}
                onChange={(name, value) => handleInputChange("bankName", value)}
                isError={false}
              />
            </div>
            <div className="space-y-2">
              <Label>Account Number</Label>
              <Input
                type="text"
                name="accountNumber"
                value={bankDetails?.accountNumber}
                onChange={(name, value) =>
                  handleInputChange("accountNumber", value)
                }
                isError={false}
              />
            </div>

            {/* Error */}
            {error && (
              <p className="mb-4 text-red-700 text-center font-semibold text-lg ddark:text-gray-300">
                {error}
              </p>
            )}

            {/* Buttons */}
            <div className="flex justify-center items-center space-x-4">
              <ButtonOutlined handleClick={() => closeModal()}>
                Cancel
              </ButtonOutlined>
              <ButtonFill type="Submit" disabled={isLoading}>
                {isLoading ? "Loading.." : "Submit"}
              </ButtonFill>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const CouponModal = ({
  modal,
  setModal,
  initialModalState,
}: CouponModalProps) => {
  return (
    <>
      <div
        id="deleteModal"
        tabIndex={-1}
        aria-hidden="true"
        className={`${
          modal.isOpen ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          {/* Modal content */}
          <div className="relative p-4 text-center bg-white rounded-lg shadow ddark:bg-gray-800 sm:p-5">
            <button
              type="button"
              className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ddark:hover:bg-gray-600 ddark:hover:text-white"
              data-modal-toggle="deleteModal"
              onClick={() => setModal(initialModalState)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>

            {modal?.data ? (
              <>
                <div className="text-left space-y-1">
                  <p className="mb-4 text-gray-500 ddark:text-gray-300">
                    Coupon Details:
                  </p>

                  <div className="flex  space-x-2">
                    <span className="font-semibold">Coupon Code:</span>
                    <span className="text-emerald-600 font-semibold">
                      {modal?.data?.code}
                    </span>
                  </div>
                  <div className="flexx  space-y-2">
                    <span className="font-semibold inline-block">
                      Purchased User:
                    </span>
                    <span className="">{modal?.data?.purchasedUserEmail}</span>
                  </div>
                  <div className="space-y-2">
                    <span className="font-semibold inline-block">
                      Coupon User :
                    </span>
                    <span className="">{modal?.data?.couponUser?.email}</span>
                  </div>
                  <div className="flex space-x-2">
                    <span className="font-semibold">Coupon User Role:</span>
                    <span className="">
                      {renderChips(modal?.data?.couponUser?.role)}
                    </span>
                  </div>
                  <div className="flex space-x-2">
                    <span className="font-semibold">Order Id:</span>
                    <span className="">{modal?.data?.orderId || "N/A"}</span>
                  </div>
                </div>
              </>
            ) : (
              <p className=" text-gray-700 ddark:text-gray-300">
                No Coupon Details Available
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewUserDetail;
