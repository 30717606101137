import { apiRequest } from "(apis)/api-interface/api-handler";
import { message, Select } from "antd";
import { useEffect, useState } from "react";
import { Input, Label } from "shared-components/ui/CustomForm";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import Loader from "shared-components/Loader";
import ScheduleTable from "./schedule-table";

const Scheduled = ({
  id,
}: {
  id: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [subjectData, setSubjectData] = useState([]);
  const [scheduledCourseTopicData, setScheduledCourseTopicData] = useState([]);

  // Form state
  const [formData, setFormData] = useState({
    topic: "",
    date: "",
    markedValue: "",
    subject: "",
  });

  // Form validation errors
  const [errors, setErrors] = useState({
    topic: "",
    date: "",
    markedValue: "",
    subject: "",
  });

  const getAllSubjects = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getallsubjects");
      if (res?.status === 200) {
        const formatedSubjectData = res?.data?.map((subject: any) => ({
          value: subject.name,
          label: subject.name,
        }));
        setSubjectData(formatedSubjectData);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getScheduleData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getSchedule", null, [id]);
      if (res?.status === 200) {
        setScheduledCourseTopicData(res.data.scheduledCourses);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllSubjects();
    getScheduleData();
  }, []);

  const validateForm = () => {
    const newErrors = {
      topic: "",
      date: "",
      markedValue: "",
      subject: "",
    };

    // Check for empty fields
    if (!formData.topic) newErrors.topic = "Topic is required";
    if (!formData.date) newErrors.date = "Date is required";
    if (!formData.markedValue) newErrors.markedValue = "Marked value is required";
    if (!formData.subject) newErrors.subject = "Subject is required";

    // Check for unique topic
    const existingTopics = scheduledCourseTopicData?.map((data) => data.topic);
    if (existingTopics?.includes(formData.topic)) {
      newErrors.topic = "Topic name already exists";
    }

    setErrors(newErrors);
    return Object.values(newErrors).every(error => error === "");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("formData", formData);
    // Validate form
    if (!validateForm()) {
      message.error("Please fill in all required fields correctly");
      return;
    }

    try {
      const payLoad = {
        ...formData,
        courseId: id,
      };

      const res = await apiRequest("addSchedule", payLoad);
      
      if (res?.status === 200) {
        message.success(res?.data?.message || "Schedule added successfully");
        
        // Refresh schedule data and reset form
        await getScheduleData();
        setFormData({
          topic: "",
          date: "",
          markedValue: "",
          subject: "",
        });
      } else {
        message.error(res?.data?.message || "Failed to add schedule");
      }
    } catch (error) {
      console.error("Schedule submission error:", error);
      message.error(
        error?.response?.data?.message || 
        error?.message || 
        "An unexpected error occurred"
      );
    }
  };

  const handleInputChange = (name: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMarkedValueChange = (value: string) => {
    setFormData(prev => ({
      ...prev,
      markedValue: value,
    }));
  };

  return (
    <div className="w-full">
      <Loader loading={loading} />
      <form
        onSubmit={handleSubmit}
        className="rounded-lg shadow-lg p-4 bg-white m-4"
      >
        <div className="flex flex-row justify-between">
          <div className="font-bold text-rose-500">Add Schedule</div>
          <div>
            <button
              type="submit"
              className="flex items-center bg-rose-500 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Add To Schedule
            </button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row ">
          <div className="md:p-2 w-full md:w-1/3">
            <Label>Topic</Label>
            <Input
              name="topic"
              value={formData.topic}
              onChange={handleInputChange}
              placeholder="Topic"
            />
            {errors.topic && <div className="text-red-500 text-sm mt-1">{errors.topic}</div>}
          </div>
          <div className="md:p-2 w-full md:w-1/3 mt-2">
            <Label>Subject</Label>
            <Select
              value={formData.subject}
              onChange={(value) => handleInputChange('subject', value)}
              options={subjectData}
              className="w-full px-0 py-6 border border-gray-200 rounded"
              placeholder="Select Subject"
              bordered={false}
            />
            {errors.subject && <div className="text-red-500 text-sm mt-1">{errors.subject}</div>}
          </div>
          <div className="md:p-2 w-full md:w-1/3">
            <Label>Date</Label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={(e) => handleInputChange('date', e.target.value)}
              placeholder="Date"
              className="w-full p-[0.8rem] border border-gray-200 rounded"
            />
            {errors.date && <div className="text-red-500 text-sm mt-1">{errors.date}</div>}
          </div>
        </div>
        <div className="mt-2 md:mt-0 md:w-1/2">
          <div className="flex flex-row gap-4 items-center flex-start m-2">
            {['DEMO', 'UPCOMING', 'RECORDED'].map((value) => (
              <button
                key={value}
                className={`${formData.markedValue === value
                  ? "bg-rose-500 text-white "
                  : "bg-white text-rose-500"
                } font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline shadow-lg`}
                onClick={(e) => {
                  e.preventDefault();
                  handleMarkedValueChange(value);
                }}
              >
                Mark{formData.markedValue === value && "ed"} as {value}
              </button>
            ))}
          </div>
          {errors.markedValue && (
            <div className="text-red-500 text-sm mt-1">
              {errors.markedValue}
            </div>
          )}
        </div>
      </form>

      <div className="w-full">
        {scheduledCourseTopicData?.length > 0 && (
          <ScheduleTable
            getScheduleData={getScheduleData}
            courseId={id}
            scheduledCourseTopicData={scheduledCourseTopicData}
          />
        )}
      </div>
    </div>
  );
};

export default Scheduled;
