import { CoursesApi } from "(apis)/(shared-apis)/new-courses";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { message } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { Switcher } from "shared-components/ui/CustomForm";

interface NewCourseCardProps {
  data: any;
  getCourseData: () => Promise<void>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewCourseCard: React.FC<NewCourseCardProps> = ({
  data,
  getCourseData,
  setLoading,
}) => {
  const history = useNavigate();
  const { title, demo_video, _id: id, is_published, duration_months } = data;

  const handleUpdateCourse = () => {
    history(`/manage/new-courses?type=edit&id=${id}`);
  };

  const handlePublish = async () => {
    setLoading(true);
    try {
      const res = await CoursesApi("update_new_course_publish_status", [id]);
      if (res?.status === 200) {
        message.success(res?.data?.message);
        await getCourseData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="h-full rounded-lg overflow-hidden bg-white shadow-lg">
        {data?.thumbnail_image ? (
          <img src={data?.thumbnail_image} alt="" className="w-full h-60" />
        ) : demo_video && demo_video?.trim() !== "" ? (
          <img src={demo_video} alt="" className="w-full h-60" />
        ) : (
          <div className="w-full h-60 flex items-center justify-center">
            No Image
          </div>
        )}

        <div className="p-6">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
            {title}
          </h2>

          <div className="flex justify-between">
            <div className="line-clamp-2">{duration_months} Months</div>

            <div className="flex flex-row gap-2 items-center">
              <div>
                {/* <GlobalPublishButton
                  onChange={handlePublish}
                  isActive={is_published}
                /> */}

                <Switcher
                  isActive={is_published}
                  for={id}
                  onChange={() => handlePublish()}
                  togglevalue={is_published}
                  size="small"
                />
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-7 h-7 cursor-pointer text-black hover:text-rose-200"
                onClick={handleUpdateCourse}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCourseCard;
