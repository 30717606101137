import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import { BasicGetApi } from "../../../(apis)/(shared-apis)/basic-get-apis";
import { useEffect, useState } from "react";
import ViewCommissionUser from "../components/ViewCommissionUser";
import { ButtonFill } from "../../../shared-components/ui/CustomButtons";
import { SearchIcon } from "../../../shared-components/ui/Icons";
import { CommissionBanner, ROlES } from "../utils";
import PromoteHeadToManagerForm from "./components/promote-head-to-manager-form";

const PromoteHeadToManager = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [adminUsers, setAllAdminUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [nestedStructure, setNestedStructure] = useState([]);

  useEffect(() => {
    fetchData();
  }, [refreshData]);

  async function fetchData() {
    setIsLoading(true);

    try {
      const [adminUsersResponse, commissionUsersResponse, hierarchyResponse] =
        await Promise.all([
          BasicGetApi("getUserInfo"),
          CommissionAPI("getAllCommissionUsers"),
          CommissionAPI("getHierarchy", [ROlES.MANAGER]),
        ]);

      if (adminUsersResponse.status === 200) {
        const adminUsersData = adminUsersResponse?.data?.data || [];
        setAllAdminUsers(adminUsersData);
      }

      if (commissionUsersResponse.status === 200) {
        const commissionUsersData = commissionUsersResponse?.data?.data || [];

        const filteredUsers = commissionUsersData;

        console.log("commissionUsersResponse", commissionUsersResponse);
        setAllUsers(filteredUsers);
      }

      if (hierarchyResponse.status === 200) {
        const hierarchyData = hierarchyResponse?.data?.data || [];
        console.log("hierarchyData: ", hierarchyData);
        setNestedStructure(hierarchyData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <div className="w-full pb-20 px-5">
        <CommissionBanner
          title="Manage Heads"
          setRefreshData={setRefreshData}
        />

        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className=" bg-white shadow-md sm:rounded-lg">
                <TableTop
                  searchQuery={searchQuery}
                  handleSearchQueryChange={handleSearchQueryChange}
                  setAllUsers={setAllUsers}
                  adminUsers={adminUsers}
                  allUsers={allUsers}
                />

                <ViewCommissionUser
                  allUsers={allUsers}
                  setAllUsers={setAllUsers}
                  allAdminUsers={adminUsers}
                  nestedStructure={nestedStructure}
                  adminUsers={undefined}
                  reportToUsers={undefined}
                  label={ROlES.HEAD}
                  allCommissionUsers={undefined}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export const TableTop = ({
  searchQuery,
  handleSearchQueryChange,
  setAllUsers,
  adminUsers,
  allUsers,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
      <div className="w-full md:w-1/2">
        <form className="flex items-center">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <SearchIcon color="text-gray-400" />
            </div>
            <input
              type="text"
              id="simple-search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
              placeholder="Search"
              required={true}
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
          </div>
        </form>
      </div>
      <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
        <ButtonFill
          handleClick={() => {
            setIsModalOpen(true);
          }}
        >
          Promote
        </ButtonFill>
      </div>
      <PromoteHeadToManagerForm
        mode="add"
        active={isModalOpen}
        setModal={setIsModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onConfirm={() => {
          setIsModalOpen(false);
        }}
        setAllUsers={setAllUsers}
        reportToUsers={adminUsers}
        adminUsers={adminUsers}
        allCommissionUsers={allUsers}
      />
    </div>
  );
};

export default PromoteHeadToManager;
