import React from "react";
import { Course } from "../../type";
import { Select as AntSelect } from "antd";
interface Props {
  courseData: Course;
  setCourseData: React.Dispatch<React.SetStateAction<any>>;
  featureData: { value: string; label: string }[];
}

const Features: React.FC<Props> = ({
  courseData,
  setCourseData,
  featureData,
}) => {
  const handleFeatureChange = (selectedIds: string[]) => {
    setCourseData((prev) => ({ ...prev, features: selectedIds }));
  };

  return (
    <div>
      <div>
        <label
          htmlFor="features"
          className="block text-sm font-medium text-gray-700"
        >
          Select Features
        </label>
        <AntSelect
          mode="multiple"
          showSearch
          style={{ width: "100%" }}
          placeholder="Select Features"
          value={courseData?.features}
          onChange={handleFeatureChange}
          options={featureData}
          filterOption={(input, option) =>
            (option?.label ?? "")?.toLowerCase().includes(input.toLowerCase())
          }
        />
      </div>
    </div>
  );
};

export default Features;
