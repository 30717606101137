import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";

import { apiRequest } from "(apis)/api-interface/api-handler";
import Loader from "shared-components/Loader";
import AddCourseModal from "./modal";
import CourseCard from "shared-pages/courses/components/course-card";

interface Course {
  _id: string;
  courseName: string;
  [key: string]: any; // Add any other properties that your course object might have
}
const Courses: React.FC = () => {
  const [coursesData, setCoursesData] = useState<Course[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getCourseData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getCourses");
      if (res?.status === 200) {
        setCoursesData(res?.data?.courses || []);
      }
      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      setCoursesData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourseData().then(() => console.log());
  }, []);

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {loading ? <Loader loading={loading} /> : 
      <div className="flex justify-center items-center w-3/4 mx-auto">
        <div className="m-4 container">
          <div className="flex justify-between">
            <div>
              <h5 className="font-bold">Welcome to the course section.</h5>
            </div>
            <div>
              <button
                color="secondary"
                onClick={() => setOpen(true)}
                className="flex items-center bg-rose-500 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                <AddIcon />
                Add New Course
              </button>
            </div>
          </div>

          <section className="text-gray-600 body-font">
            <div className="container py-4 mx-auto">
              {coursesData?.length > 0 && (
                <div className="font-bold my-4">Available Courses</div>
              )}
              <div className="flex flex-wrap -m-4">
                {coursesData?.length > 0 ? (
                  coursesData.map((data, i) => (
                    <div className="p-4 md:w-1/2 lg:w-1/3" key={i}>
                      <CourseCard
                        data={data}
                        getCourseData={getCourseData}
                        setLoading={setLoading}
                      />
                    </div>
                  ))
                ) : (
                  <div>Please add a course, there is no course available</div>
                )}
              </div>
            </div>
          </section>

          {/* Modal for add */}
          <AddCourseModal open={open} setOpen={setOpen} />
          </div>
        </div>
      }
    </>
  );
};

export default Courses;
