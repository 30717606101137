"use client";

import { CoursesApi } from "(apis)/(shared-apis)/new-courses";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { EditIcon, EyeIcon, Loader2, SaveIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { BasicInfo } from "./components/course-steps/basic-info";
import Faqs from "./components/course-steps/faqs";
import Features from "./components/course-steps/features";
import { PricingEnrollment } from "./components/course-steps/pricing-enrollement";
import { ReviewCourse } from "./components/course-steps/review-course";
import Testimonials from "./components/course-steps/testimonials";
import { CourseStructurePreview } from "./components/course-structure-preview";
import { ModuleForm } from "./components/module-form";
import { CourseTimetable } from "./components/timetable/course-time-table";
import { dummyModules } from "./data";
import { Course, Module, Timetable } from "./type";
import UploadContent from "./components/course-steps/upload-content";

export default function NewCoursePage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const courseType = searchParams.get("type");
  const courseId = searchParams.get("id");
  const navigate = useNavigate();

  const [teacherData, setTeacherData] = useState<any[]>([]);
  const [featureData, setFeatureData] = useState<any[]>([]);
  const [testimonialsData, setTestimonialsData] = useState<any[]>([]);
  const [apiLoader, setApiLoader] = useState({
    loading: false,
    api: "",
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [showPreview, setShowPreview] = useState(false);
  const [courseData, setCourseData] = useState<Course>({
    _id: "",
    title: "Dummy Course Testing",
    subject: "Biology",
    description: "",
    level: "Beginner",
    language: "",
    duration_months: 3,
    price: 0,
    modules: dummyModules,
    thumbnail_image: "",
    instructors: [],
    learning_outcomes: "",
    tags: ["MDCAT", "Testing"],
    demo_video: "",
    is_coming_soon: false,
    is_published: false,
    features: [],
    faqs: [
      {
        question: "Asd",
        asnwer: "asd",
      },
      {
        question: "Asd 2",
        asnwer: "asd 3",
      },
    ],
    testimonials: [],
  });

  // Fetch course data if editing
  useEffect(() => {
    const fetchCourseData = async () => {
      if (courseType === "edit" && courseId) {
        try {
          setApiLoader({ loading: true, api: "fetchCourse" });
          const response = await CoursesApi("get_course_by_id", [courseId]);
          if (response?.data?.data) {
            setCourseData(response.data.data);
          }
        } catch (error) {
          console.error("Error fetching course data:", error);
        } finally {
          setApiLoader({ loading: false, api: "" });
        }
      }
    };

    fetchCourseData();
  }, [courseType, courseId]);

  const handleInputChange = (name: string, value: string | string[]) => {
    setCourseData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (name: string, file: File | string) => {
    if (typeof file === "string") {
      // If it's a string (URL), directly set it
      setCourseData((prev) => ({
        ...prev,
        [name]: file,
      }));
    } else if (file instanceof File) {
      // If it's a File object
      if (name === "thumbnail_image") {
        const thumbnailUrl = URL.createObjectURL(file);
        setCourseData((prev) => ({
          ...prev,
          [name]: thumbnailUrl,
          [`${name}_file`]: file, // Store the original file
        }));
      } else if (name === "demo_video") {
        const videoUrl = URL.createObjectURL(file);
        setCourseData((prev) => ({
          ...prev,
          [name]: videoUrl,
          [`${name}_file`]: file, // Store the original file
        }));
      }
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setCourseData((prev) => ({ ...prev, [name]: value }));
  };

  const handleInstructorChange = (selectedIds: string[]) => {
    setCourseData((prev) => ({ ...prev, instructors: selectedIds }));
  };

  const handleModulesChange = (modules: Module[]) => {
    setCourseData((prev) => ({ ...prev, modules }));
  };

  const handleNextStep = () => {
    setCurrentStep((prev) => Math.min(prev + 1, 6));
  };

  const handlePrevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const handlePublish = async () => {
    console.log("Publishing course:", courseData);
    // Here you would typically make an API call to save the course
  };

  const [timetable, setTimetable] = useState<Timetable | null>(null);

  const handleTimetableChange = (newTimetable: Timetable) => {
    setTimetable(newTimetable);
  };

  const getAllTeachers = async () => {
    setApiLoader({
      loading: true,
      api: "getTeachers",
    });
    const res = await apiRequest("getTeachers");
    if (res?.status === 200) {
      const fomrmatedTeacherData = res?.data?.teachers?.map(
        ({ name, _id }) => ({
          value: _id,
          label: name,
        })
      );
      setTeacherData(fomrmatedTeacherData);
      setApiLoader({
        loading: false,
        api: "getTeachers",
      });
    }
  };
  const getAllFeatures = async () => {
    setApiLoader({
      loading: true,
      api: "getFeatures",
    });
    const res = await apiRequest("getFeatures");
    if (res?.status === 200) {
      const fomrmatedFeatureData = res?.data?.courseFeatures?.map(
        ({ featureName, _id }) => ({
          value: _id,
          label: featureName,
        })
      );
      setFeatureData(fomrmatedFeatureData);
      setApiLoader({
        loading: false,
        api: "getFeatures",
      });
    }
  };
  const getAllTestimonials = async () => {
    setApiLoader({
      loading: true,
      api: "getTestimonials",
    });
    const res = await apiRequest("getTestimonials");
    if (res?.status === 200) {
      const fomrmatedTestimonialsData = res?.data?.Testimonials;
      setTestimonialsData(fomrmatedTestimonialsData);
      setApiLoader({
        loading: false,
        api: "getTestimonials",
      });
    }
  };

  const fetchAllData = () => {
    Promise.all([getAllTestimonials(), getAllTeachers(), getAllFeatures()])
      .then(() => {})
      .catch((error) => {
        console.error("Error hitting APIs:", error);
      });
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const [saveModulesLoading, setSaveModulesLoading] = useState(false);

  const validateRequiredFields = (courseData: Course) => {
    if (
      courseData?.title &&
      courseData?.subject &&
      courseData?.level &&
      courseData?.description &&
      courseData?.duration_months &&
      courseData?.learning_outcomes &&
      courseData?.tags &&
      courseData?.thumbnail_image
    ) {
      return true;
    }
    return false;
  };

  const handleSaveBasicInfo = async () => {
    if (!validateRequiredFields(courseData)) {
      toast.error("Please fill all the Basic Info fields.");
      return;
    }

    try {
      setApiLoader({ loading: true, api: "saveBasicInfo" });

      // Create FormData for file upload
      const formData = new FormData();

      // Handle demo video conversion if it's a blob URL
      if (
        courseData.demo_video &&
        typeof courseData.demo_video === "string" &&
        courseData.demo_video.startsWith("blob:")
      ) {
        const demoVideoBlob = await fetch(courseData.demo_video).then((r) =>
          r.blob()
        );
        const demoVideoFile = new File([demoVideoBlob], "demo_video", {
          type: "video/mp4",
        });
        formData.append("demo_video", demoVideoFile);
      }

      // Prepare basic course data without modules
      const basicCourseData = { ...courseData };
      delete basicCourseData.modules;

      // Append form data directly
      Object.keys(basicCourseData).forEach((key) => {
        const value = basicCourseData[key as keyof typeof basicCourseData];

        if (Array.isArray(value)) {
          // Handle array fields like instructors and tags
          value.forEach((item, index) => {
            formData.append(`${key}[${index}]`, String(item));
          });
        } else if (
          value !== undefined &&
          value !== null &&
          key !== "demo_video" &&
          key !== "thumbnail_image"
        ) {
          formData.append(key, String(value));
        }
      });

      // Call handleUpdateCourse with the prepared data
      const response = await handleUpdateCourse({
        title: courseData?.title,
        subject: courseData?.subject,
        description: courseData?.description,
        level: courseData?.level,
        language: courseData?.language,
        duration_months: courseData?.duration_months,
        learning_outcomes: courseData?.learning_outcomes,
        tags: courseData?.tags,
        demo_video: formData.get("demo_video") as File | undefined,
        thumbnail_image: courseData?.thumbnail_image,
      });

      return response;
    } catch (error) {
      console.error("Error saving basic course info:", error);
      throw error;
    } finally {
      setApiLoader({ loading: false, api: "" });
    }
  };

  // Validate and prepare modules
  const prepareModules = (modules: any[]): Module[] => {
    return modules.map((module) => {
      // Ensure description is a string, even if it's HTML
      const description = module.description
        ? typeof module.description === "string"
          ? module.description
          : JSON.stringify(module.description)
        : "";

      // Create a clean module object
      return {
        ...module,
        description,
        // Ensure sub_modules is an array
        sub_modules: Array.isArray(module.sub_modules)
          ? module.sub_modules.map((subModule) => ({
              ...subModule,
              // Ensure contents is an array
              contents: Array.isArray(subModule.contents)
                ? subModule.contents
                : [],
            }))
          : [],
      };
    });
  };

  const isEditMode = courseType === "edit" && courseId;

  const handleUpdateCourse = async (updateFields?: Partial<Course>) => {
    try {
      setApiLoader({ loading: true, api: "updateCourse" });

      // Determine if we're creating or editing a course
      const isEditMode = courseType === "edit" && courseId;

      const dataToUpdate = updateFields
        ? { ...courseData, ...updateFields }
        : courseData;

      // Add type guard function
      const isFile = (obj: any): obj is File => {
        return obj instanceof File;
      };

      // Create FormData for file uploads
      const formData = new FormData();

      if (isFile(dataToUpdate?.demo_video)) {
        formData.append(
          "demo_video",
          dataToUpdate?.demo_video,
          "demo_video.mp4"
        );
      }

      // Prepare and validate modules
      const preparedModules = dataToUpdate?.modules
        ? prepareModules(dataToUpdate?.modules)
        : [];

      // Append individual fields
      formData.append("title", dataToUpdate?.title);
      formData.append("subject", dataToUpdate?.subject);
      formData.append("description", dataToUpdate?.description);
      formData.append("level", dataToUpdate?.level);
      formData.append("language", dataToUpdate?.language);
      formData.append("duration_months", String(dataToUpdate?.duration_months));
      formData.append("price", String(dataToUpdate?.price));
      formData.append("learning_outcomes", dataToUpdate?.learning_outcomes);
      formData.append("thumbnail_image", dataToUpdate?.thumbnail_image);

      // Append modules
      preparedModules?.forEach((module, index) => {
        formData.append(`modules[${index}]`, JSON.stringify(module));
      });

      // Append array fields
      const arrayFields = [
        "tags",
        "instructors",
        "testimonials",
        "features",
        "faqs",
      ];
      arrayFields.forEach((field) => {
        const arrayValue = dataToUpdate[field as keyof Course];
        console.log("arrayValue: ", arrayValue, " field: ", field);
        if (Array.isArray(arrayValue) && arrayValue.length > 0) {
          arrayValue.forEach((item, index) => {
            formData.append(
              `${field}[${index}]`,
              typeof item === "object" ? JSON.stringify(item) : String(item)
            );
          });
        }
      });

      // Append optional fields
      if (dataToUpdate?.demo_video)
        formData.append("demo_video", dataToUpdate?.demo_video);
      if (dataToUpdate?.is_coming_soon !== undefined)
        formData.append("is_coming_soon", String(dataToUpdate?.is_coming_soon));
      if (dataToUpdate?.is_published !== undefined)
        formData.append("is_published", String(dataToUpdate?.is_published));

      // Send request based on mode
      const response = isEditMode
        ? await CoursesApi("edit_course", [dataToUpdate?._id], formData)
        : await CoursesApi("add_course", [], formData);

      if (!isEditMode) {
        console.log("Response not edit mode: ", response);
        const id = response?.data?.data?._id;
        navigate(`/manage/new-courses?type=edit&id=${id}`);
        // navigate('/new-courses?type=edit&id=');
      }

      // Update local state with response
      setCourseData(response?.data?.data);

      return response;
    } catch (error) {
      console.error("Error updating/creating course:", error);
      throw error;
    } finally {
      setApiLoader({ loading: false, api: "" });
    }
  };

  const handleSaveModules = async () => {
    try {
      setSaveModulesLoading(true);

      // Create FormData for file uploads
      const formData = new FormData();

      const modules = courseData.modules;

      const preparedModules = modules ? prepareModules(modules) : [];

      preparedModules.forEach((module, index) => {
        formData.append(`modules[${index}]`, JSON.stringify(module));
      });

      // Send request based on mode
      const response = await CoursesApi(
        "edit_course",
        [courseData._id],
        formData
      );

      // console.log("responses: ", response)

      // Optionally show a success toast or notification
      toast.success("Modules saved successfully!");
    } catch (error) {
      console.error("Error saving modules:", error);
      toast.error("Failed to save modules. Please try again.");
    } finally {
      setSaveModulesLoading(false);
    }
  };

  const handleSaveTimetable = () => {
    console.log("Saving Timetable:", timetable);
  };

  const handleSaveInstructors = () => {
    console.log("Saving Instructors:", courseData.instructors);
  };

  const handleSavePricing = () => {
    console.log("Saving Pricing:", {
      price: courseData.price,
      duration_months: courseData.duration_months,
    });
  };

  console.log({
    courseId,
    courseData,
  });

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <BasicInfo
            courseData={courseData}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            handleFileChange={handleFileChange}
            teacherData={teacherData}
            handleInstructorChange={handleInstructorChange}
            handleSave={handleSaveBasicInfo}
            apiLoader={apiLoader}
          />
        );
      case 2:
        return (
          <div>
            <div className="flex justify-end mb-4 space-x-2">
              <button
                onClick={handleSaveModules}
                disabled={saveModulesLoading}
                className={`flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-md transition-colors 
                  ${
                    saveModulesLoading
                      ? "text-gray-500 bg-gray-100 cursor-not-allowed"
                      : "text-green-600 bg-green-50 hover:bg-green-100"
                  }`}
              >
                {saveModulesLoading ? (
                  <>
                    <span>Saving...</span>
                    <Loader2 className="animate-spin h-4 w-4" />
                  </>
                ) : (
                  <>
                    <span>Save Modules</span>
                    <SaveIcon className="w-4 h-4" />
                  </>
                )}
              </button>

              <button
                onClick={() => setShowPreview(!showPreview)}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 bg-indigo-50 rounded-md hover:bg-indigo-100"
              >
                {showPreview ? (
                  <>
                    <span>Edit Modules</span>
                    <EditIcon />
                  </>
                ) : (
                  <>
                    <span>Show Preview</span>
                    <EyeIcon />
                  </>
                )}
              </button>
            </div>
            <div className="w-full">
              {!showPreview ? (
                <ModuleForm
                  modules={courseData.modules}
                  onModuleChange={handleModulesChange}
                  courseId={courseData._id}
                  handleSave={handleSaveModules}
                />
              ) : (
                <CourseStructurePreview
                  modules={courseData.modules}
                  onModulesReorder={handleModulesChange}
                />
              )}
            </div>
          </div>
        );
      case 3:
        return (
          <CourseTimetable
            modules={courseData.modules}
            initialTimetable={timetable || undefined}
            onTimetableChange={handleTimetableChange}
            // handleSave={handleSaveTimetable}
          />
        );

      case 4:
        return (
          <PricingEnrollment
            courseData={courseData}
            handleInputChange={handleInputChange}
            // handleSave={handleSavePricing}
          />
        );
      case 5:
        return <ReviewCourse courseData={courseData} />;
      case 6:
        return <Faqs courseData={courseData} setCourseData={setCourseData} />;
      case 7:
        return (
          <Layout>
            <Testimonials
              courseData={courseData}
              setCourseData={setCourseData}
              testimonialsData={testimonialsData}
            />
          </Layout>
        );
      case 8:
        return (
          <Features
            courseData={courseData}
            setCourseData={setCourseData}
            featureData={featureData}
          />
        );
      case 9:
        return (
          <>
            <button
              onClick={handleSaveModules}
              disabled={saveModulesLoading}
              className={`flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-md transition-colors 
                  ${
                    saveModulesLoading
                      ? "text-gray-500 bg-gray-100 cursor-not-allowed"
                      : "text-green-600 bg-green-50 hover:bg-green-100"
                  }`}
            >
              {saveModulesLoading ? (
                <>
                  <span>Saving...</span>
                  <Loader2 className="animate-spin h-4 w-4" />
                </>
              ) : (
                <>
                  <span>Save Modules</span>
                  <SaveIcon className="w-4 h-4" />
                </>
              )}
            </button>

            <UploadContent
              courseData={courseData}
              setCourseData={setCourseData}
            />
          </>
        );
      default:
        return null;
    }
  };

  const Layout = ({ children }) => {
    return (
      <>
        <button
          onClick={handleSaveBasicInfo}
          disabled={apiLoader?.loading}
          className={`flex items-center ml-auto m-5 gap-2 px-4 py-2 text-sm font-medium rounded-md transition-colors 
             ${
               apiLoader?.loading
                 ? "text-gray-500 bg-gray-100 cursor-not-allowed"
                 : "text-green-600 bg-green-50 hover:bg-green-100"
             }`}
        >
          {apiLoader?.loading ? (
            <>
              <span>Saving...</span>
              <Loader2 className="animate-spin h-4 w-4" />
            </>
          ) : (
            <>
              <span>Save</span>
              <SaveIcon className="w-4 h-4" />
            </>
          )}
        </button>
        {children}
      </>
    );
  };

  return (
    <div className=" mx-auto p-10">
      <Toaster />
      <div className="bg-white shadow-md rounded-lg p-6 w-full  mx-auto">
        <h2 className="text-2xl font-bold mb-4">
          {isEditMode ? "Edit" : "Add New"} Course
        </h2>
        <p className="text-gray-600 mb-6">
          {isEditMode ? "Edit" : "Add a new"} course to the PreMed platform
        </p>

        <div className="mb-6">
          <div className="flex border-b border-gray-200">
            {[
              "Basics",
              "Modules",
              "Schedule",
              // "Instructors",
              "Pricing",
              "Review",
              "FAQs",
              `Testimonials`,
              "Features",
              "Content",
            ].map((step, index) => {
              const isLocked = !isEditMode && index !== 0;

              return (
                <button
                  key={step}
                  className={`py-2 px-4 flex items-center ${
                    currentStep === index + 1
                      ? "border-b-2 border-primary text-primary font-semibold"
                      : "text-gray-500"
                  } ${
                    isLocked
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  }`}
                  onClick={() => !isLocked && setCurrentStep(index + 1)}
                  disabled={isLocked}
                >
                  {step}
                  {isLocked && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-4 h-4 ml-2 text-gray-400"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </button>
              );
            })}
          </div>
        </div>

        <div className="mb-6">{renderStep()}</div>

        {/* <div className="flex justify-between">
          <ButtonOutlined
            handleClick={handlePrevStep}
            disabled={currentStep === 1}
          >
            Previous
          </ButtonOutlined>
          <ButtonFill
            handleClick={currentStep < 6 ? handleNextStep : handleUpdateCourse}
          >
            {currentStep < 6 ? "Next" : "Publish Course"}
          </ButtonFill>
        </div> */}
      </div>
    </div>
  );
}
