import { apiRequest } from '(apis)/api-interface/api-handler';
import Button from '@material-ui/core/Button';
import { message, Select } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
const EditSchedule = ({ scheduledData, id, getScheduleData}) => {
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isloadingWhileSubmitting, setIsloadingWhileSubmitting] = useState(false);

  const initialValues = {
    topic: '',
    date: '',
    markedValue: '',
    subject: '',
  };
  const validationSchema = Yup.object().shape({
    topic: Yup.string().required('Topic is required'),
    date: Yup.string().required('date  is required'),
    subject: Yup.string().required('subject  is required'),
    markedValue: Yup.string().required('markedValue  is required')
  });

  const { values, handleSubmit,  setFieldValue, setValues, touched, errors, handleChange } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsloadingWhileSubmitting(true)
        const { topic, date, markedValue,subject } = values
        const payLoad = {
          topic, date, markedValue, subject,
        }
        const res = await apiRequest("updateSchedule",payLoad,[id])
        if (res?.status === 200) {
          setIsloadingWhileSubmitting(false)
          setIsEditEnabled(false)
          message.success(res?.data?.message)
          getScheduleData().then(() => console.log())
          resetForm()
        }

      } catch (error) {
        setIsloadingWhileSubmitting(false)
        message.error(error?.response?.data?.message || error?.message)
      }
    },
  });
 
  useEffect(() => {
    if (scheduledData && scheduledData?.date) {
      try {
        const formattedDate = new Date(scheduledData.date).toISOString().split('T')[0];  
        setValues({
          topic: scheduledData?.topic || '',
          date: formattedDate,
          markedValue: scheduledData?.markAs || '',
          subject: scheduledData?.subject || '',
        });
      } catch (error) {
        console.error('Error parsing date:', error);
      }
    }
    
  }, [scheduledData])


  const handleFieldChange = (fieldName, value) => {
    setFieldValue(fieldName, value);

    const dbValue = scheduledData?.[fieldName === 'subject' ? 'subject' : fieldName] || '';
    const isFieldModified = fieldName === 'date' 
      ? new Date(value).toISOString() !== dbValue
      : value !== dbValue;

    setIsEditEnabled(isFieldModified);
  };

  const [subjectData, setSubjectData] = useState([]);
  const getAllSubjects = async () => {
    try {
      const res = await apiRequest("getallsubjects");
      if (res?.status === 200) {
        const formatedSubjectData = res?.data?.map((subject: any) => ({
          value: subject.name,
          label: subject.name,
        }));
        setSubjectData(formatedSubjectData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllSubjects();
  }, []);

  return (
    <div className=''>

      <form onSubmit={handleSubmit} className='rounded-lg shadow p-4 pt-0 bg-white '>
        <div className='font-bold'>
          Manage Schedule
        </div>
        <div className='flex flex-row flex-wrap items-center justify-between mx-4'>
        </div>
        <div className='flex flex-col md:flex-row items-center'>

          <div className="md:p-2 w-full md:w-1/3">
            <div className="relative">
              <label htmlFor="name" className=" text-md font-bold text-gray-900 mb-4">
                Topic
              </label>
              <input
                type="text"
                id="topic"
                name="topic"
                disabled
                value={values?.topic}
                onChange={handleChange}
                className="w-full bg-transparent bg-opacity-50  rounded border border-gray-300 focus:border-red-500 focus:bg-white focus:ring-2 focus:ring-red-200 text-base  font-normal outline-none my-1 py-0 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              {touched?.topic && errors?.topic && <div className="text-red-500 text-sm mt-1">{errors?.topic}</div>}
            </div>
          </div>
          <div className="md:p-2 w-full md:w-1/3">
            <div className="relative">
              <label htmlFor="name" className=" text-md font-bold text-gray-900 mb-4">
            Subject
              </label>
              <Select
                value={values?.subject}
                onChange={(value) => handleFieldChange('subject', value)}
                placeholder="Select Subject"
                options={subjectData}
                className="w-full px-0 py-4 border border-gray-200 rounded"
                bordered={false}
              />
              {touched?.subject && errors?.subject && <div className="text-red-500 text-sm mt-1">{errors?.subject}</div>}
            </div>
          </div>
          <div className="md:p-2 w-full md:w-1/4">
            <div className="relative">
              <label htmlFor="name" className=" text-md font-bold text-gray-900 mt-2.5">
                Date
              </label>
              <input
                type="date"
                id="date"
                name="date"
                value={values?.date}
                onChange={(e) => handleFieldChange('date', e.target.value)}
                className="w-full bg-transparent bg-opacity-50 rounded border border-gray-300 focus:border-red-500 focus:bg-white focus:ring-2 focus:ring-red-200 text-base outline-none my-1  py-0 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              {touched?.date && errors?.date && <div className="text-red-500 text-sm mt-1">{errors.date}</div>}
            </div>
          </div>
        </div>
        <div className=' mt-2 md:mt-0 flex justify-between'>
          <div className='flex flex-row gap-4 items-center flex-start m-2 '>
          <button
                className={`${values?.markedValue === 'DEMO' ? 'bg-rose-500 text-white ' : 'bg-white text-rose-500'} font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline shadow-lg`}
                onClick={(e) => {
                  e.preventDefault();
                  setFieldValue('markedValue', 'DEMO')
                  const dbValue = scheduledData?.markAs || ''
                  const isFieldModified =  'DEMO' !==dbValue
                  setIsEditEnabled(isFieldModified);
                }              
              }
              >
                Mark{values?.markedValue === 'DEMO' && 'ed'} as Demo
              </button>
              <button
                className={`${values?.markedValue === 'UPCOMING' ? 'bg-rose-500 text-white ' : 'bg-white text-rose-500'} font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline shadow-lg`}
                onClick={(e) => {
                  e.preventDefault();
                  setFieldValue('markedValue', 'UPCOMING')
                  const dbValue = scheduledData?.markAs || ''
                  const isFieldModified = 'UPCOMING' !==dbValue
                  setIsEditEnabled(isFieldModified);
                }               
              
              }
              >
                Mark{values?.markedValue === 'UPCOMING' && 'ed'} as Upcoming
              </button>
              <button
                className={`${values?.markedValue === 'RECORDED' ? 'bg-rose-500 text-white ' : 'bg-white text-rose-500'} font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline shadow-lg`}
                onClick={(e) => {
                  e.preventDefault();
                  setFieldValue('markedValue', 'RECORDED')
                  const dbValue = scheduledData?.markAs || ''
                  const isFieldModified = 'RECORDED' !==dbValue
                  setIsEditEnabled(isFieldModified);

                }
                  
                }
              >
                Mark{values?.markedValue === 'RECORDED' && 'ed'} as Recorded
              </button>

            {touched?.markedValue && errors?.markedValue && <div className="text-red-500 text-sm mt-1">{errors?.markedValue}</div>}
          </div>
          <div>
            <Button variant="contained" size='small' type='submit' color="secondary" disabled={!isEditEnabled || isloadingWhileSubmitting}>
             {isloadingWhileSubmitting?'Updating...':'Edit Confirm'} 
            </Button>
          </div>

        </div>
      </form>


    </div>
  )
}

export default EditSchedule