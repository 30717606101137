import { ApiInterface } from "../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../constants/config";

export const CoursesAPIs: ApiInterface[] = [
  {
    url: "/api/new-courses/get",
    method: "GET",
    name: "get_course_by_id",
  },
  {
    url: "/api/new-courses/get",
    method: "GET",
    name: "get_all_new_courses",
  },
  {
    url: "/api/new-courses/update-status",
    method: "PUT",
    name: "update_new_course_publish_status",
  },
  {
    url: "/api/new-courses/add",
    method: "POST",
    name: "add_course",
  },
  {
    url: "/api/new-courses/update",
    method: "PUT",
    name: "edit_course",
  },
];

// Frontend Changes
export function CoursesApi(
  name: string,
  params: string[] = [""],
  data: any = null,
  queryParams: Record<string, any> = {}
) {
  const api = CoursesAPIs.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url =
    backendUrl + api.url + (params.length ? "/" + params.join("/") : "");

  // Convert queryParams object to a query string
  const queryString = new URLSearchParams(queryParams).toString();

  return axios({
    method: api.method,
    data,
    withCredentials: true,
    url: queryString ? `${url}?${queryString}` : url,
  });
}
