import React, { useEffect, useState } from "react";
import { Input } from "../ui/input";
import { Course } from "../../type";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import Loading from "shared-components/ui/Loading";
import { Switcher } from "shared-components/ui/CustomForm";
import { toast, Toaster } from "react-hot-toast";
import { CoursesPricingAPI } from "(apis)/(shared-apis)/courses-pricing";
import AddPricingModal from "../pricing/pricing-modal";
interface PricingEnrollmentProps {
  courseData: Course;
  handleInputChange: (name: string, value: string) => void;
}

export const PricingEnrollment: React.FC<PricingEnrollmentProps> = ({
  courseData,
  handleInputChange,
}) => {

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('add');
  const [selectedSKU, setSelectedSKU] = useState(null);
  const [pricingData, setPricingData] = useState([]);
  const [loading, setLoading] = useState(false);


  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  }

  const handleEdit = (item: any) => {
    setOpen(true);
    setMode('edit');
    setSelectedSKU(item);
  }

  const handleStatus = async (item: any, value: boolean) => {
    const res = await CoursesPricingAPI("handleCoursePricingStatus", [item._id]);
    if (res?.data.statusCode === 200) {
        toast.success("Pricing Plan Status Updated Successfully");
        getPricingData();
    }
    else {
        toast.error(res?.data?.message || "Failed to update pricing plan status");
    }
}


  const getPricingData = async () => {
    setLoading(true);
    const res = await CoursesPricingAPI("getNewCoursePricing", [courseData?._id]);
    if (res?.data.statusCode === 200) {
      setPricingData(res.data.data);
    }
    setLoading(false);
  }

  useEffect(() => {
    getPricingData();
  }, []);


  return (
    <div className='w-[95%] mx-auto h-full mt-10'>
      <Toaster/>
      <div className='flex justify-between items-center'>
        <h1 className='text-3xl text-gray-700 font-bold'>Pricing Management</h1>
        <ButtonFill
          handleClick={() => {
            setOpen(true);
            setMode('add');
          }}
        >
          Add Pricing
        </ButtonFill>
      </div>

      {loading ? (
        <div className='flex justify-center items-center h-full'>
          <Loading />
        </div>
      ) : (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 items-start'>
          {pricingData && pricingData.map((item: any) => (
            <div key={item._id} className='bg-white border border-gray-100 p-4 rounded-md space-y-2'>
              <h1 className='text-xl text-gray-700 font-bold'>{item?.SKUName}</h1>
              <p className='text-gray-500'>{item?.Tag}</p>
              <p className='text-gray-500'>{item?.PreMedAccess && item?.PreMedAccess?.length > 0 ?
                <div className='flex flex-wrap gap-2'>
                  {item?.PreMedAccess?.map((access: any) => (
                    <span key={access} className='bg-gray-100 text-gray-500 px-2 text-xs py-1 rounded-md'>{access}</span>
                  ))}
                </div> : "No Pre-Med Access"}</p>
              <p className='text-gray-500'>Price: {item?.Price}</p>
              <p className='text-gray-500'>Discount: {item?.Discount}</p>
              <div className='flex flex-wrap gap-2'>
                <span className='text-gray-500'>Subscription Ends: </span>
                {!item?.endDate ? (
                  <p className='text-gray-500'>{item?.numberOfMonths} Months</p>
                ) : (
                  <p className='text-gray-500'>{formatDate(item?.endDate)}</p>
                )}
              </div>
              <div className='flex gap-2'>
                <ButtonOutlined
                  handleClick={() => handleEdit(item)}
                >
                  Edit
                </ButtonOutlined>
                <Switcher
                  for={`isPublished-${item._id}`}
                  isActive={item?.isPublished}
                  togglevalue={item?.isPublished}
                  onChange={(value) => handleStatus(item, value)}
                  disabled={loading}
                />
              </div>
            </div>
          ))}


        </div>
      )}

      <AddPricingModal
        active={open}
        setModal={setOpen}
        courseId={courseData?._id}
        mode={mode as 'add' | 'edit'}
        selectedSKU={selectedSKU}
        setSelectedSKU={setSelectedSKU}
        fetchPricingData={getPricingData}
        setMode={setMode}
        type="new-courses"
      />
    </div>
  );
};
