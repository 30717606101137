import React, { useContext, useEffect } from "react";
import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import GlobalForm from "shared-components/form-fields/global-form";
import { UserContext } from "state-management/user-context";
import { apiRequest } from "(apis)/api-interface/api-handler";

interface UpcommingClassProps {
  getCourseData: () => void;
  courseId: string;
  courseData: {
    upcommingClass: {
      className: string;
      link: string;
      startTime: string;
      endTime: string;
      date: string;
      description: string;
      publish: boolean;
    };
  };
}

interface FormValues {
  className: string;
  link: string;
  startTime: string;
  endTime: string;
  date: string;
  description: string;
  publish: boolean;
}

const UpcommingClass: React.FC<UpcommingClassProps> = ({ getCourseData, courseId, courseData }) => {
  const {user} = useContext(UserContext);
  const navigate = useNavigate();

  const formik = useFormik<FormValues>({
    initialValues: {
      className: "",
      link: "",
      startTime: "",
      endTime: "",
      date: "",
      description: "",
      publish: true,
    },
    validationSchema: Yup.object({
      className: Yup.string().required("Class Name is required"),
      startTime: Yup.string().required("Start Time is required"),
      endTime: Yup.string().required("End Time is required"),
    }),
    onSubmit: async (values, { resetForm }: FormikHelpers<FormValues>) => {
      try {
        if (!user?._id) {
          message.error("Please Login first");
          navigate("/login");
          return;
        }

     const  convertTo12HourFormat= (time: string) =>{
          return dayjs(time, 'HH:mm').format('h:mm A');
        }

        values.startTime = convertTo12HourFormat(values.startTime);
        values.endTime = convertTo12HourFormat(values.endTime);

        const formData = new FormData();
        formData.append("upcommingClass", JSON.stringify(values));

        // Update course API call
        const res = await apiRequest("updateCourse",formData, [courseId]);
        console.log("res", res.data);
        if (res?.status ===200) {
          message.success(res?.data?.message);
          getCourseData();
          resetForm();
        }
      } catch (error: any) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });

  const { isSubmitting, setValues } = formik;

  useEffect(() => {
    function convertTo24HourFormat(time: string) {
      return dayjs(time, 'h:mm A').format('HH:mm');
    }

    setValues({
      className: courseData?.upcommingClass?.className || "",
      link: courseData?.upcommingClass?.link || "",
      startTime: courseData?.upcommingClass?.startTime 
        ? convertTo24HourFormat(courseData?.upcommingClass?.startTime) 
        : "",
      endTime: courseData?.upcommingClass?.endTime 
        ? convertTo24HourFormat(courseData?.upcommingClass?.endTime) 
        : "",
      date: courseData?.upcommingClass?.date 
        ? dayjs(courseData?.upcommingClass?.date).format("YYYY-MM-DD") 
        : "",
      description: courseData?.upcommingClass?.description || "",
      publish: courseData?.upcommingClass?.publish || true,
    });
  }, [courseData, setValues]);

  const formData = {
    fields: [
      {
        name: "className",
        label: "Class Name",
        type: "text",
        size: 4,
        formContent: "input",
        require: true,
      },
      {
        name: "link",
        label: "Link",
        type: "text",
        size: 4,
        formContent: "input",
      },
      {
        name: "publish",
        label: "Publish",
        size: 4,
        formContent: "publishbutton",
      },
      {
        name: "startTime",
        label: "Start Time",
        type: "time",
        size: 4,
        require: true,
        formContent: "input",
      },
      {
        name: "endTime",
        label: "End Time",
        type: "time",
        require: true,
        size: 4,
        formContent: "input",
      },
      {
        name: "date",
        label: "Date",
        type: "date",
        size: 4,
        formContent: "input",
      },
      {
        name: "description",
        label: "Description",
        type: "text",
        size: 12,
        formContent: "react-quill",
      },
      {
        name: "submitbutton",
        label: "Save",
        type: "submit",
        loader: isSubmitting,
        formContent: "submitbutton",
        require: false,
      },
    ],
  };
  return (
    <div>
      <GlobalForm formik={formik} formData={formData} />
    </div>
  );
};

export default UpcommingClass;
