import React, { useState, useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { Select } from 'antd';
import ReactQuill from 'react-quill';
import { ButtonOutlined, ButtonFill } from '../../../../shared-components/ui/CustomButtons';
import CustomDropDown from '../../../../shared-components/ui/CustomDropDown';
import { Input, Label, Switcher } from '../../../../shared-components/ui/CustomForm';
import { CoursesPricingAPI } from '../../../../(apis)/(shared-apis)/courses-pricing';
import Loading from '../../../../shared-components/ui/Loading';
import { axiosErrorHandler } from '../../../../utility/form/functions';
import { apiRequest } from '../../../../(apis)/api-interface/api-handler';

interface AddPricingModalProps {
    active?: boolean;
    mode: 'add' | 'edit';
    setModal: (value: boolean) => void;
    setMode: (value: string) => void;
    courseId?: string;
    selectedSKU?: any;
    fetchPricingData?: () => void;
    fetchPackage?: () => void;
    setSelectedSKU?: (value: any) => void;
    type: 'old-courses' | 'new-courses';
}

interface FormData {
    SKUName: string;
    Tag: string;
    PreMedAccess: string[];
    Description: string;
    tagDetails: any[];
    position: number;
    totalAmount: number;
    discount: number;
    endDate?: Date;
    numberOfMonths?: number;
}

function AddPricingModal(props: AddPricingModalProps) {

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState<FormData>({
        SKUName: "",
        Tag: "",
        PreMedAccess: [],
        Description: "",
        tagDetails: [],
        position: 1000,
        totalAmount: 0,
        discount: 0,
        endDate: new Date(),
        numberOfMonths: 0,
    });

    const Submit = async () => {

        if (!formData.SKUName || formData.SKUName === "") {
            toast.error("SKU Name is required");
            return;
        }

        if (formData.tagDetails.length === 0) {
            toast.error("At least 1 tag details is required");
            return;
        }

        //one of either endDate or numberOfMonths is required
        if (!formData.endDate && !formData.numberOfMonths) {
            toast.error("Either endDate or numberOfMonths is required");
            return;
        }

        //endDate should be a valid date
        if (formData.endDate && new Date(formData.endDate) < new Date()) {
            toast.error("End date should be a valid date");
            return;
        }

        //numberOfMonths should be a positive number
        if (formData.numberOfMonths && formData.numberOfMonths < 0) {
            toast.error("Number of months should be a positive number");
            return;
        }

        //totalAmount should be a positive number
        if (formData.totalAmount && formData.totalAmount < 0) {
            toast.error("Total amount should be a positive number");
            return;
        }

        //discount should be a number between 0 and 1
        if (formData.discount && (formData.discount < 0 || formData.discount > 1)) {
            toast.error("Discount should be a number between 0 and 1");
            return;
        }

        if (!formData.Description || formData.Description === "") {
            toast.error("Description is required");
            return;
        }


        let invalidDiscount = false;
        let invalidPrice = false;

        if (formData.totalAmount < 0) {
            invalidPrice = true;
        }
        //discount should be a number between 0 and 1, including 0 and 1
        if (formData.discount < 0 || formData.discount > 1) {
            invalidDiscount = true;
        }

        if (invalidPrice) {
            toast.error("Prices should be positive");
            return;
        }
        if (invalidDiscount) {
            toast.error("Discount should be between 0 and 1");
            return;
        }

        setLoading(true);
        if (props?.mode === 'edit') {
            try {
                //if showDate is true, then delete the numberOfMonths from formData
                if (showDate) {
                    delete formData.numberOfMonths;
                }
                else {
                    delete formData.endDate;
                }

                //add type to formData
                const formDataWithType = {
                    ...formData,
                    type: props?.type
                }
                const response = await CoursesPricingAPI("updateCoursePricing", [props?.selectedSKU?._id], formDataWithType);
                if (response.data.statusCode === 200) {
                    toast.success("Pricing Plan Updated Successfully");
                    props?.fetchPricingData?.();
                    props?.setMode('add');
                    props?.setSelectedSKU?.(null);
                    props?.setModal(false);
                    setLoading(false);
                    return;
                }
                setLoading(false);
                return;
            }
            catch (error) {
                axiosErrorHandler(error, "Failed to update pricing plan");
            }
            setLoading(false);
            return;
        }
        else {
            try {
                if (!props?.courseId) {
                    toast.error("Course ID is required");
                    return;
                }
                const formDataWithType = {
                    ...formData,
                    type: props?.type
                }
                const response = await CoursesPricingAPI("addCoursePricing", [props?.courseId], formDataWithType);
                if (response.data.success) {
                    toast.success("Pricing Plan Added Successfully");
                    props?.setModal(false);
                    props?.fetchPackage?.();
                    setFormData({
                        SKUName: "",
                        Tag: "",
                        PreMedAccess: [],
                        Description: "",
                        tagDetails: [],
                        position: 1000,
                        totalAmount: 0,
                        discount: 0,
                        endDate: new Date(),
                        numberOfMonths: 0,
                    });
                    props?.fetchPricingData?.();
                    props?.setMode('add');
                    props?.setSelectedSKU?.(null);
                    props?.setModal(false);
                    setLoading(false);
                    return;
                }
                setLoading(false);
                return;
            }
            catch (error) {
                axiosErrorHandler(error, "Failed to add pricing plan");
            }
            setLoading(false);
            return;
        }

    }
    useEffect(() => {
        if (props?.selectedSKU && props?.mode === 'edit') {
            setFormData((prevData: any) => ({
                ...prevData,
                SKUName: props.selectedSKU.SKUName,
                Tag: props.selectedSKU.Tag,
                PreMedAccess: props.selectedSKU.PreMedAccess,
                Description: props.selectedSKU.Description,
                tagDetails: props.selectedSKU.tagDetails,
                position: props.selectedSKU.position,
                totalAmount: props.selectedSKU.Price,
                discount: props.selectedSKU.Discount,
                endDate: props.selectedSKU.endDate ? new Date(props.selectedSKU.endDate) : null,
                numberOfMonths: props.selectedSKU.numberOfMonths,
            }));

            setShowDate(!!props.selectedSKU.endDate);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                SKUName: "",
                Tag: "",
                PreMedAccess: [],
                Description: "",
                tagDetails: [],
                position: 1000,
                totalAmount: 0,
                discount: 0,
                endDate: new Date(),
                numberOfMonths: 0,
            }));
            setShowDate(false);
        }
    }, [props.selectedSKU, props.mode]);


    const handleInputChange = (name: string, value: any) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [accessTags, setAccessTags] = useState([])
    const GetDiscountTags = async () => {
        try {
            const response = await apiRequest("getDistintTags");
            if (response?.data?.tags) {
                setAccessTags(response.data.tags);
            }
        } catch (error) {
            console.log("Error in fetching discount tags: ", error);
        }
    }

    useEffect(() => {
        GetDiscountTags();
    }, [])

    const [tagToBeAdded, setTagToBeAdded] = useState({
        index: 0,
        description: "",
        logo: "",
        isActive: true,
        position: 1000,
    });

    const AddTagDetails = () => {
        if (!tagToBeAdded.description || tagToBeAdded.description === "") {
            toast.error("Description is required");
            return;
        }
        if (!tagToBeAdded.logo || tagToBeAdded.logo === "") {
            toast.error("Logo is required");
            return;
        }
        setFormData({
            ...formData,
            tagDetails: [
                ...formData.tagDetails,
                tagToBeAdded
            ]
        });
        setTagToBeAdded({
            index: 1,
            description: "",
            logo: "",
            isActive: true,
            position: 1000,
        });
    }

    const [mode, setMode] = useState('add')
    const EditTagDetails = () => {
        if (!tagToBeAdded.description || tagToBeAdded.description === "") {
            toast.error("Description is required");
            return;
        }
        if (!tagToBeAdded.logo || tagToBeAdded.logo === "") {
            toast.error("Logo is required");
            return;
        }
        const updatedTagDetails = formData.tagDetails.map((tag, index) => {
            if (index === tagToBeAdded.index) {
                return tagToBeAdded
            }
            return tag
        });
        setFormData({
            ...formData,
            tagDetails: updatedTagDetails
        });
        setTagToBeAdded({
            index: 1,
            description: "",
            logo: "",
            isActive: true,
            position: 1000,
        });
        setMode("add")
    }

    const [showDate, setShowDate] = useState(false)


    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${props?.active ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
        >
            <Toaster />
            <div className="relative w-full max-w-7xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            {props?.mode === 'add' ? 'Add' : 'Edit'}{" "} Pricing Plan
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                props?.setModal(false);
                                props?.setMode('add');
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 items-start'>
                        <div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <Label>Name of the Pricing</Label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Name of the Pricing"
                                            value={formData.SKUName}
                                            name="SKUName"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <CustomDropDown
                                            width='w-full'
                                            label='Ribbon Tag'
                                            options={RibbonTags}
                                            onSelect={(value) => {
                                                setFormData({
                                                    ...formData,
                                                    Tag: value
                                                })
                                            }}
                                            value={formData.Tag}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-2 mt-4">
                                <div className="space-y-2">
                                    <Label>Description</Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter Description"
                                        value={formData.Description}
                                        name="Description"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className="space-y-2 mt-4">
                                <div className="space-y-2">
                                    <Label>Access</Label>
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder="Select Pre-Med Access"
                                        value={formData.PreMedAccess}
                                        onChange={(value) => {
                                            setFormData({
                                                ...formData,
                                                PreMedAccess: value
                                            })
                                        }}
                                        className='p-2 border border-gray-200 rounded'
                                        bordered={false}
                                    >
                                        {accessTags.map((tag) => (
                                            <Select.Option key={tag} value={tag}>
                                                {tag}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>

                            <div className="space-y-2 mt-4">
                                <div className="space-y-2">
                                    <Label>Total Amount</Label>
                                    <Input
                                        type="number"
                                        placeholder="Enter Total Amount"
                                        value={formData.totalAmount}
                                        name="totalAmount"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>


                            <div className="space-y-2 mt-4">
                                <div className="space-y-2">
                                    <Label>Discount</Label>
                                    <Input
                                        type="number"
                                        placeholder="Enter Discount"
                                        value={formData.discount}
                                        name="discount"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className="space-y-2">
                                <Label>Add End Date Instead of Number of Months</Label>
                                <Switcher
                                    isActive={undefined}
                                    for={formData?.endDate}
                                    onChange={() => {
                                        setShowDate(!showDate);
                                        if (!showDate) {
                                            delete formData.numberOfMonths;
                                        }
                                        else {
                                            delete formData.endDate;
                                        }
                                    }}
                                    togglevalue={showDate}
                                    size="normal"
                                />
                            </div>
                            {showDate ?
                                <Input
                                    type="date"
                                    placeholder="Enter End Date"
                                    value={formData?.endDate}
                                    name="endDate"
                                    onChange={(name, value) => {
                                        //remove any numberOfMonths from formData
                                        delete formData.numberOfMonths
                                        handleInputChange(name, value)
                                    }}
                                    min={new Date().toISOString().split('T')[0]}
                                />
                                :
                                <div className="space-y-2">
                                    <Label>Number of Months </Label>
                                    <Input
                                        type="number"
                                        placeholder="Enter Number of Months"
                                        value={formData?.numberOfMonths}
                                        name="numberOfMonths"
                                        onChange={(name, value) => {
                                            //remove any endDate from formData
                                            delete formData.endDate
                                            handleInputChange(name, value)
                                        }}
                                    />
                                </div>
                            }



                        </div>
                        <div className="space-y-2">
                            <div className="space-y-2">
                                <Label>Tag Details</Label>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div className="space-y-2">
                                        <CustomDropDown
                                            width='w-full'
                                            label='Logo'
                                            options={Logos}
                                            onSelect={(value) => {
                                                setTagToBeAdded({
                                                    ...tagToBeAdded,
                                                    logo: value
                                                })
                                            }}
                                            value={tagToBeAdded.logo}
                                        />
                                    </div>
                                    <div className="space-y-2">
                                        <Label>Position</Label>
                                        <input
                                            type="number"
                                            className='w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light'
                                            placeholder="Enter Position"
                                            name="position"
                                            onChange={(e: any) => {
                                                setTagToBeAdded({
                                                    ...tagToBeAdded,
                                                    position: e.target.value
                                                })
                                            }}
                                            value={tagToBeAdded.position}
                                        />
                                    </div>
                                </div>
                                <ReactQuill
                                    id={`description`}
                                    placeholder={`Enter Description`}
                                    value={tagToBeAdded.description}
                                    onChange={(content) =>
                                        setTagToBeAdded({
                                            ...tagToBeAdded,
                                            description: content
                                        })
                                    }
                                    className="border border-gray-300 rounded"
                                />
                                <div className="flex items-center justify-end gap-2">
                                    <ButtonFill handleClick={mode === 'add' ? AddTagDetails : EditTagDetails}>
                                        {mode === 'add' ? 'Add' : 'Edit'}
                                    </ButtonFill>
                                </div>
                                <div className=" bg-white shadow-md sm:rounded-lg mt-4">
                                    <div className="overflow-x-auto">
                                        <table className="w-full mb-20 text-sm text-left text-gray-500">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-4 py-4">
                                                        Logo
                                                    </th>
                                                    <th scope="col" className="px-4 py-4 text-center">
                                                        Description
                                                    </th>
                                                    <th scope="col" className="px-4 py-4 text-center">
                                                        Position
                                                    </th>
                                                    <th scope="col" className="px-4 py-3 text-center">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formData.tagDetails.map((tag, index) => (
                                                    <tr className="border-b" key={index}>
                                                        <td className="px-4 py-3">{tag?.logo}</td>
                                                        <td className="px-4 py-3">{tag?.description.substring(0, 10)}...</td>
                                                        <td className="px-4 py-3 text-center">{tag?.position}</td>
                                                        <td className="px-4 py-3 text-center">
                                                            <div className='flex justify-around items-center'>
                                                                <DeleteOutline
                                                                    onClick={() => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            tagDetails: formData.tagDetails.filter((tag, i) => i !== index)
                                                                        })
                                                                    }}
                                                                    className='cursor-pointer'
                                                                />
                                                                <Edit
                                                                    onClick={() => {
                                                                        setTagToBeAdded({
                                                                            ...tag,
                                                                            index: index
                                                                        })
                                                                        setMode('edit')
                                                                    }}
                                                                    className='cursor-pointer'
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                <div className="space-y-2 mt-4">
                                    <div className="space-y-2">
                                        <Label>Package Position</Label>
                                        <input
                                            type="number"
                                            className='w-full rounded border-[1.5px] border-stroke bg-transparent py-2 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light'
                                            placeholder="Enter Position"
                                            name="position"
                                            onChange={(e: any) => {
                                                setFormData({
                                                    ...formData,
                                                    position: e.target.value
                                                })
                                            }}
                                            value={formData.position}
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>


                    <div className="flex items-center justify-end gap-2">
                        <ButtonOutlined
                            handleClick={() => {
                                props?.setModal(false);
                                props?.setMode('add');
                            }}
                        >
                            Cancel
                        </ButtonOutlined>

                        <ButtonFill handleClick={Submit} isLoading={loading}>
                            {props?.mode === 'add' ? 'Add' : 'Edit'}{" "} Pricing Plan
                        </ButtonFill>
                    </div>


                </div>


            </div>
        </div>
    )
}

const RibbonTags = [
    "Best Value",
    "Popular",
    "Recommended",
    "Limited Time",
    "New",
    "Exclusive",
    "Special Offer",
    "Hot Deal",
    "Best Seller",
    "Top Rated",
    "Top Seller",
    "Top Pick",
]

const Logos = [
    "Red",
    "Green",
    "Yellow",
]

export default AddPricingModal;

