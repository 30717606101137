import React from "react";
import { Module, Topic } from "../type";
import { useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

interface CourseStructurePreviewProps {
  modules: Module[];
  onModulesReorder: (newModules: Module[]) => void;
}

type DragItem = {
  index: number;
  id: string;
  type: string;
  moduleIndex?: number;
};

const TopicItem = ({ topic, index, moduleIndex, moveTopic, moduleId }: any) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "topic",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) return;
      if (item.moduleIndex !== moduleIndex) return; // Only allow reordering within same module

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveTopic(moduleIndex, dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "topic",
    item: () => ({
      id: topic.id,
      index,
      moduleIndex,
      type: "topic",
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      className="border-l-2 border-green-400 pl-4 ml-2 cursor-move"
    >
      <div className="font-medium text-green-600 flex items-center">
        <span className="mr-2">☰</span>
        {moduleIndex + 1}.{index + 1} {topic.title}
      </div>

      <div className="mt-1 space-y-1">
        {topic.contents.map((content, contentIndex) => (
          <div
            key={content.id}
            className="border-l-2 border-gray-300 pl-4 ml-2 text-sm text-gray-700"
          >
            <span className="inline-block w-6 h-6 mr-2 text-xs bg-gray-100 rounded-full flex items-center justify-center">
              {content.type === "video" && "🎥"}
              {content.type === "pdf" && "📄"}
              {content.type === "text" && "📝"}
              {content.type === "assessment" && "✍️"}
            </span>
            {content.title}
            {content.duration && (
              <span className="text-xs text-gray-500 ml-2">
                ({content.duration} min)
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const ModuleItem = ({
  module,
  index,
  moveModule,
  moveTopic,
  moduleIndex,
}: any) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "module",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveModule(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "module",
    item: () => ({
      id: module.id,
      index,
      type: "module",
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      className="border-l-2 border-blue-500 pl-4 cursor-move"
    >
      <div className="font-medium text-blue-600 flex items-center">
        <span className="mr-2">☰</span>
        {moduleIndex + 1}. {module.title}
      </div>
      <div className="text-sm text-gray-600 mt-1">{module.description}</div>

      <div className="mt-2 space-y-2">
        {module.sub_modules.map((topic, topicIndex) => (
          <TopicItem
            key={topic.id}
            topic={topic}
            index={topicIndex}
            moduleIndex={index}
            moduleId={module.id}
            moveTopic={moveTopic}
          />
        ))}
      </div>
    </div>
  );
};

export const CourseStructurePreview: React.FC<CourseStructurePreviewProps> = ({
  modules,
  onModulesReorder,
}) => {
  const moveModule = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragModule = modules[dragIndex];
      const newModules = [...modules];
      newModules.splice(dragIndex, 1);
      newModules.splice(hoverIndex, 0, dragModule);
      onModulesReorder(newModules);
    },
    [modules, onModulesReorder]
  );

  const moveTopic = React.useCallback(
    (moduleIndex: number, dragIndex: number, hoverIndex: number) => {
      const newModules = [...modules];
      const module = newModules[moduleIndex];
      const sub_modules = [...module.sub_modules];
      const dragTopic = sub_modules[dragIndex];

      sub_modules.splice(dragIndex, 1);
      sub_modules.splice(hoverIndex, 0, dragTopic);

      newModules[moduleIndex] = {
        ...module,
        sub_modules,
      };

      onModulesReorder(newModules);
    },
    [modules, onModulesReorder]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
        <h3 className="text-lg font-semibold mb-4">Course Structure Preview</h3>
        <div className="space-y-4">
          {modules.map((module, index) => (
            <ModuleItem
              key={module.id}
              module={module}
              index={index}
              moduleIndex={index}
              moveModule={moveModule}
              moveTopic={moveTopic}
            />
          ))}
        </div>
      </div>
    </DndProvider>
  );
};
