export const initialValues = {
    courseName: "",
    videoFile: null,
    pdfFile: null,
    RepresentativeLink: "",
    selectedTeacher: [],
    selectedDuration: null,
    desc: "",
    tagDetails: [
      {
        description: "",
        logo: "",
        isActive: true,
        position: "",
      },
    ],
    videoPreview: "",
    tag: "",
    courseXplans: [],
    colorCode: "#000000",
    thumbnail_image: null,
    course_image: null,
    carouselImages: []
  };