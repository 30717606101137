export const dummyModules = [];

// export const dummyModules = [
//   {
//     id: "1734349486895",
//     title: "1.1 Welcome",
//     orderIndex: 0,
//     description: "Dummy Description 1.1 Welcome",
//     sub_modules: [
//       {
//         id: "1734349532943",
//         title: "Subject Guide Recorded Video ",
//         orderIndex: 0,
//         contents: [],
//       },
//       {
//         id: "1734349588155",
//         title: "Teachers Intro page and Video",
//         orderIndex: 1,
//         contents: [],
//       },
//     ],
//   },
//   {
//     id: "1734349603420",
//     title: "Cell Structure and Function ",
//     orderIndex: 1,
//     description: "Description Cell Structure and Function ",
//     sub_modules: [
//       {
//         id: "1734349628659",
//         title: "Chapter Guide Video 2-3 Mins",
//         orderIndex: 0,
//         contents: [
//           {
//             id: "1734349643786",
//             type: "video",
//             title: "",
//             duration: 0,
//           },
//           {
//             id: "1734349677071",
//             type: "video",
//             title: "",
//             duration: 0,
//           },
//         ],
//       },
//       {
//         id: "1734349684591",
//         title: "Chapter Guide PDF",
//         orderIndex: 1,
//         contents: [],
//       },
//       {
//         id: "1734349704366",
//         title: "Chapter Guide PDF 2",
//         orderIndex: 2,
//         contents: [],
//       },
//     ],
//   },
// ];
