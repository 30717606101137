import React, { useContext } from "react";
import { useState, useEffect } from "react";

import { CloseOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import { message, Radio } from "antd";
import { UserContext } from "state-management/user-context";
import PaymentMethods from "../(components)/PaymentMethods";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { backendUrl } from "constants/config";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis";
import { customAPIHandler } from "(apis)/api-interface/api-handler";
function VerifyCouponCode(couponcode) {
  var data = axios({
    method: "GET",
    withCredentials: true,
    url: backendUrl + `/api/coupons/verify-coupon-code/${couponcode}`,
  });
  return data;
}
async function requestBundleSubscriptionApi(data) {
  return axios.post(`${backendUrl}/api/orders/create-bundle-order`, data);
}
const CheckoutMyOwnBundle = ({ isOpenMobCartModal, setIsOpenMobCartModal }) => {
  // constants
  const { user } = useContext(UserContext);

  // UseStates
  const [cartItems, setCartItems] = useState([]);
  const [email, setEmail] = useState<string>("");

  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [Processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Bank Transfer");
  const [paymentProofbase64, setPaymentProofbase64] = useState("");
  const [couponValue, setCouponValue] = React.useState("");
  const [couponError, setCouponError] = React.useState(false);
  const [couponApplied, setCouponApplied] = React.useState(false);
  const [couponDiscount, setCouponDiscount] = React.useState(0);
  const [disabled, setDisabled] = React.useState(false);

  // Functions
  const applyCoupon = async () => {
    if (couponValue === "") {
      setCouponError(true);
      return;
    }
    if (couponApplied) {
      toast.error("Coupon Already Applied");
      return;
    }

    try {
      const response = await VerifyCouponCode(couponValue?.toUpperCase());
      if (response?.data?.Error == true) {
        toast.error("Invalid Coupon Code");
        setCouponError(true);
      } else {
        toast.success("Coupon Applied Successfully");
        setCouponError(false);
        setCouponApplied(true);
        setCouponDiscount(response?.data?.Amount);

        setTotal(total - response?.data?.Amount * total);
        setDiscount(discount + response?.data?.Amount * total);
        setCouponError(false);
      }
    } catch (error) {
      toast.error("Failed to apply coupon");
      console.error(error);
    }
  };

  const onChangeRadio = (e) => {
    setPaymentMethod(e.target.value);
  };

  const UploadPaymentProof = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPaymentProofbase64(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const clearCart = () => {
    localStorage.removeItem("custom-bundle-cart");
    setCartItems([]);
    setCouponApplied(false);
    setCouponValue("");
    setTotal(0);
    setDiscount(0);
  };

  const RequestSubscription = async () => {
    if (cartItems.length === 0) {
      return;
    }
    if (
      paymentProofbase64 === null ||
      paymentProofbase64 === undefined ||
      paymentProofbase64 === "" ||
      paymentProofbase64 === "data:,"
    ) {
      message.error("Please Upload Payment Proof");
      return;
    }

    setDisabled(true);
    try {
      let fetchedUser = null;
      const response = await BasicGetApi("getUserInfoByEmail", [], {
        email: email,
      });

      if (response && response?.data?.isSuccess) {
        fetchedUser = response?.data?.data;
      }
      console.log("fetchedUser", fetchedUser)
      if (!fetchedUser) {
        message.error("Invalid User Email");
        setDisabled(false);
        return;
      }
      const InitialAmount = cartItems?.reduce(
        (total, item) => total + item.originalPrice,
        0
      );
      const PromotionalAmount = cartItems?.reduce(
        (total, item) => total + item.promotionalPrice,
        0
      );
      let Products = [];
      cartItems?.map((feature, index) =>
        feature.access.map((f) => Products.push(f))
      );

      const data = {
        UserID: fetchedUser?._id,
        UserName: email,
        TotalAmount: discount,
        CouponUsed: couponApplied,
        CouponCode: couponValue,
        DiscountAmount: discount - total || 0,
        PaidAmount: total,
        PaymentMethod: paymentMethod,
        PaymentProof: paymentProofbase64,
        MOBComponents: cartItems,
        CouponDiscount: couponDiscount,
        BREAKDOWN: {
          TotalAmount: InitialAmount,
          PromotionalAmount: PromotionalAmount,
          PromotionalDiscount: (
            (InitialAmount - PromotionalAmount) /
            InitialAmount
          ).toFixed(2),
          CouponDiscountAmount: couponDiscount * PromotionalAmount,
          CouponDiscount: couponDiscount,
          TotalDiscount: discount - total,
          GrandTotal: total,
        },
        Products,
      };

      const Response = await requestBundleSubscriptionApi(data);
      if (!Response.data.success) {
        toast.error(Response.data.message);
      } else {
        message.success("Your order has been placed successfully");
        const order = Response?.data?.Order;

        if (!order || !order?._id) {
          message.error("Order not found or invalid");
        } else {
          const changeStatusResponse = await customAPIHandler(
            "changeStatusForBundlePurchase",
            "",
            {
              orderId: order._id,
              newStatus: "Accepted",
              message: "Order Accepted",
            },
            "PRE-MEDICAL"
          );

          if (
            changeStatusResponse?.data?.message ===
            "Order Status Updated Successfully"
          ) {
            localStorage.removeItem("custom-bundle-cart");
            message.success("Order Accepted Successfully");
          } else {
            message.error("Failed to accept order");
          }
        }

        // router("/bundles/mdcat");
        clearCart();
        setProcessing(false);
        setDisabled(false);
        setIsOpenMobCartModal(false)

        //track via google analytics, track that he is placing the order, not the item
        // handlePurchase("premed-bundle", total);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to request subscription");
    }
    setDisabled(false);
  };

  const Duration = (feature) => {
    if (feature?.duration?.type === "number") {
      return feature?.duration?.numberOfMonths === 1
        ? "1 Month"
        : feature?.duration?.numberOfMonths + " Months";
    } else {
      return (
        "Till " +
        new Date(feature?.duration?.endDate).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      );
    }
  };

  const RemoveItemFromCart = (featureId) => {
    let cart = cartItems;
    let index = cart.findIndex((item) => item.featureId === featureId);

    cart.splice(index, 1);
    if (cart.length === 0) {
      clearCart();
      localStorage.removeItem("custom-bundle-cart");
      return;
    }

    const newTotal = cartItems.reduce(
      (total, item) => total + item.promotionalPrice,
      0
    );
    const newDiscount = cartItems.reduce(
      (total, item) => total + item.originalPrice,
      0
    );

    setTotal(newTotal);
    setDiscount(newDiscount);

    if (couponApplied) {
      const newCouponDiscount = couponDiscount * newTotal;
      setTotal(newTotal - newCouponDiscount);
      setDiscount(newDiscount + newCouponDiscount);
    }

    setCartItems(cart);

    let storageObject = {
      toBeBought: cart,
      totalPrice: newTotal,
      totalDiscount: newDiscount,
    };
    localStorage.setItem("custom-bundle-cart", JSON.stringify(storageObject));
  };
  // useeffects
  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("custom-bundle-cart"));
    if (cart) {
      setCartItems(cart.toBeBought);
      setTotal(cart.totalPrice); //Amount after discount
      setDiscount(cart.totalDiscount); //Amount before discount
    }
  }, [isOpenMobCartModal]);
  return (
    <>
      <CustomAntdModal
        isFooter={false}
        width={1500}
        isOpenModal={isOpenMobCartModal}
        setIsOpenModal={setIsOpenMobCartModal}
      >
        <div className="Background flex flex-col">
          {!Processing ? (
            <div className="w-[90%] mx-auto mt-8 md:mt-8 flex flex-col md:flex-row justify-between gap-8">
              <div className=" w-full md:w-3/4 mx-auto">
                <h1 className="text-primary text-5xl font-bold">CART</h1>
                <h3 className="text-primary text-2xl font-bold mt-4">
                  Upto 70% off on all bundles!
                </h3>
                <h3 className="text-primary text-2xl font-bold mt-4">
                  Order Summary
                </h3>
                <p className="text-black ">
                  You have {cartItems.length} items in your cart
                </p>
                <div className="w-full mt-4 bg-white p-4 rounded-3xl shadow-xl">
                  <div className="text-lg font-bold">Selected Features</div>
                  <div className="flex flex-row flex-wrap gap-2">
                    {cartItems &&
                      cartItems?.map((feature, index) => (
                        <div
                          className="relative my-2 p-4 rounded shadow"
                          key={index}
                        >
                          <div
                            className="absolute top-0 right-0 cursor-pointer"
                            onClick={() => {
                              RemoveItemFromCart(feature.featureId);
                            }}
                          >
                            <CloseOutlined
                              className="text-primary font-bold hover:text-red-500 transition duration-300 ease-in-out"
                              size={16}
                            />
                          </div>
                          <div className="flex justify-start items-center gap-4">
                            <img
                              className="w-4 h-4 rounded-full"
                              src={
                                "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CustomImages/PreMedCircleLogo.cffae65f.png"
                              }
                              alt="cardlogo"
                            />
                            <div className="">
                              <span className="text-sm font-bold text-primary">
                                {feature?.featureName}{" "}
                              </span>
                            </div>
                            <div className="text-xs text-gray-500 font-semibold">
                              {Duration(feature)}
                            </div>
                            <div className="text-xs text-gray-500 font-semibold">
                              {feature?.promotionalPrice}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <h3 className="text-primary text-2xl font-bold mt-4">
                  Refund Policy
                </h3>
                <p>
                  No Refunds are allowed once the payment is made for the
                  selected bundle. However, you can change the bundle within 24
                  hours of purchase. For more details, please visit our Refund
                  Policy Page.
                </p>
              </div>
              <div className=" w-full md:w-1/4 mx-auto">
                <div className="flex flex-col bg-white p-4 rounded-xl shadow-xl">
                  <div className="text-gray-800 text-lg font-semibold mb-2 mt-2">
                    Coupon Code
                  </div>
                  {couponError && (
                    <div className="text-red-500 text-xs font-bold">
                      Invalid Coupon Code
                    </div>
                  )}

                  <div className="bg-white rounded-lg shadow-xl flex justify-between items-center">
                    <input
                      placeholder="Enter Coupon Code"
                      className="w-3/4 px-4 py-2 rounded-lg focus:border-none focus:outline-none transition duration-300 ease-in-out font-bold text-black text-sm"
                      value={couponValue}
                      onChange={(e) =>
                        setCouponValue(e?.target?.value?.toUpperCase())
                      }
                      disabled={couponApplied}
                    />
                    <button
                      className="bg-primary text-white rounded-lg px-4 py-2 hover:bg-primary transition duration-300 ease-in-out disabled:opacity-50 cursor-pointer text-center font-bold disabled:cursor-not-allowed"
                      onClick={applyCoupon}
                      disabled={couponApplied}
                    >
                      Apply
                    </button>
                  </div>

                  <div className="flex justify-between items-center mb-2 mt-4">
                    <div className="text-black font-semibold">Order Value</div>
                    <div className="text-gray-800">
                      {discount.toFixed()} PKR
                    </div>
                  </div>

                  <div className="flex justify-between items-center mb-2">
                    <div className="text-black font-semibold">Discount</div>
                    <div className="text-gray-800">
                      {(discount - total).toFixed()} PKR
                    </div>
                  </div>

                  <div className="border-b-2 border-red-300 mb-2"></div>

                  <div className="flex justify-between items-center mb-2">
                    <div className="text-black font-semibold text-xl">
                      Total
                    </div>
                    <div className={`text-primary font-bold text-xl`}>
                      {total.toFixed()} PKR
                    </div>
                  </div>

                  <p className="text-xs text-gray-500 text-center justify-center">
                    This is a One-Time payment payable completely upfront
                    through Credit/Debit Card, Bank Transfer, JazzCash, or
                    EasyPaisa
                  </p>

                  <button
                    className="bg-primary text-white rounded-lg px-4 py-2 hover:bg-primary-100 transition duration-300 ease-in-out mt-4 hover:shadow-xl cursor-pointer text-center font-bold"
                    onClick={() => {
                      setProcessing(true);
                    }}
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="w-[90%]  mx-auto mt-8 md:mt-20 flex flex-col md:flex-row justify-between gap-8">
                <div className="w-full md:w-3/4 mx-auto">
                  <div className="flex flex-start items-center gap-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-8 h-8 text-red-500 mb-2cursor-pointer"
                      onClick={() => setProcessing(false)}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5"
                      />
                    </svg>

                    <h1 className="text-primary text-5xl font-bold">Payment</h1>
                  </div>
                  <PaymentMethods paymentMethod={paymentMethod} />
                  <div className="flex flex-col gap-4 mt-8">
                    By checking out you agree with our Terms of Service. We will
                    process your personal data for the fulfillment of your order
                    and other purposes as per our Privacy Policy.{" "}
                  </div>
                </div>
                <div className="w-full md:w-1/4 mx-auto">
                  <div className="flex flex-col bg-white p-4 rounded-xl shadow-xl">
                    <div className="text-gray-800">Select Payment Method</div>

                    <div className="flex flex-col flex-start mb-4 mt-4">
                      <Radio.Group
                        onChange={(e) => {
                          setPaymentMethod(e.target.value);
                          onChangeRadio(e);
                        }}
                        value={paymentMethod}
                      >
                        <div className="">
                          {radio("Bank Transfer")}
                          {radio("Easypaisa")}
                          {radio("JazzCash")}
                        </div>
                      </Radio.Group>
                    </div>

                    <div className="flex justify-between items-center mb-2 mt-4">
                      <div className="text-black font-semibold">
                        Order Value
                      </div>
                      <div className="text-gray-800">
                        {discount.toFixed()} PKR
                      </div>
                    </div>

                    <div className="flex justify-between items-center mb-2">
                      <div className="text-black font-semibold">Discount</div>
                      <div className="text-gray-800">
                        {(discount - total).toFixed()} PKR
                      </div>
                    </div>

                    <div className="border-b-2 border-red-300 mb-2"></div>

                    <div className="flex justify-between items-center mb-2">
                      <div className="text-black font-semibold text-xl">
                        Total
                      </div>
                      <div className={`text-primary font-bold text-xl`}>
                        {total.toFixed()} PKR
                      </div>
                    </div>

                    <p className="text-xs text-gray-500 text-center justify-center mt-2 mb-2">
                      This is a One-Time payment payable completely upfront
                      through Credit/Debit Card, Bank Transfer, JazzCash, or
                      EasyPaisa
                    </p>
                    <div className="bg-white rounded-lg shadow-xl flex justify-between items-center my-4">
                      <input
                        placeholder="Enter Email "
                        className="w-3/4 px-4 py-2 rounded-lg focus:border-none focus:outline-none transition duration-300 ease-in-out font-bold text-black text-sm placeholder:text-gray-500"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setEmail(e.target.value.trim()?.toLowerCase())
                        }
                      />
                    </div>
                    {paymentProofbase64 && (
                      <div className="flex justify-between items-center mb-2 p-2 rounded-lg">
                        <img
                          src={paymentProofbase64}
                          alt="Payment Proof"
                          className="w-full rounded-lg object-cover h-32"
                        />
                      </div>
                    )}

                    <input
                      type="file"
                      className="border-2 border-red-500 p-2 rounded-lg"
                      hidden
                      id="screen"
                      onChange={UploadPaymentProof}
                    />
                    <button
                      className="bg-primary font-bold text-white px-4 py-2 rounded-lg hover:bg-primary transition duration-300 ease-in-out"
                      onClick={() => {
                        document.getElementById("screen").click();
                      }}
                    >
                      Upload Paid Receipt
                    </button>

                    <button
                      className="bg-primary font-bold text-white px-4 py-2 rounded-lg hover:bg-primary transition duration-300 ease-in-out mt-4 disabled:opacity-50 disabled:cursor-not-allowed "
                      onClick={RequestSubscription}
                      disabled={disabled}
                      style={{
                        opacity: disabled ? 0.5 : 1,
                        cursor: disabled ? "not-allowed" : "pointer",
                      }}
                    >
                      {" "}
                      Complete Order
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="w-3/4 mx-auto mt-20 flex flex-col justify-between gap-8">
              <div className="font-bold text-2xl text-primary">How to Pay</div>
              <div className="flex flex-col md:flex-row gap-4">
                <div className="w-full md:w-3/4 rounded shadow-xl">
                  <img src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/StaticImages/PaymentTutorial.png" />
                </div>
                <iframe
                  className="w-full md:w-1/4 rounded shadow-xl"
                  src={paymentVideo}
                  title="How to purchase PreMed.PK Subscription?"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </div>
            </div>
  */}
            </>
          )}
        </div>
      </CustomAntdModal>
    </>
  );
};

function radio(name) {
  return (
    <div>
      <Radio value={name}>
        <div className="">
          <div className="">{name}</div>
        </div>
      </Radio>
    </div>
  );
}

export default CheckoutMyOwnBundle;
