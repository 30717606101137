import React from "react";
import { Course } from "../../type";
import { FileSearch } from "lucide-react";

interface ReviewCourseProps {
  courseData: Course;
}

export const ReviewCourse: React.FC<ReviewCourseProps> = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[400px] text-center space-y-4">
      <FileSearch className="w-24 h-24 text-gray-400" />
      <h3 className="text-2xl font-semibold text-gray-700">Course Review Coming Soon</h3>
      <p className="text-gray-500 max-w-md">
        We are working on creating a comprehensive review page for the course.
      </p>
    </div>
  );
};
