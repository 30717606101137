import { apiRequest } from '(apis)/api-interface/api-handler'
import { WhatsApp } from '@material-ui/icons'
import { notification } from 'antd'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { ButtonFill } from 'shared-components/ui/CustomButtons'
import Loading from 'shared-components/ui/Loading'


const Students = ({ id, courseData, setCoursedata, user }) => {



    const FormatDate = (date: Date | string): string => {
        //dd-mm-yyyy
        const d = new Date(date)
        let day: number | string = d.getDate()
        let month: number | string = d.getMonth() + 1
        const year = d.getFullYear()
        if (day < 10) {
            day = `0${day}`
        }
        if (month < 10) {
            month = `0${month}`
        }
        return `${day}-${month}-${year}`
    }

    const Duration = (startDate: Date | string, endDate: Date | string): string => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diff = end.getTime() - start.getTime(); // Using getTime() to get milliseconds
        const days = diff / (1000 * 60 * 60 * 24);
        return days.toFixed(0);
    };


    const [selectedState, setSelectedState] = React.useState("All");
    const [loading, setLoading] = React.useState(false);
    const [students, setStudents] = React.useState([]);
    const [filteredStudents, setFilteredStudents] = React.useState([]);
    const GetStudents = async () => {
        setLoading(true);
        try {
            const Response = await apiRequest("getCourseStudents", null, [id]);
            if (Response.status === 200) {
                console.log(Response.data.students)
                const sortedStudents = Response.data.students.sort((a, b) => {
                    const detailsA = GetCourseDetails(a);
                    const detailsB = GetCourseDetails(b);

                    // Handle cases where dates might be null
                    if (!detailsA.subscriptionStartDate) return 1;
                    if (!detailsB.subscriptionStartDate) return -1;

                    return new Date(detailsB.subscriptionStartDate).getTime() -
                        new Date(detailsA.subscriptionStartDate).getTime();
                });
                setStudents(sortedStudents);
                setFilteredStudents(sortedStudents);
            } else {
                toast.error(Response.data.message)
            }
        } catch (error) {
            console.log(error)
            toast.error('An error occured while fetching students')
        }
        setLoading(false);
    }

    React.useEffect(() => {
        GetStudents();
    }, []);

    const AddToWhatsapp = async (studentid) => {
        try {
            if (!user) {
                toast.error('Please login to add to whatsapp')
            }
            const Response = await apiRequest("markAsWhatsappAdded", { userId: studentid }, [id])
            if (Response.data.success) {
                toast.success(Response.data.message)
                if (Response.data.message === "Whatsapp added successfully") {
                    setCoursedata(prevState => {
                        return {
                            ...prevState,
                            whatsappAdded: [...prevState.whatsappAdded, studentid]
                        }
                    })
                }
                else {
                    setCoursedata(prevState => {
                        return {
                            ...prevState,
                            whatsappAdded: prevState.whatsappAdded.filter(id => id !== studentid)
                        }
                    })
                }
            }
        }
        catch (error) {
            toast.error('An error occured while adding to whatsapp')
        }
    }


    const ChangeSubscriptionStatus = async (studentid, status) => {
        try {
            if (!user) {
                toast.error('Please login to change subscription status')
            }
            const Response = await apiRequest("changeSubscriptionStatus", { userId: studentid, status: status }, [id])
            if (Response.data.success) {
                toast.success(Response.data.message)
                GetStudents();
            }
        }
        catch (error) {
            toast.error('An error occured while changing subscription status')
        }
    }
    const Tabs = [
        "All",
        "Active Subscriptions",
        "Deactivated Subscriptions",
        "Expired Subscriptions",
    ]

    const GetCourseDetails = (user: any) => {
        const UserCourses = user.courses_bundles || []
        const CourseDetails = UserCourses.find((course: any) => course.course_id === id)

        // Return default values if no course details found
        if (!CourseDetails) {
            return {
                course_id: id,
                subscriptionStartDate: null,
                subscriptionEndDate: null,
                sku_name: "N/A",
                tag: "N/A",
                status: "Not Enrolled"
            }
        }

        return {
            course_id: CourseDetails.course_id,
            subscriptionStartDate: CourseDetails.subscriptionStartDate,
            subscriptionEndDate: CourseDetails.subscriptionEndDate,
            sku_name: CourseDetails.sku_name,
            tag: CourseDetails.tag || "N/A",
            status: CourseDetails.status || "Active"
        }
    }

    // New filtering function
    const filterStudents = React.useMemo(() => {
        if (!students.length) return [];

        const today = new Date();

        switch (selectedState) {
            case "All":
                return students;

            case "Active Subscriptions":
                return students.filter(student => {
                    const courseDetails = GetCourseDetails(student);
                    const endDate = new Date(courseDetails.subscriptionEndDate);
                    return courseDetails.status === "Active" && endDate > today;
                });

            case "Deactivated Subscriptions":
                return students.filter(student => {
                    const courseDetails = GetCourseDetails(student);
                    return courseDetails.status === "Blocked";
                });

            case "Expired Subscriptions":
                return students.filter(student => {
                    const courseDetails = GetCourseDetails(student);
                    const endDate = new Date(courseDetails.subscriptionEndDate);
                    return endDate < today || courseDetails.status === "Expired";
                });

            default:
                return students;
        }
    }, [students, selectedState]);

    // Update the useEffect to set filteredStudents when selectedState changes
    React.useEffect(() => {
        setFilteredStudents(filterStudents);
    }, [selectedState, students]);

    return (
        <div className='w-full'>
            {loading ? <Loading /> :
                <div className='mt-8 w-full'>
                    <div className='flex flex-col'>
                        <h1 className='text-2xl font-bold'>Enrolled Students</h1>
                        <div className='mt-4 flex flex-row flex-wrap gap-4'>
                            <div className='flex flex-col border-r-2 border-gray-300 pr-4'>
                                <h1 className='text-lg font-bold'>Total Subscriptions</h1>
                                <h1 className='text-2xl font-bold'>{students.length}</h1>
                            </div>
                            <div className='flex flex-col border-r-2 border-gray-300 pr-4'>
                                <h1 className='text-lg font-bold'>Total Subscriptions Today</h1>
                                <h1 className='text-2xl font-bold'>{students.filter(student => new Date(student.createdAt).toDateString() === new Date().toDateString()).length}</h1>
                            </div>
                            <div className='flex flex-col border-r-2 border-gray-300 pr-4'>
                                <h1 className='text-lg font-bold'>Expired Subscriptions</h1>
                                <h1 className='text-2xl  font-bold'>{students.filter(student => new Date(student.createdAt).toDateString() === new Date().toDateString()).length}</h1>
                            </div>

                        </div>

                        <div className='mt-4 flex flex-row flex-wrap border-2 border-gray-300 rounded p-2'>
                            {Tabs.map((tab) => (
                                <button
                                    key={tab}
                                    className={`px-4 py-2 rounded ${selectedState === tab ? 'bg-rose-500 text-white' : 'bg-white text-gray-500'}`}
                                    onClick={() => setSelectedState(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>

                    </div>

                    <div className='mt-4'>
                        <div className="overflow-x-auto">
                            <table className="w-full mb-20 text-sm text-left text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-4 py-4">
                                            Email
                                        </th>
                                        <th scope="col" className="px-4 py-4 text-left">
                                            Full Name
                                        </th>
                                        <th scope="col" className="px-4 py-4 text-left">
                                            Phone Number
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Subscription Status
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Subscription Start Date
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Subscription End Date
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            SKU Name
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Tag
                                        </th>
                                        <th scope="col" className="px-4 py-3 text-center">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredStudents.map((student, index) => (
                                        <tr className="border-b text-gray-800" key={student._id}>
                                            <td className="px-4 py-3">{student.username}</td>
                                            <td className="px-4 py-3">{student.fullname}</td>
                                            <td className="px-4 py-3">{student.phonenumber}</td>
                                            <td className="px-4 py-3">{GetCourseDetails(student).status}</td>
                                            <td className="px-4 py-3">{FormatDate(GetCourseDetails(student).subscriptionStartDate)}</td>
                                            <td className="px-4 py-3">{FormatDate(GetCourseDetails(student).subscriptionEndDate)}</td>
                                            <td className="px-4 py-3">{GetCourseDetails(student).sku_name}</td>
                                            <td className="px-4 py-3">{GetCourseDetails(student).tag}</td>
                                            <td className="px-4 py-3">
                                                <div className="flex items-center justify-center gap-2">
                                                    <WhatsApp
                                                        fontSize="medium"
                                                        className={`cursor-pointer ${courseData?.whatsappAdded?.includes(student._id) ? "text-green-500" : "text-gray-500"}`}
                                                        onClick={() => {
                                                            AddToWhatsapp(student._id);
                                                        }}
                                                    />
                                                    {GetCourseDetails(student).status === "Active" ? (
                                                        <button
                                                            className="text-xs bg-primary text-white px-2 py-1 rounded text-xs hover:scale-105 transition-all duration-300"
                                                            onClick={() => {
                                                                ChangeSubscriptionStatus(student._id, "Blocked");
                                                            }}
                                                        >
                                                            Block
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="text-xs bg-primary text-white px-2 py-1 rounded text-xs hover:scale-105 transition-all duration-300"
                                                            onClick={() => {
                                                                ChangeSubscriptionStatus(student._id, "Active");
                                                            }}
                                                        >
                                                            Unblock
                                                        </button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>

                    </div>

                </div>
            }

        </div>
    )
}

export default Students