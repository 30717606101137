import React, { useState } from "react";
import { Button } from "./ui/button";
import { Select } from "./ui/select";
import { Module, Topic } from "../type";
import { ChevronRight } from "@material-ui/icons";
import { ChevronDown, DeleteIcon } from "shared-components/ui/Icons";
import { ButtonOutlined } from "shared-components/ui/CustomButtons";
import { Input, Switcher } from "shared-components/ui/CustomForm";
import { RichTextEditor } from "shared-components/ui/rich-text-editor";
import { SaveIcon } from "lucide-react";
import { v4 as uuidv4 } from 'uuid';
import { CoursesApi } from "(apis)/(shared-apis)/new-courses";

interface ModuleFormProps {
  modules: Module[];
  onModuleChange: (modules: Module[]) => void;
  handleSave?: () => void;
  courseId: string;
}

export function ModuleForm({
  modules,
  onModuleChange,
  handleSave,
  courseId,
}: ModuleFormProps) {
  const [expandedModules, setExpandedModules] = useState<number[]>([]);
  const [expandedsub_modules, setExpandedsub_modules] = useState<number[]>([]);

  const toggleModule = (moduleIndex: number) => {
    setExpandedModules((prev) =>
      prev.includes(moduleIndex)
        ? prev.filter((idx) => idx !== moduleIndex)
        : [...prev, moduleIndex]
    );
  };

  const toggleTopic = (topicIndex: number) => {
    setExpandedsub_modules((prev) =>
      prev.includes(topicIndex)
        ? prev.filter((idx) => idx !== topicIndex)
        : [...prev, topicIndex]
    );
  };

  const addModule = () => {
    const newModule: Module = {
      id: uuidv4(),
      title: `Module ${modules.length + 1}`,
      orderIndex: modules.length,
      description: "",
      sub_modules: [],
      status: "upcoming",
      is_coming_soon: false,
    };
    onModuleChange([...modules, newModule]);
  };

  const deleteModule = (moduleIndex: number) => {
    const updatedModules = modules.filter((module, idx) => idx !== moduleIndex);
    // Reindex the remaining modules
    const reindexedModules = updatedModules?.map((module, index) => ({
      ...module,
      orderIndex: index,
    }));
    onModuleChange(reindexedModules);
  };

  const addTopic = (moduleIndex: number) => {
    const updatedModules = modules?.map((module, mIdx) => {
      if (mIdx === moduleIndex) {
        return {
          ...module,
          sub_modules: [
            ...module?.sub_modules,
            {
              id: uuidv4(),
              title: `Topic ${module?.sub_modules.length + 1}`,
              orderIndex: module?.sub_modules.length,
              contents: [],
              status: "upcoming",
              is_coming_soon: false,
            },
          ],
        };
      }
      return module;
    });
    onModuleChange(updatedModules);
  };

  const deleteTopic = (moduleIndex: number, topicIndex: number) => {
    const updatedModules = modules?.map((module, mIdx) => {
      if (mIdx === moduleIndex) {
        // Filter out the topic and reindex
        const updatedsub_modules = module?.sub_modules
          .filter((topic, tIdx) => tIdx !== topicIndex)
          ?.map((topic, index) => ({
            ...topic,
            orderIndex: index,
          }));

        return {
          ...module,
          sub_modules: updatedsub_modules,
        };
      }
      return module;
    });
    onModuleChange(updatedModules);
  };

  const updateModule = (
    moduleIndex: number,
    field: keyof Module,
    value: string
  ) => {
    const updatedModules = modules.map((module, idx) =>
      idx === moduleIndex ? { ...module, [field]: value } : module
    );
    onModuleChange(updatedModules);
  };

  const updateTopic = (
    moduleIndex: number,
    topicIndex: number,
    field: keyof Topic,
    value: string
  ) => {
    const updatedModules = modules.map((module, mIdx) => {
      if (mIdx === moduleIndex) {
        return {
          ...module,
          sub_modules: module.sub_modules.map((topic, tIdx) =>
            tIdx === topicIndex ? { ...topic, [field]: value } : topic
          ),
        };
      }
      return module;
    });
    onModuleChange(updatedModules);
  };

  const updatesub_modulestatus = (
    moduleIndex: number,
    topicIndex: number,
    status: string
  ) => {
    const updatedModules = modules.map((module, mIdx) => {
      if (mIdx === moduleIndex) {
        return {
          ...module,
          sub_modules: module.sub_modules.map((topic, tIdx) =>
            tIdx === topicIndex ? { ...topic, status } : topic
          ),
        };
      }
      return module;
    });
    onModuleChange(updatedModules);
  };

  const toggleModuleComingSoon = (moduleIndex: number) => {
    const updatedModules = modules.map((module, idx) =>
      idx === moduleIndex
        ? { ...module, is_coming_soon: !module.is_coming_soon }
        : module
    );
    onModuleChange(updatedModules);
  };



  return (
    <div>

      <div className="space-y-4">
        <ButtonOutlined handleClick={addModule}>Add Module</ButtonOutlined>

        {modules?.map((module, moduleIndex) => (
          <div key={module?.id} className="border rounded-lg p-4 mb-4  ">
            <div className="flex items-center justify-between">
              <div
                className="flex items-center justify-between cursor-pointer w-full"
                onClick={() => toggleModule(moduleIndex)}
              >
                <div className="">
                  <h3 className="text-lg font-medium">
                    {moduleIndex + 1}. {module?.title}
                  </h3>

                  <div className="flex items-center gap-2">
                    <span className="text-sm">Coming Soon</span>
                    <Switcher
                      isActive={module?.is_coming_soon}
                      for={`module-coming-soon-${module?.id}`}
                      onChange={() => toggleModuleComingSoon(moduleIndex)}
                      togglevalue={module?.is_coming_soon}
                      size="small"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-primary-500 p-2 bg-gray-100 rounded-md">
                    {expandedModules?.includes(moduleIndex) ? (
                      <ChevronDown />
                    ) : (
                      <ChevronRight />
                    )}
                  </span>

                  <Button
                    variant="danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteModule(moduleIndex);
                    }}
                    className="ml-2 p-2"
                  >
                    <DeleteIcon size="w-5 h-5" />
                  </Button>
                </div>
              </div>
            </div>

            {expandedModules?.includes(moduleIndex) && (
              <div className="mt-4 space-y-4">
                <div>
                  <label
                    htmlFor={`module-title-${module?.id}`}
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Module Title
                  </label>
                  <Input
                    value={module?.title}
                    onChange={(name, value) =>
                      updateModule(moduleIndex, "title", value)
                    }
                  />
                </div>
                <div>
                  <label
                    htmlFor={`module-description-${module?.id}`}
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Description
                  </label>
                  <RichTextEditor
                    value={module?.description}
                    onChange={(value) =>
                      updateModule(moduleIndex, "description", value)
                    }
                    placeholder="Enter module description"
                  />
                </div>

                <div className="mt-4">
                  <Button
                    onClick={() => addTopic(moduleIndex)}
                    variant="secondary"
                    className="px-3 w-full"
                  >
                    Add Topic
                  </Button>

                  {module?.sub_modules?.map((topic, topicIndex) => (
                    <div
                      key={topic?.id}
                      className="border rounded-lg p-4 mt-4 border-primary "
                    >
                      <div className="flex items-center justify-between">
                        <div
                          className="flex items-center justify-between cursor-pointer w-full"
                          onClick={() => toggleTopic(topicIndex)}
                        >
                          <h4 className="text-md font-medium">
                            {moduleIndex + 1}.{topicIndex + 1} {topic?.title}
                          </h4>
                        </div>
                        <div className="flex items-center gap-4">
                          <select
                            value={topic?.status}
                            onChange={(e) =>
                              updatesub_modulestatus(
                                moduleIndex,
                                topicIndex,
                                e.target.value as string
                              )
                            }
                            className="border rounded px-2 py-1"
                          >
                            <option value="upcoming">Upcoming</option>
                            <option value="active">Active</option>
                            <option value="completed">Completed</option>
                          </select>
                          <span
                            className="text-primary-500 p-2 bg-gray-100 rounded-md"
                            onClick={() => toggleTopic(topicIndex)}
                          >
                            {expandedsub_modules?.includes(topicIndex) ? (
                              <ChevronDown />
                            ) : (
                              <ChevronRight />
                            )}
                          </span>
                          <Button
                            variant="danger"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteTopic(moduleIndex, topicIndex);
                            }}
                            className="ml-2 p-2"
                          >
                            <DeleteIcon size="w-5 h-5" />
                          </Button>
                        </div>
                      </div>

                      {expandedsub_modules?.includes(topicIndex) && (
                        <div className="mt-4 space-y-4">
                          <div>
                            <label
                              htmlFor={`topic-title-${topic?.id}`}
                              className="block text-sm font-medium text-gray-700 mb-2"
                            >
                              Topic Title
                            </label>
                            <Input
                              value={topic?.title}
                              onChange={(name, value) =>
                                updateTopic(moduleIndex, topicIndex, "title", value)
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
