import { apiRequest } from '(apis)/api-interface/api-handler'
import { Image, message, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomButton from 'shared-components/form-fields/custom-button'
import GlobalTable from 'shared-components/table/GlobalTable'
import { ManageIcon } from './ui/Icons'
// Define the prop types using TypeScript interfaces
interface FeaturesProps {
  data: any;
  featuresData: any;
  fetchData: () => void;
  loading?: boolean;
  updateApi: string;
  isContentTypeFomrData?: boolean;
}

const SelectFeatures: React.FC<FeaturesProps> = ({ data, updateApi, isContentTypeFomrData = false, featuresData, fetchData, loading }) => {

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    data?.selectedFeatures || []
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate()
  const columns = [
    Table.SELECTION_COLUMN,
    {
      title: 'Icon',
      dataIndex: 'iconUrl',
      key: 'iconUrl',
      width: 100,
      render: (iconUrl: string) => <Image width={50} height={50} src={iconUrl} alt="feature" />,
    },
    {
      title: 'Feature Name',
      dataIndex: 'featureName',
      key: 'featureName',
      width: 100,
      headerStyle: { background: 'red', color: 'white' }
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Description',
      dataIndex: 'intro',
      key: 'intro',
      width: 200
    },

    {
      title: 'Actions',
      key: 'actions',
      width: 90,
      render: () => (
        <div  >
          <ManageIcon size="w-6 h-6" onClick={() => navigate("/manage/features")} />
        </div>
      ),
    },
  ]
  useEffect(() => {
    setSelectedRowKeys(data?.selectedFeatures || []);
  }, [data]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },

  };
  const handleSubmitSelectedFeature = async () => {
    try {
      setIsSubmitting(true);
      const selectedFeatures = selectedRowKeys;
      const formData = new FormData();
      selectedFeatures?.forEach((feature: string, index) => {
        formData.append(`selectedFeatures[${index}]`, feature);
      });
      const payload = isContentTypeFomrData ? formData : { selectedFeatures };
      const res = await apiRequest(updateApi, payload, [data?._id]);
      if (res?.status === 200) {
        message.success(res?.data?.message);
        fetchData();
        setIsSubmitting(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
    } finally {
      setIsSubmitting(false);
    }
  }
  return (
    <div className='w-[95%] mx-auto h-full'>
      <GlobalTable
        rowSelectionProps={rowSelection}
        isRowSelection={true}
        loading={loading}
        data={featuresData}
        columns={columns}
        isExpandable={true}
      />
      <div className="flex justify-end mt-10">
        <CustomButton text="Save" onClick={handleSubmitSelectedFeature} isLoading={isSubmitting} />
      </div>
    </div>
  )
}

export default SelectFeatures
