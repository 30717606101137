import axios from "axios";
import { backendUrl } from "../../constants/config";
import { ApiInterface } from "interfaces/global-interfaces";

export const fileManagerApis: ApiInterface[] = [
  {
    url: "/api/file-manager/upload",
    method: "POST",
    name: "uploadFile",
  },
  {
    url: "/api/file-manager",
    method: "GET",
    name: "getFiles",
  },
  {
    url: "/api/file-manager",
    method: "DELETE",
    name: "deleteFile",
  },
  {
    url: "/api/file-manager/update",
    method: "PUT",
    name: "updateFile",
  },
];

export function FileManagerAPI(
  name: string,
  params: string[] = [""],
  data: any = null
) {
  const api = fileManagerApis.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");

  return axios({
    method: api.method,
    data,
    withCredentials: true,
    url,
  });
}
