import React, { useEffect, useState } from "react";

import { CoursesApi } from "(apis)/(shared-apis)/new-courses";
import Loader from "shared-components/Loader";
import NewCourseCard from "./new-course-card";
import { PlusCircleIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { ButtonFill } from "shared-components/ui/CustomButtons";

interface Course {
  _id: string;
  courseName: string;
  [key: string]: any; // Add any other properties that your course object might have
}
const ViewAllNewCourses: React.FC = () => {
  const [coursesData, setCoursesData] = useState<Course[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const nav = useNavigate();

  const getCourseData = async () => {
    try {
      setLoading(true);
      const res = await CoursesApi("get_all_new_courses");
      if (res?.status === 200) {
        setCoursesData(res?.data?.data || []);
      }
      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      setCoursesData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourseData().then(() => console.log());
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div className="flex justify-center items-center  mx-auto w-full">
        <div className="">
          <div className="flex flex-col md:flex-row justify-between items-center border-b pb-4 mb-6">
            <div className="">
              <h2 className="font-bold text-3xl text-gray-900 mb-2">
                Course Management
              </h2>
              <p className="text-gray-600 text-sm">
                Create, review, and manage new course offerings
              </p>
            </div>
            <div className="w-fit">
              <ButtonFill handleClick={() => nav("/manage/new-courses")}>
                <span className="font-medium text-sm">New Course</span>
              </ButtonFill>
            </div>
          </div>

          <section className="text-gray-600 body-font">
            <div className="container py-4 mx-auto">
              {coursesData?.length > 0 && (
                <div className="font-bold my-4">Available New Courses</div>
              )}
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
                {coursesData?.length > 0 ? (
                  coursesData.map((data, i) => (
                    <div className="p-4 w-full" key={i}>
                      <NewCourseCard
                        data={data}
                        getCourseData={getCourseData}
                        setLoading={setLoading}
                      />
                    </div>
                  ))
                ) : (
                  <div>Please add a course, there is no course available</div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ViewAllNewCourses;
