import React, { useState, useEffect, KeyboardEvent } from "react";
import { CommissionAPI } from "../../../../(apis)/(shared-apis)/latest-commission-api";

interface AllowedEmailsModalProps {
  isOpen: boolean;
  couponId: string;
  existingEmails: string[];
  onClose: () => void;
  onUpdate: (emails: string[]) => void;
}

const AllowedEmailsModal: React.FC<AllowedEmailsModalProps> = ({
  isOpen,
  couponId,
  existingEmails = [],
  onClose,
  onUpdate,
}) => {
  const [emails, setEmails] = useState<string[]>(existingEmails);
  const [emailInput, setEmailInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Reset emails when modal opens
    setEmails(existingEmails);
    setEmailInput("");
    setError(null);
  }, [isOpen, existingEmails]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddEmail = (
    e: KeyboardEvent<HTMLInputElement> | React.MouseEvent
  ) => {
    e.preventDefault();
    const trimmedEmail = emailInput.trim();

    if (!trimmedEmail) return;

    // Validate email
    if (!validateEmail(trimmedEmail)) {
      setError("Invalid email format");
      return;
    }

    // Check for duplicates
    if (
      emails.some((email) => email.toLowerCase() === trimmedEmail.toLowerCase())
    ) {
      setError("Email already exists");
      return;
    }

    // Add email
    setEmails([...emails, trimmedEmail]);
    setEmailInput("");
    setError(null);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Add email on Enter or comma
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      handleAddEmail(e);
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const handleSave = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const response = await CommissionAPI("updatePromoCodeEmails", [], {
        couponId,
        allowedEmails: emails,
      });

      if (response?.data?.success) {
        onUpdate(emails);
        onClose();
      } else {
        setError(response?.data?.message || "Failed to update emails");
      }
    } catch (err) {
      setError("An error occurred while updating emails");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">Manage Allowed Emails</h2>

        {/* Email Input */}
        <div className="mb-4">
          <label
            htmlFor="email-input"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Add Emails
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="email"
              id="email-input"
              value={emailInput}
              onChange={(e) => {
                setEmailInput(e.target.value);
                setError(null);
              }}
              onKeyDown={handleKeyDown}
              placeholder="Enter email (press Enter to add)"
              className="flex-grow p-2 border rounded-md focus:ring-primary focus:border-primary"
            />
            <button
              onClick={handleAddEmail}
              className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary/80"
            >
              Add
            </button>
          </div>
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>

        {/* Existing Emails List */}
        {emails.length > 0 && (
          <div className="mb-4">
            <h3 className="text-md font-medium mb-2">Allowed Emails:</h3>
            <div className="flex flex-wrap gap-2 max-h-40 overflow-y-auto border p-2 rounded-md">
              {emails.map((email) => (
                <div
                  key={email}
                  className="bg-gray-100 px-3 py-1 rounded-full flex items-center space-x-2"
                >
                  <span>{email}</span>
                  <button
                    onClick={() => handleRemoveEmail(email)}
                    className="text-red-500 hover:text-red-700"
                    title="Remove email"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Description */}
        <div className="text-sm text-gray-600 mb-4">
          <p>Users with these emails can access the promo code details.</p>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-3 mt-6">
          <button
            onClick={onClose}
            disabled={isLoading}
            className={`px-4 py-2 rounded-md ${
              isLoading
                ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
            }`}
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={isLoading}
            className={`px-4 py-2 rounded-md text-white ${
              isLoading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-primary hover:bg-primary/80"
            }`}
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllowedEmailsModal;
