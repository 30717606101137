import { FormikErrors, FormikProps } from "formik";
import React from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import { Input, Label } from "./ui/CustomForm";

interface FAQ {
  question: any;
  answer: any;
}
// Type guard function
function isFormikErrorObject(
  value: string | FormikErrors<FAQ> | undefined
): value is FormikErrors<FAQ> {
  return typeof value === "object" && value !== null;
}

interface FAQSProps {
  formik: FormikProps<any>;
  data: {
    _id: string;
    faqs: FAQ[];
  };
}
const CommonFAQS: React.FC<FAQSProps> = ({ formik, data }) => {
  // Formik setup

  const { values, handleSubmit, setFieldValue, isSubmitting, errors, touched } =
    formik;

  const handleAddFAQ = () => {
    setFieldValue("faqs", [...values?.faqs, { question: "", answer: "" }]);
  };

  const handleRemoveFAQ = (index: number) => {
    const updatedFaqs = [...values?.faqs];
    updatedFaqs.splice(index, 1);
    setFieldValue("faqs", updatedFaqs);
  };

  const handleFAQChange = (index: number, key: keyof FAQ, value: string) => {
    const updatedFaqs = [...values?.faqs];
    updatedFaqs[index][key] = value;
    setFieldValue("faqs", updatedFaqs);
  };

  const HandleInputChange = (name: string, value: string) => {
    const key = "question"
    const index = parseInt(name.replace(`faqQuestion`, ""))
    handleFAQChange(index, key, value)
  }
  

  return (
    <div className="m-0 w-full">
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="mx-4"
      >
        {/* Rendering FAQ fields */}
        {data?.faqs?.length === 0 && (
          <div className="my-4">No FAQs Found please add new.</div>
        )}
        {values?.faqs?.length > 0 &&
          values?.faqs?.map((faq, index) => (
            <div
              key={index}
              className={`overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold p-4 mb-4`}
            >
              <div className="mx-auto p-2 pt-0">
                <div className="flex justify-between my-1 mb-2">
                  <label
                    className="text-md font-bold text-gray-900 mt-2"
                    htmlFor={`faqQuestion${index}`}
                  >
                    FAQ {index + 1}
                  </label>
                  {index > 0 && (
                    <button
                      className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                      type="button"
                      onClick={() => handleRemoveFAQ(index)}
                    >
                      Remove FAQ
                    </button>
                  )}
                </div>
                <div>
                  <Label>Question</Label>
                  <Input
                    type="text"
                    name={`faqQuestion${index}`}
                    value={faq.question}
                    onChange={HandleInputChange}
                  />
                  {isFormikErrorObject(errors.faqs?.[index]) &&
                    errors.faqs?.[index].question &&
                    touched.faqs?.[index]?.question && (
                      <div className="text-red-500 text-xs mt-1">
                        {errors.faqs[index]?.question as any}
                      </div>
                    )}
                </div>
                <div className="mt-2">
                  <Label>Answer</Label>
                  <textarea
                    id={`faqAnswer${index}`}
                    className="w-full p-4 border text-black rounded focus:border-red-500 focus:bg-white focus:ring-2 focus:ring-red-200 text-base outline-none  py-1 px-3  transition-colors duration-200 ease-in-out"
                    value={faq.answer}
                    onChange={(e) =>
                      handleFAQChange(index, "answer", e.target.value)
                    }
                    style={{ minHeight: 100 }}
                  />
                  {typeof errors?.faqs?.[index] === "object" &&
                    errors?.faqs?.[index]?.answer &&
                    touched?.faqs?.[index]?.answer && (
                      <div className="text-red-500 text-xs mt-1">
                        {errors?.faqs?.[index]?.answer as any}
                      </div>
                    )}
                </div>
              </div>
            </div>
          ))}

        <button
          className="bg-rose-500 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded"
          type="button"
          onClick={handleAddFAQ}
        >
          Add {values?.faqs?.length > 0 ? <span>More</span> : <></>} FAQ
        </button>
        {errors.faqs && touched.faqs && (
          <div className="text-red-500 text-xs mt-1">
            {Array.isArray(errors.faqs) ? (
              <></>
            ) : (
              <div>{errors.faqs as string}</div>
            )}
          </div>
        )}
        <div className="flex justify-end items-end mx-4 mt-4 ">
          <CustomButton
            disabled={isSubmitting}
            text="Save"
            onClick={() => handleSubmit()}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </div>
  );
};

export default CommonFAQS;
